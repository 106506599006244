import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-payment',
  templateUrl: './cancel-payment.component.html',
  styleUrls: ['./cancel-payment.component.scss']
})
export class CancelPaymentComponent {

  constructor(private router: Router) {

  }
  takeMeBack() {
    this.router.navigateByUrl('/dashboard');
  }
}
