import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FilterDialogComponent } from "./filter-dialog/filter-dialog.component";
declare var $: any;

import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  users: any[] = [];
  topusers: any[] = [];
  newusers: any[] = [];
  games: any[] = [];

  content: any;

  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  categories: any[];
  searchText = "";
  isMobile = false;
  constructor(
    private matDialog: MatDialog,
    private firestore: AngularFirestore,
    private router: Router,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
  ) {}
  imageDidNotLoad(event) {
    event.target.src = "assets/images/dummy_user.png";
  }
  storeUid(uid: string) {
    localStorage.setItem("userUid", uid);
    const userUid = localStorage.getItem("userUid");
  }

  formatDate(timestamp: any) {
    const date = new Date(timestamp.seconds * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" } as const;
    return new Intl.DateTimeFormat("it-IT", options).format(date);
  }
  ngOnInit() {

      this.breakpoint
        .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
        .subscribe((o) => {
          if (o.matches) {
            this.isMobile = o.matches;
            this._fuseConfigService.config = {
              layout: {
                navbar: {
                  hidden: this.isMobile,
                },
                toolbar: {
                  hidden: this.isMobile,
                },
                footer: {
                  hidden: this.isMobile,
                },
                sidepanel: {
                  hidden: this.isMobile,
                },
              },
            };
          }
        });
    this.firestore
      .collection("users", (ref) =>
        ref
          .where("isMaster", "==", true)
          .where("isVerified", "==", 2)
          .where("name", "!=", null)
          .where("hideMaster", '==', false)
      )
      .valueChanges()
      .subscribe((users) => {
        this.users = this.shuffleArray(users).slice(0,10);
    });

    this.firestore
      .collection("users", (ref) =>
        ref
          .where("isMaster", "==", true)
          .where("hideMaster", '==', false)
          .where("isVerified", "==", 2)
          .orderBy("creationDateTS", "desc")
          .limit(10)
      )
      .valueChanges()
      .subscribe((newUsers) => {
        this.newusers = newUsers;
      });

    this.firestore
      .collection("users", (ref) =>
        ref
          .where("isMaster", "==", true)
          .where("isVerified", "==", 2)
          .orderBy("rating", "desc")
          .where("hideMaster", '==', false)
          .limit(10)
      )
      .valueChanges()
      .subscribe((topUsers) => {
        this.topusers = topUsers;
      });

      this.firestore
      .collection("games")
      .valueChanges()
      .subscribe((games) => {
        const shuffledGames = this.shuffleArray(games);
        this.games = shuffledGames.slice(0, 10);  // Get the first 10 games
        // Process the games array to get unique categories
        this.categories = Array.from(
          new Set(
            this.games
              .map((game) => game.category)
              .reduce((prev, curr) => prev.concat(curr), [])
          )
        );
        this.categories = this.categories.map((c) => {
          return { pic: c.toLowerCase().split(" ").join("-"), name: c };
        });
      });
  }

  shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  filter() {
    this.matDialog.open(FilterDialogComponent, {
      data: {
        search: this.searchText,
      },
      width: "400px",
      height: "100%",
      direction: "ltr",
    });
  }

  searchGames() {
    localStorage.setItem("search", this.searchText);
    this.router.navigate(["/filter-result"]);
  }

  gameClicked(game) {
    localStorage.setItem("search", game.name);
  }
}
