import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { MessagesComponent } from "./messages.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { SummaryPipe } from "./summary.pipe";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { SharedAppModule } from "app/shared/shared-app.module";
import { AppAuthGuard } from "app/app.authguard";
import { RelativeTimePipe } from "./message.pipe";

const routes = [
  {
    path: "messages/:userId",
    component: MessagesComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "messages",
    component: MessagesComponent,
    canActivate: [AppAuthGuard]

  },
];

@NgModule({
  declarations: [MessagesComponent, SummaryPipe, RelativeTimePipe],
  imports: [  
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    SharedAppModule,
    MatMenuModule, 
    MatIconModule,
    MatButtonModule, 
    TranslateModule],
})
export class MessagesModule {}
