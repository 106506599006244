<div class="main pt-2">
  <div class="manage-rates">
    <div class="container-fluid">
      <h2>Manage Rates</h2>
      <div class="nav-row">
        <div class="links">
          <a routerLink="/manage-rates">Group</a>
          <a class="active">Private</a>
        </div>
        <button class="btn btn-primary" routerLink="/rates-edit">
          <i class="fa fa-plus-circle"></i> Add New Rate
        </button>
      </div>

      <div class="list">
        <ul>
          <li class="green">Valorant Single Class</li>
          <li>10 Lessons</li>
          <li>1 Hour/Lesson</li>
          <li>$60</li>
          <li class="available">Promo Code<br />Available</li>
        </ul>
        <ul>
          <li class="green">Valorant Single Class</li>
          <li>10 Lessons</li>
          <li>1 Hour/Lesson</li>
          <li>$60</li>
          <li class="available">Promo Code<br />Available</li>
        </ul>
        <ul>
          <li class="green">Valorant Single Class</li>
          <li>10 Lessons</li>
          <li>1 Hour/Lesson</li>
          <li>$60</li>
          <li class="not-available">Promo Code<br />not Available</li>
        </ul>
        <ul>
          <li class="green">Valorant Single Class</li>
          <li>10 Lessons</li>
          <li>1 Hour/Lesson</li>
          <li>$60</li>
          <li class="available">Promo Code<br />Available</li>
        </ul>
        <ul>
          <li class="green">Valorant Single Class</li>
          <li>10 Lessons</li>
          <li>1 Hour/Lesson</li>
          <li>$60</li>
          <li class="not-available">Promo Code<br />not Available</li>
        </ul>
        <ul>
          <li class="green">Valorant Single Class</li>
          <li>10 Lessons</li>
          <li>1 Hour/Lesson</li>
          <li>$60</li>
          <li class="available">Promo Code<br />Available</li>
        </ul>
      </div>
    </div>
  </div>
</div>
