import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { ActivatedRouteSnapshot } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: "root",
})
export class toolbarService {
  routeParams: any;
  item: any;
  pageItem: any;
  items: any[];
  onItemChanged: BehaviorSubject<any>;
  onPageItemChanged: BehaviorSubject<any>;
  onItemsChanged: BehaviorSubject<any>;
  userData: any;

  constructor(private http: HttpClient, private afs: AngularFirestore) {
    this.onItemChanged = new BehaviorSubject({});
    this.onPageItemChanged = new BehaviorSubject({});
    this.onItemsChanged = new BehaviorSubject({});
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise((resolve, reject) => {
      Promise.all([this.getInit()]).then(() => {
        resolve(true);
      }, reject);
    });
  }

  getInit(): Promise<any> {
    return new Promise((resolve, reject) => {});
  }
  getUnreadMessagesCount(userId: string): Observable<number> {
    return this.afs
      .collection("rooms", (ref) =>
        ref
          .where("userIds", "array-contains", userId)
          .where("metadata.lastMessageRead", "==", false)
          .where("metadata.lastMessageSentBy", "!=", userId)
      )
      .valueChanges()
      .pipe(map((rooms) => {console.log(rooms.length);return rooms.length}));
  }
}
