<div class="book-lesson-popup">
  <div class="book-lesson-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <h3>Choose Games :</h3>
    <div class="row games">
      <div class="col-4">
        <img src="assets/images/games/1.png" alt="" />
        <h5>Fall Guys</h5>
      </div>
      <div class="col-4">
        <img src="assets/images/games/2.png" alt="" />
        <h5>Stray</h5>
      </div>
      <div class="col-4">
        <img src="assets/images/games/3.png" alt="" />
        <h5>Halo Infinite</h5>
      </div>
      <div class="col-4">
        <img src="assets/images/games/4.png" alt="" />
        <h5>Valorant</h5>
      </div>
      <div class="col-4">
        <img src="assets/images/games/5.png" alt="" />
        <h5>Mincraft</h5>
      </div>
      <div class="col-4">
        <img src="assets/images/games/6.png" alt="" />
        <h5>Fortnite</h5>
      </div>
    </div>
    <div class="btns-row">
      <div class="btn btn-default" (click)="dialogRef.close(true)">Cancel</div>
      <div class="btn btn-primary" (click)="requestSent()">Book</div>
    </div>
  </div>
</div>
