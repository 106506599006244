<div class="main">
  <div class="group-class-edit">
    <div class="container-fluid">
      <div class="class-date-time">
        <span class="class-name">Valorant Rank up Tips</span>
        <span class="time">Mon - Fri (8:00 pm)</span>
        <span class="date">(Aug,12 2022) - (Oct,12 2022)</span>
        <span class="students">47 Students</span>
      </div>
      <div class="class-cover">
        <img src="assets/images/manager/cover.png" alt="" />
      </div>
      <div class="btns-area">
        <div class="nav-action-btns">
          <button class="small-btn" routerLink="/class-setting">
            <i class="fa fa-cog"></i>
          </button>
          <button class="small-btn"><i class="fa fa-share-alt"></i></button>
        </div>
      </div>
      <div class="edit-lesson">
        <div class="box">
          <h4>Manage your class activities and<br />students attendance</h4>
          <div class="row">
            <div class="col-md">
              <h5>Game Name:</h5>
              <input type="text" class="form-control" value="Valorant" />
              <h5>Class Topic (Subject):</h5>
              <input
                type="text"
                class="form-control"
                value="Valorant Rankup Tips & Tricks"
              />
              <h5>Date:</h5>
              <input type="text" class="form-control" value="Jul 22, 2022" />
            </div>
            <div class="col-md-5">
              <h5>Class Activites:</h5>
              <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
              >
            </div>
            <div class="col-md">
              <div class="tags">
                <h5>Class Activites:</h5>
                <div class="tag-box">Alexa Bliss</div>
                <div class="tag-box">Liv Morgan</div>
                <div class="tag-box">Alex Housten</div>
                <div class="tag-box">Luke Harper</div>
                <div class="tag-box">Jenny James</div>
                <div class="tag-box">Nick Fury</div>
              </div>
            </div>
          </div>
          <div class="form-action-btns">
            <button class="btn btn-default" routerLink="/group-class-view">
              Back
            </button>
            <button class="btn btn-primary" routerLink="/group-class-view">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
