<div class="calendar-popup">
  <div class="calendar-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <h3>Calendar Shared for All Private Rates :</h3>
    <ul class="weekdays">
      <li>M</li>
      <li class="active">T</li>
      <li>W</li>
      <li>T</li>
      <li>F</li>
      <li>S</li>
      <li>S</li>
    </ul>
    <div class="events">
      <div class="event-time">
        <ul class="time">
          <li>12:00 am</li>
          <li>01:00 pm</li>
          <li>02:00 pm</li>
          <li>03:00 pm</li>
          <li>04:00 pm</li>
          <li>05:00 pm</li>
        </ul>
      </div>
      <div class="event-list">
        <div class="box">
          <span class="title">Not Available for Private lessons</span>
        </div>
        <div class="box active">
          <span class="title">Avaliable for Private lessons</span>
        </div>
        <div class="box">
          <span class="title">Not Available for Private lessons</span>
        </div>
      </div>
    </div>
    <div class="text-right w-100">
      <button class="btn btn-primary" (click)="dialogRef.close(true)">
        Back
      </button>
    </div>
  </div>
</div>
