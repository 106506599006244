<div class="class-scheduled-popup">
  <div class="class-scheduled-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <img class="done-img" src="assets/images/profile/dialog/done.png" alt="" />
    <h3>Request Sent</h3>
    <p>
      Your Request was sent to the management. You will be contacted through
      email once your request is accepted
    </p>
    <div class="text-center w-100">
      <div class="btn btn-primary" (click)="dialogRef.close(true)">Done</div>
    </div>
  </div>
</div>
