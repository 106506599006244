import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DynamicLinkService {
  private dynamicLinkUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.firebase.apiKey}`;

  constructor(private http: HttpClient) {}
  createDynamicLink(userUid: string) {
    console.log(userUid);
    const dynamicLinkParams = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://adminsweech.page.link',
        link: `https://student.sweech.gg/profile?userId=${userUid}`,
        androidInfo: {
          androidPackageName: 'com.wytex.sweech',
        },
        iosInfo: {
          iosBundleId: 'com.wytex.sweech',
        },
      }
      
    };

    return this.http.post(this.dynamicLinkUrl, dynamicLinkParams);
  }
}
