import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { ManagerComponent } from "./manager.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { GroupClassDetailComponent, LinkDialogComponent } from "./group-class-detail/group-class-detail.component";
import { GroupClassEditComponent } from "./group-class-edit/group-class-edit.component";
import { ClassSettingComponent } from "./class-setting/class-setting.component";
import { PrivateClassDetailComponent } from "./private-class-detail/private-class-detail.component";
import { TranslateModule } from "@ngx-translate/core";
import { SharedAppModule } from "app/shared/shared-app.module";
import { CommonModule } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import {MatChipsModule} from '@angular/material/chips';
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

const routes = [
  {
    path: "classes",
    component: ManagerComponent,
  },
  {
    path: "group-class-view/:id",
    component: GroupClassDetailComponent,
  },
  {
    path: "group-class-edit",
    component: GroupClassEditComponent,
  },
  {
    path: "class-setting",
    component: ClassSettingComponent,
  },
  {
    path: "private-class-view",
    component: PrivateClassDetailComponent,
  },
];

@NgModule({
  declarations: [
    ManagerComponent,
    GroupClassDetailComponent,
    GroupClassEditComponent,
    ClassSettingComponent,
    PrivateClassDetailComponent,
    LinkDialogComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FuseSharedModule,
    MatTabsModule,
    TranslateModule,
    SharedAppModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDialogModule,
  ],
})
export class ManagerModule {}
