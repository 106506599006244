<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main pt-2">
  <div class="settings">
    <div class="container-fluid">
      <h2>{{"Settings" | translate}}</h2>
      <div class="setting-box" [ngStyle]="{
        padding: isMobile ? '0' : '50px',
        'margin-bottom': isMobile ? '2rem' : '0px',
        border: isMobile ? 'none' : '1px solid #ccc'
      }">
        <div class="new-game-row">
          <span *ngIf="!user.isMaster && !user.masterRequest || user.masterRequest == 0">{{"Become a Teacher" | translate}}</span>
          <span *ngIf="!user.isMaster && user.masterRequest == 1">{{"Your request has been submitted" | translate}}</span>
          <span *ngIf="user.isMaster || user.masterRequest == 2">{{"You are a Master too, congratulations!" | translate}}</span>
          <button class="btn add-btn" *ngIf="!user.isMaster" [disabled]="user.masterRequest == 1 || user.masterRequest == 2" routerLink="/signup-detail" [queryParams]="{'isMasterApplying': true}">
            <i class="far fa-plus-square"></i>
          </button>
        </div>
        <!-- <h3>{{ "Notifications :" | translate }}</h3> -->
        <!-- <div class="Notification-box" *ngIf="user.settings"> -->
          <!-- <div class="set-row">
            <span>{{ "Push Notifications" | translate }} </span>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch3"
                [(ngModel)]="user.settings.pushNotifications"
                name="pushNotifications"
                (change)="setSetting()"
              />
              <label class="custom-control-label" for="customSwitch3"></label>
            </div>
          </div>
          <div class="set-row">
            <span>{{ "SMS Notifications" | translate }} </span>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch4"
                [(ngModel)]="user.settings.smsNotifications"
                name="smsNotifications"
                (change)="setSetting()"
              />
              <label class="custom-control-label" for="customSwitch4"></label>
            </div>
          </div>
          <div class="set-row">
            <span>{{ "Site Notifications" | translate }} </span>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch5"
                [(ngModel)]="user.settings.siteNotifications"
                name="siteNotifications"
                (change)="setSetting()"
              />
              <label class="custom-control-label" for="customSwitch5"></label>
            </div>
          </div> -->
          <!-- <div class="set-row">
            <label for="language">{{ "Language" | translate }}:</label>
            <select
              id="language"
              (change)="setSetting()"
              [(ngModel)]="user.settings.selectedLanguage"
              name="selectedLanguage"
            >
              <option value="en">{{"English" | translate}}</option>
              <option value="it">{{"Italian" | translate}}</option>
            </select>
          </div> -->
        <!-- </div> -->

        <!-- <h3>{{"Payment :" | translate}}</h3> -->
        <!-- <div class="payment-box">
          <div class="box-head">
            <span>{{"Add another Payment Method" | translate}} </span>
            <button class="btn add-btn" (click)="selectPayment()">
              <i class="far fa-plus-square"></i>
            </button>
          </div>
          <div class="card-input" *ngFor="let card of user.card; let i = index">
            <input
              type="text"
              class="form-control"
              placeholder="0529xxxxxxxxx8989039409"
              [value]="'************'+card"
              [disabled]="true"
            />
            <i class="fa fa-times cross" (click)="removeCard(card, i)"></i>
          </div> 
        </div> -->

        <div class="new-game-row">
          <span>{{"Contact Support" | translate}} </span>
          <button class="btn add-btn" (click)="supportDialog()">
            <i class="far fa-envelope"></i>
          </button>
        </div>

        <!-- Add studentDiscordName input field ngModel -->
        <div class="payment-box">
          <div class="box-head">
            <span>{{"Discord Username" | translate}} </span>
          </div>
          <input type="text" class="form-control" placeholder="Discord Username" [(ngModel)]="studentDiscordName" name="studentDiscordName" />
          <button class="btn" (click)="saveDiscordName()">
            {{"Save" | translate}}
          </button>
        </div>

        <div class="bottom-btns">
          <!-- <button class="btn discord-btn mb-3">{{"Connect Discord" | translate}}</button> -->
          <br />
          <button class="btn delete-btn" (click)="onDeleteAccount()">{{"Delete Account" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
