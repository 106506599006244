import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
declare var $: any;

@Component({
  selector: "group-class-edit",
  templateUrl: "./group-class-edit.component.html",
  styleUrls: ["./group-class-edit.component.scss"],
})
export class GroupClassEditComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor() {}

  ngOnInit() {}
}
