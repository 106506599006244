import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
   MatDialogRef,
 MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { confirmPurchaseDialogComponent } from "../confirm-purchase-dialog/confirm-purchase-dialog.component";
import { Subject, takeUntil } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
  selector: "connect-discord-dialog",
  templateUrl: "./connect-discord-dialog.component.html",
  styleUrls: ["./connect-discord-dialog.component.scss"],
})
export class connectDiscordDialogComponent {
  isMobile: boolean;
  private _unsubscribeAll: Subject<any> = new Subject();
  /**
   * Constructor
   *
   * @param {MatDialogRef<connectDiscordDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<confirmPurchaseDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
  ) {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((o) => {
        if (o.matches) {
          this.isMobile = o.matches;
          this._fuseConfigService.config = {
            layout: {
              navbar: {
                hidden: this.isMobile,
              },
              toolbar: {
                hidden: this.isMobile,
              },
              footer: {
                hidden: this.isMobile,
              },
              sidepanel: {
                hidden: this.isMobile,
              },
            },
          };
        }
      });
  }

  confirmPurchase() {
    this.dialogRef.close();
    this.matDialog.open(confirmPurchaseDialogComponent, {
      data: {
        cls: this.data.cls,
        selectedRate: this.data.selectedRate,
      }
    });
  }
}
