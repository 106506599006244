<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav class="sidenav" mode="side">
    <div class="sidenav-items">
      
        <!-- <a routerLink="/manage-rates" routerLinkActive="active">{{
          "Manage Rates" | translate
        }}</a>
        <a routerLink="/settings" routerLinkActive="active">{{
          "Settings" | translate
        }}</a>
        <a routerLink="/Logout" routerLinkActive="active">{{
          "Logout" | translate
        }}</a> -->
        <div class="profile-section">
            <button mat-icon-button (click)="sidenav.toggle()">
              <mat-icon>close</mat-icon>
            </button>
            <!-- Your profile section goes here -->
          </div>
          <div class="nav-items">
            <button mat-button (click)="navigateTo('my-profile')">{{"Profile" | translate}}</button>
            <button mat-button (click)="navigateTo('classes')">{{"My Classes" | translate}}</button>
            <button mat-button (click)="navigateTo('settings')">{{"Settings" | translate}}</button>
            <!-- Add other buttons here -->
          </div>
          <div class="logout-section">
            <button mat-button color="warn" (click)="logout()" *ngIf="!dialogOpen">{{"Logout" | translate}}</button>
          </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
