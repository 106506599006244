<fuse-progress-bar></fuse-progress-bar>
<app-sidenav
  *ngIf="
    isMobile &&
    userFirestore &&
    ((userFirestore$ | async)?.step >= 1 )
  "
></app-sidenav>

<ng-container>
  <vertical-layout-1 [isMobile]="isMobile"></vertical-layout-1>
</ng-container>
<div
  *ngIf="
    isMobile &&
    ((userFirestore$ | async)?.step >= 1 )
  "
  style="z-index: 10000; position: fixed"
>
  <app-bottom-nav></app-bottom-nav>
</div>
