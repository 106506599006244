import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, firstValueFrom, forkJoin, lastValueFrom, of } from "rxjs";
import firebase from "firebase/compat/app";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { User } from "app/models/user.model";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
declare var $: any;
import { v4 as uuidv4 } from "uuid";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";

interface Room {
  id: string;
  userIds: string[];
  lastMessage: string;
}
@Component({
  selector: "messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
})
export class MessagesComponent implements OnInit, AfterViewChecked {
  groupBtn: Boolean = false;
  // profilePicture$: Observable<string>;
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  // aboutMe$: Observable<string>;
  // name$: Observable<string>;
  // country$: Observable<string>;
  aboutMe: string;
  user: firebase.User;
  user$: Observable<User>;

  chatlist: any;
  rooms: any[];
  selectedSet: string = "emojione";
  emojiTitle: string = "Pick your emoji...";
  selectedEmoji: any;

  // registerDateTS$: Observable<Date>;
  roomsCollection: import("@angular/fire/compat/firestore").AngularFirestoreCollection<Room>;
  rooms$: Observable<Room[]>;

  selectedRoom: any;
  messages: Observable<any[]>;

  messagesLoading: boolean = true;
  roomsLoading: boolean = true;

  message = "";

  isBlocked: boolean = false;
  isBlockedByUser: boolean = false;
  filteredRooms: any[] = [];
  @ViewChild("scrollMe") private myScrollContainer: ElementRef;
  private roomCreated = false;

  userIdFromURL = "";
  private deletionTriggered = false;
  isMobile = false;
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.route.params.subscribe((params) => {
      console.log(params);
      this.userIdFromURL = params["userId"] ?? "";
      this.afAuth.authState.subscribe((user) => {
        if (user == null) return;
        const loggedInUserId = user.uid;
        this.user = user;
        this.user$ = this.afs
          .doc(`users/${loggedInUserId}`)
          .valueChanges()
          .pipe(map((usr: any) => usr ?? {}));

        this.roomsCollection = this.afs.collection<Room>("rooms", (ref) => {
          return ref
            .where("userIds", "array-contains", loggedInUserId)
            .orderBy("updatedAt", "desc");
        });
        this.roomsLoading = true;
        this.roomsCollection
          .valueChanges({ idField: "id" })
          .pipe(
            switchMap((rooms) => {
              return rooms.length == 0
                ? of([])
                : forkJoin(
                    rooms
                    // .filter((r) => {
                    //   const deletedUsers = r["metadata"]["deletedUsers"];
                    //   if (!deletedUsers || deletedUsers.length === 0) {
                    //     return true;
                    //   }
                    //   return !deletedUsers.includes(loggedInUserId);
                    // })
                      .map(async (room, index) => {
                        const userId = room.userIds.find(
                          (id) => id !== loggedInUserId
                        );
                        const us = await firstValueFrom(
                          this.afs.doc<any>(`users/${userId}`).get()
                        );
                        let currentRoomId = this.selectedRoom?.id;
                        const userData = us.data();
                        if (
                          this.userIdFromURL != "" &&
                          this.userIdFromURL == userId
                        ) {
                          let currentRoom = this.rooms?.find(
                            (room) => room.id === currentRoomId
                          );
                          if (currentRoom) {
                            this.selectedRoom = currentRoom;
                          } else {
                            // default to the first room or any other logic you want when the current room isn't found
                            this.selectedRoom = { ...room, user: userData };
                          }
                          // this.selectedRoom = { ...room, user: userData };
                          if (this.selectedRoom.metadata.blockedUsers) {
                            if (
                              this.selectedRoom.metadata.blockedUsers.includes(
                                this.user.uid
                              )
                            ) {
                              this.isBlockedByUser = true;
                            } else if (
                              this.selectedRoom.metadata.blockedUsers.includes(
                                userId
                              )
                            ) {
                              this.isBlocked = true;
                            }
                          }
                          this.fetchMessages(room);
                          this.readMessage();
                          return this.selectedRoom; // Return observable of null
                        }
                        if (index == 0) {
                          let currentRoom = this.rooms?.find(
                            (room) => room.id === currentRoomId
                          );
                          if (currentRoom) {
                            this.selectedRoom = currentRoom;
                          } else {
                            // default to the first room or any other logic you want when the current room isn't found
                            this.selectedRoom = { ...room, user: userData };
                          }
                          // this.selectedRoom = { ...room, user: userData };
                          if (this.selectedRoom.metadata.blockedUsers) {
                            if (
                              this.selectedRoom.metadata.blockedUsers.includes(
                                this.user.uid
                              )
                            ) {
                              this.isBlockedByUser = true;
                            } else if (
                              this.selectedRoom.metadata.blockedUsers.includes(
                                userId
                              )
                            ) {
                              this.isBlocked = true;
                            }
                          }
                          this.readMessage();
                          this.fetchMessages(room);
                        }
                        return { ...room, user: userData }; // Wrap in of() to return observable
                      })
                  ).pipe(map((rooms) => rooms.filter((r) => r != null).filter((r) => {
                    const deletedUsers = r["metadata"]["deletedUsers"];
                    if (!deletedUsers || deletedUsers.length === 0) {
                      return true;
                    }
                    return !deletedUsers.includes(loggedInUserId);
                  }))); // Filter out null values
            }),
            tap((rooms) => {
              this.roomsLoading = false;
            }),
            finalize(() => {
              this.roomsLoading = false;
            })
          )
          .subscribe({
            next: async (rooms) => {
              if (Array.isArray(rooms) && rooms.length === 0) {
                this.messagesLoading = false;
                this.messages = of([]);
              }

              this.roomsLoading = false;
              this.rooms = Array.isArray(rooms) ? rooms : []; // <-- make sure that rooms is an array
              this.rooms.sort((a, b) => {
                return (
                  new Date(b.updatedAt).getTime() -
                  new Date(a.updatedAt).getTime()
                );
              });
              this.filteredRooms = this.rooms;
              const roomExists = await this.doesRoomExist(
                loggedInUserId,
                this.userIdFromURL
              );

              if (
                !roomExists &&
                this.userIdFromURL != "" &&
                !this.roomCreated &&
                !this.deletionTriggered
              ) {
                this.roomCreated = true;
                const newRoom = {
                  userIds: [loggedInUserId, this.userIdFromURL],
                  updatedAt: firebase.firestore.Timestamp.now(),
                  createdAt: firebase.firestore.Timestamp.now(),
                  imageUrl: null,
                  metadata: {
                    imageUrl: "",
                    lastMessage: "",
                    lastMessageRead: false,
                    lastMessageSentBy: loggedInUserId,
                    name: "",
                    totalUnread: 1,
                    type: "request",
                    updatedAt: firebase.firestore.Timestamp.now(),
                  },
                  name: null,
                  type: "direct",
                  userRoles: null,
                };

                lastValueFrom(
                  this.afs.collection("users").doc(this.userIdFromURL).get()
                ).then((userFromNewRoom) => {
                  this.afs
                    .collection("rooms")
                    .add(newRoom)
                    .then((docRef) => {
                      this.selectedRoom = {
                        ...newRoom,
                        id: docRef.id,
                        user: userFromNewRoom.data(),
                      };
                      this.rooms = [this.selectedRoom, ...this.rooms];
                      // Sort on date
                      this.rooms.sort((a, b) => {
                        return (
                          new Date(b.updatedAt).getTime() -
                          new Date(a.updatedAt).getTime()
                        );
                      });
                      this.filteredRooms = this.rooms;
                      this.readMessage();
                    });
                });
              }
            },
            error: (err) => {
              console.error("An error occurred: ", err);
              this.roomsLoading = false; // set to false in case of error too
              this.messagesLoading = false;
            },
          });
      });
    });
  }
  private async doesRoomExist(
    user1Id: string,
    user2Id: string
  ): Promise<boolean> {
    const roomsSnapshot = await this.afs
      .collection<Room>("rooms", (ref) =>
        ref.where("userIds", "array-contains", user1Id)
      )
      .get()
      .toPromise();

    const roomsData: Room[] = roomsSnapshot.docs.map(
      (doc) => doc.data() as Room
    );
    return roomsData.some((room) => room.userIds.includes(user2Id));
  }

  fetchMessages(room: Room) {
    this.messagesLoading = true;
    this.messages = this.afs
      .collection("rooms")
      .doc(room.id)
      .collection("messages", (ref) => ref.orderBy("createdAt"))
      .valueChanges()
      .pipe(
        tap((messages) => {
          this.messagesLoading = false;
        }),
        finalize(() => {
          this.messagesLoading = false;
        })
      );
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
    this.cdr.detectChanges();
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
  TabChanged(tab = { index: 0 }) {
    if (tab.index == 1) {
      this.groupBtn = true;
    } else {
      this.groupBtn = false;
    }
  }

  clickonRoom(room: Room) {
    this.selectedRoom = room;
    // if (
    //   this.selectedRoom.metadata.blockedUsers &&
    //   (this.selectedRoom.metadata.blockedUsers.includes(this.user.uid) ||
    //     this.selectedRoom.metadata.blockedUsers.includes(
    //       this.selectedRoom.user.uid
    //     ))
    // ) {
    //   this.isBlocked = true;
    // }
    this.isBlocked = false;
    this.isBlockedByUser = false;
    if (this.selectedRoom.metadata.blockedUsers) {
      if (this.selectedRoom.metadata.blockedUsers.includes(this.user.uid)) {
        this.isBlockedByUser = true;
      } else if (
        this.selectedRoom.metadata.blockedUsers.includes(
          this.selectedRoom.user.uid
        )
      ) {
        this.isBlocked = true;
      } else if (
        !this.selectedRoom.metadata.blockedUsers.includes(
          this.selectedRoom.user.uid
        )
      ) {
        this.isBlocked = false;
      }
    }
    this.fetchMessages(room);
    this.readMessage();
  }

  sendMessage() {
    if (this.isBlocked) {
      return;
    }
    if (this.isBlockedByUser) {
      return;
    }
    if (this.message.trim() == "") {
      return;
    }
    this.afs
      .collection("rooms")
      .doc(this.selectedRoom.id)
      .collection("messages")
      .add({
        text: JSON.parse(JSON.stringify(this.message)),
        authorId: this.user.uid,
        createdAt: firebase.firestore.Timestamp.now(),
        type: "text",
        updatedAt: firebase.firestore.Timestamp.now(),
      })
      .then((r) => {
        const updatedData = {
          "metadata.lastMessage": this.message,
          "metadata.lastMessageSentBy": this.user.uid,
          "metadata.lastMessageRead": false,
          "metadata.updatedAt": firebase.firestore.Timestamp.now(),
          "metadata.deletedUsers": [],
          "metadata.totalUnread": 1,
          updatedAt: firebase.firestore.Timestamp.now(),
        };
        updatedData[`metadata.lastMessageRead${this.user.uid}`] = false;
        this.afs
          .collection("rooms")
          .doc(this.selectedRoom.id)
          .update(updatedData)
          .then(() => {
            this.message = "";
          });
      });
  }
  readMessage() {
    console.log(this.selectedRoom);
    if (
      this.selectedRoom.metadata.lastMessageRead == false &&
      !this.isBlocked &&
      this.selectedRoom.metadata.lastMessageSentBy != this.user.uid
    ) {
      this.afs.collection("rooms").doc(this.selectedRoom.id).update({
        "metadata.lastMessageRead": true,
      });
    }
  }

  reportUser() {
    this.openDialog(
      this.translate.instant("Report User"),
      this.translate.instant("Are you sure you want to report this user?"),
      (r) => {
        this.afs
          .collection("support")
          .add({
            category: "Report User in Chat Room",
            classId: "",
            creationDateTS: new Date(),
            isMaster: false,
            isNew: true,
            isReplied: false,
            message: r,
            reportedUserId: this.selectedRoom.user.uid,
            senderEmail: this.user.email,
            senderID: this.user.uid,
            senderUsername: this.user.displayName,
            uid: uuidv4(),
            isRefund: false,
          })
          .then(() => {});
      },
      true
    );
  }
  blockUser() {
    this.openDialog(
      this.translate.instant("Block User"),
      this.translate.instant("Are you sure you want to block this user?"),
      (r) => {
        this.afs
          .collection("rooms")
          .doc(this.selectedRoom.id)
          .update({
            "metadata.blockedUsers": firebase.firestore.FieldValue.arrayUnion(
              this.selectedRoom.user.uid
            ),
          })
          .then(() => {
            // this.isBlocked = true;
          });
      }
    );
  }
  deleteChat() {
    // Ask confirmation modal
    // Delete the chat
    this.openDialog(
      this.translate.instant("Delete Chat"),
      this.translate.instant("Are you sure you want to delete this chat?"),
      async (r) => {
        this.deletionTriggered = true; // Set deletionTriggered to true here
        await this.afs.doc(`rooms/${this.selectedRoom.id}`).update({
          "metadata.deletedUsers": firebase.firestore.FieldValue.arrayUnion(
            this.user.uid
          ),
        });
        this.rooms = this.rooms.filter(
          (room) => room.id !== this.selectedRoom.id
        );
        this.filteredRooms = this.rooms;
        this.selectedRoom = this.rooms.length > 0 ? this.rooms[0] : null;
        // this.afs
        //   .collection("rooms")
        //   .doc(this.selectedRoom.id)
        //   .delete()
        //   .then(() => {
        //     this.rooms = this.rooms.filter(
        //       (room) => room.id !== this.selectedRoom.id
        //     );
        //     this.filteredRooms = this.rooms;
        //     this.selectedRoom = this.rooms.length > 0 ? this.rooms[0] : null;

        //     this.router.navigate(["/messages"]);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      }
    );
  }

  openDialog(
    title: string,
    text: string,
    callback: Function,
    inputField: boolean = false
  ) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        title,
        text,
        inputField,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // Yes button clicked
        callback(result);
      }
    });
  }

  unblock() {
    this.afs
      .collection("rooms")
      .doc(this.selectedRoom.id)
      .update({
        "metadata.blockedUsers": firebase.firestore.FieldValue.arrayRemove(
          this.selectedRoom.user.uid
        ),
      });
    this.isBlocked = false;
  }

  searchMessages(searchStr: string) {
    // search in rooms array for usernames
    this.filteredRooms = [];
    this.rooms.forEach((room) => {
      if (room.user.username.toLowerCase().includes(searchStr.toLowerCase())) {
        // show the room
        this.filteredRooms.push(room);
      } else {
        // hide the room
      }
    });
  }
}
