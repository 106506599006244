import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
   MatDialogRef,
 MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { confirmPurchaseDialogComponent } from "../confirm-purchase-dialog/confirm-purchase-dialog.component";

@Component({
  selector: "join-server-dialog",
  templateUrl: "./join-server-dialog.component.html",
  styleUrls: ["./join-server-dialog.component.scss"],
})
export class joinServerDialogComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<joinServerDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<joinServerDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  goClasses() {
    this.dialogRef.close();
    this._router.navigate(["/classes"]);
  }
}
