<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main pt-2">
  <div class="my-profile">
    <div class="container-fluid">
      <h2>{{"Profile" | translate}}</h2>

      <div class="d-flex justify-content-between">
        <div class="name-img">
          <img
            *ngIf="
              profilePicture$ | async as profilePicture;
              else noProfilePicture
            "
            src="{{ profilePicture }}"
            alt="Profile Picture"
            style="border-radius: 50%"
            [ngStyle]="{
              'border': isMobile ? 'none' : '1px solid #ccc',
              'width': isMobile ? '100px' : '150px',
              'height': isMobile ? '100px' : '150px'
            }"
          />
          <ng-template #noProfilePicture>
            <div class="profile-picture-container">
              <i class="fa fa-user"></i>
            </div>
          </ng-template>
          <span class="name">{{ name$ | async }}</span>
        </div>
        <div>
          <button mat-raised-button class="edit-profile" [routerLink]="['/my-profile','edit', loggedInUser]" >{{"Edit Profile" | translate}}</button>
        </div>
      </div>
      <h3>{{"Classes" | translate}} :</h3>
      <div class="row mb-5">
        <div class="col-md-4" *ngFor="let class of classes$ | async">
          <a class="top-game-box" [routerLink]="'/group-class-view/'+class.uid">
            <img class="img-fluid" src="{{ class.image }}" alt="" />
            <div class="text-area">
              <span class="name">{{ class.gameTitle }}</span>
              <span class="date"
                >{{ formatDate(class.startDate) }} -
                {{ formatDate(class.endDate) }}</span
              >
            </div>
          </a>
        </div>
        <div
          *ngIf="(classes$ | async)?.length === 0"
          class="col-md-12 text-center mt-5"
        >
          {{"No classes scheduled" | translate}}
        </div>
      </div>

      <h3>{{"Finished Classes" | translate}} :</h3>
      <div class="row mb-5">
        <div class="col-md-4" *ngFor="let class of finishedClasses$ | async">
          <a class="top-game-box" routerLink="/group-class-view/{{class.uid}}">
            <img class="img-fluid" src="{{ class.image }}" alt="" />
            <div class="text-area">
              <span class="name">{{ class.gameTitle }}</span>
              <span class="date"
                >{{ formatDate(class.startDate) }} -
                {{ formatDate(class.endDate) }}</span
              >
            </div>
          </a>
        </div>
        <div
          *ngIf="( finishedClasses$ | async)?.length === 0"
          class="col-md-12 text-center mt-5"
        >
          {{"No classes scheduled" | translate}}
        </div>
      </div>
      <!-- <h3>Private Lessons :</h3>
      <div class="row mb-5">
        <div class="col-md-4" *ngFor="let class of privateclasses$ | async">
          <a class="top-game-box" routerLink="/finished-classes-detail">
            <img class="img-fluid" src="{{ class.image }}" alt="" />
            <div class="text-area">
              <span class="name">{{ class.gameTitle }}</span>
              <span class="date"
                >{{ formatDate(class.startDate) }} -
                {{ formatDate(class.endDate) }}</span
              >
            </div>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</div>
