import { NgModule } from "@angular/core";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { DashboardComponent } from "./dashboard.component";
import { FilterResultMasterComponent } from "./filter-result-master/filter-result-master.component";
import { FilterResultComponent } from "./filter-result/filter-result.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FilterDialogComponent } from "./filter-dialog/filter-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { SharedAppModule } from "app/shared/shared-app.module";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOptionModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

const routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
  },
  {
    path: "filter-result",
    component: FilterResultComponent,
  },
  {
    path: "filter-result-master",
    component: FilterResultMasterComponent,
  },
];

@NgModule({
  declarations: [
    DashboardComponent,
    FilterResultComponent,
    FilterResultMasterComponent,
    FilterDialogComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    TranslateModule,
    SharedAppModule,
    MatFormFieldModule,
    MatInputModule, 
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatDialogModule
  ],
  providers: [{ provide: "localStorage", useValue: window.localStorage }],
})
export class DashboardModule {}
