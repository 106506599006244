<app-navbar *ngIf="isMobile" [title]="'Class Detail' | translate"></app-navbar>

<div
  class="main"
  *ngIf="classData"
  [ngClass]="{ desktop: !isMobile, mobile: isMobile }"
>

  <div class="group-class-detail">
    <div class="container-fluid">
      <div class="class-date-time" *ngIf='!isMobile'>
        <span class="class-name"
          >{{ classData.title }} {{ classData.subtitle }}</span
        >
        <span class="time">{{ days }} ({{ formatTime(classData.startTime) }}) ({{ formatTime(classData.endTime) }})</span>

        <span class="date"
          >({{ classData.startDate.toDate() | date : "dd MMM, yyyy": "" :'it-IT' }}) - ({{
            classData.endDate.toDate() | date : "dd MMM, yyyy": "" : 'it-IT'
          }})</span
        >
        <span class="students"
          >{{ classData.classStudentsList.length }}
          {{ "Students" | translate }}</span
        >
      </div>
      <div class="class-date-time" *ngIf="isMobile">
        <div class="class-image">
          <img src="{{ classData.image }}" alt="Class image" />
        </div>
        <div class="class-info">
          <span class="class-name"
            >{{ classData.title }} {{ classData.subtitle }}</span
          >
          <span class="time">
            {{ days }}
          </span>
          <span class="time"
            > ({{ classData.startTime }}) ({{
              classData.endTime
            }})</span
          >
          <span class="date"
            >({{ classData.startDate.toDate() | date : "MMM, dd yyyy" }}) - ({{
              classData.endDate.toDate() | date : "MMM, dd yyyy"
            }})</span
          >
          <span class="students"
            >{{ classData.classStudentsList.length }}
            {{ "Students" | translate }}</span
          >
        </div>
      </div>

      <div class="class-cover" *ngIf="!isMobile">
        <img src="{{ classData.image }}" alt="Class Image" />
      </div>
      <div class="tabs-area">
        <div class="nav-action-btns" [ngStyle]="{'position': isMobile ? 'inherit': 'absolute'}" >
          <!--  -->
          <!-- <mat-icon (click)="shareLink()" style="margin-right: 0.5rem;">share</mat-icon> -->
          <button mat-button class="btn btn-danger text-white" color="warn" (click)="contactSupport()">{{"Contact Support" | translate}}</button>
        </div>
        <mat-tab-group
          animationDuration="0ms"
          (selectedTabChange)="TabChanged($event)"
          [ngClass]="{'mt-6': isMobile}"
        >
          <mat-tab [label]="'Register' | translate">
            <div class="lesson-list">
              <a
                class="box"
               
                *ngFor="let activity of classActivities"
              >
                <div class="row">
                  <div class="col-md">
                    <h5>{{ "Game Name:" | translate }}</h5>
                    <input
                      type="text"
                      [value]="activity.gameName"
                      disabled
                      class="form-control"
                      value="Valorant"
                    />
                    <h5>{{ "Class Topic (Subject):" | translate }}</h5>
                    <input
                      type="text"
                      class="form-control"
                      [value]="activity.topic"
                      disabled
                    />
                    <h5>{{ "Date:" | translate }}</h5>
                    <input
                      type="text"
                      class="form-control"
                      [value]="activity.date.toDate() | date: 'dd MMM, yyyy': '': 'it-IT'"
                      disabled
                    />
                  </div>
                  <div class="col-md-5">
                    <h5>{{ "Class Activities:" | translate }}</h5>
                    <textarea
                      class="form-control"
                      [value]="activity.activities"
                      disabled
                    >
                    </textarea>

                    <h5>{{ "Exercies:" | translate }}</h5>
                    <textarea
                      class="form-control"
                      [value]="activity.exercises"
                      disabled
                    >
                    </textarea>
                  </div>
                  <div class="col-md">
                    <div class="tags">
                      <h5>{{ "Student Attendance :" | translate }}</h5>
                      <div
                        *ngFor="
                          let std of getActivityStudentName(
                            activity.presentStudents
                          )
                        "
                        class="tag-box"
                      >
                        {{ std.username }}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </mat-tab>
          <mat-tab [label]="'Calendar' | translate">
            <div class="calendar-box">
              <div class="row">
                <div class="col-md-6" style="border-right: 1px solid #ccc">
                  <select
                    class="form-control"
                    #dateSelect
                    style="width: 10.625rem;"
                    [(ngModel)]="selectedMonthVal"
                    (change)="onMonthChange($event)"
                  >
                    <option
                      *ngFor="let option of dropdownOptions"
                      [value]="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                  <div class="select-date">
                    <ul class="weekdays">
                      <li>{{"Sun" | translate}}</li>
                      <li>{{"Mon" | translate}}</li>
                      <li>{{"Tue" | translate}}</li>
                      <li>{{"Wed" | translate}}</li>
                      <li>{{"Thu" | translate}}</li>
                      <li>{{"Fri" | translate}}</li>
                      <li>{{"Sat" | translate}}</li>
                    </ul>
                    <ul class="days">
                      <li *ngFor="let day of blankDays">
                        <span class="inactive">{{ day }}</span>
                      </li>
                      <li *ngFor="let day of daysInMonth">
                        <span
                          (click)="onDateClick(day)"
                          [ngClass]="{
                            selected: selected === day,
                            'has-class': isClassScheduled(day)
                          }"
                          class="active"
                          >{{ day }}</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <!-- <div style="margin-top: 4rem"></div> -->
                  <!-- <select name="" id="" class="form-control">
                    <option value="">Week 2</option>
                    <option value="">Week 3</option>
                    <option value="">Week 4</option>
                  </select> -->
                  <p style="margin-top: 4rem">
                    {{ selDate | date : "EEEE, d": '': "it-IT" }}
                  </p>
                  <div class="events">
                    <div class="event-time">
                      <ul class="time">
                        <li>00:00</li>
                        <li>01:00</li>
                        <li>02:00</li>
                        <li>03:00</li>
                        <li>04:00</li>
                        <li>05:00</li>
                        <li>06:00</li>
                        <li>07:00</li>
                        <li>08:00</li>
                        <li>09:00</li>
                        <li>10:00</li>
                        <li>11:00</li>
                        <li>12:00</li>
                        <li>13:00</li>
                        <li>14:00</li>
                        <li>15:00</li>
                        <li>16:00</li>
                        <li>17:00</li>
                        <li>18:00</li>
                        <li>19:00</li>
                        <li>20:00</li>
                        <li>21:00</li>
                        <li>22:00</li>
                        <li>23:00</li>
                      </ul>
                    </div>
                    <div class="event-list">
                      <div
                        *ngFor="let class of selectedDayClasses"
                        [style.top.px]="(class.startTimeMinutes / 60) * 38"
                        [style.height.px]="
                          ((class.endTimeMinutes - class.startTimeMinutes) /
                            60) *
                          38
                        "
                        class="event-item box"
                        [ngStyle]="{
                          width: isMobile ? 'auto' : '100%',
                          'font-size': isMobile ? '0.7rem' : 'inherit'
                        }"
                      >
                        <img
                          src="{{ classData.image }}"
                          alt=""
                          style="object-fit: contain"
                        />

                        <span class="event-name">{{ class.gameTitle }}</span>
                        <span class="event-start-time">
                          {{ formatTime(class.startTime) }}
                        </span>
                        <span class="event-end-time">
                          {{ formatTime(class.endTime) }}
                        </span>
                      </div>
                      <!-- <div class="box">
                        <img src="assets/images/event-small.png" alt="" />
                        <div>
                          <span class="title"
                            >Valorant Coaching with pro ESports Player
                          </span>
                          <span class="day">Tuesday & Friday</span>
                        </div>
                      </div>
                      <div class="box">
                        <img src="assets/images/event-small.png" alt="" />
                        <div>
                          <span class="title"
                            >Valorant Coaching with pro ESports Player
                          </span>
                          <span class="day">Tuesday & Friday</span>
                        </div>
                      </div>
                      <div class="box">
                        <img src="assets/images/event-small.png" alt="" />
                        <div>
                          <span class="title"
                            >Valorant Coaching with pro ESports Player
                          </span>
                          <span class="day">Tuesday & Friday</span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab [label]="'Blackboard' | translate">
            <div class="blackboard">
              <h5>{{ "Blackboard Notice" | translate }}</h5>
              <textarea class="black form-control" disabled="true">
              {{ classData ? classData.notice : ("Nothing to show" | translate) }}
            </textarea
              >

              <!--
              <textarea
                class="notice form-control"
                [(ngModel)]="blackboardText"
                [maxLength]="1500"
              ></textarea>
              <div class="text-right mt-5">
                <div class="btn btn-default mr-3">Back</div>
                <div class="btn btn-primary" (click)="saveBlackboard()">
                  {{ "Save" | translate }}
                </div>
              </div> -->
            </div>
          </mat-tab>
          <mat-tab [label]="'Classmates' | translate">
            <div class="students-tab">
              <div class="row">
                <div
                  class="col-md-3"
                  *ngIf="master$ | async as master; "
                >
                  <div class="box card-master">
                    <div class="label">{{"Master" | translate}}</div>
                    <img
                      [src]="master.profilePic ?? '/assets/images/dummy_user.png'"
                      alt=""
                      class="rounded-circle"
                    />
                    <h4>{{ master.nickname ?? master.username ?? 'Master# ' + master.uid  }}</h4>
                    <span class="date">{{
                      master.creationDateTS?.toDate() | date : "MMM, dd yyyy": "" : 'it-IT'
                    }}</span>
                    <button class="btn btn-default" (click)="openChat(master.uid)">
                      {{ "Message" | translate }}
                    </button>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  *ngFor="let std of students; let i = index"
                >
                  <div class="box">
                    <img
                      [src]="std.profilePic ?? '/assets/images/dummy_user.png'"
                      alt=""
                      class="rounded-circle"
                    />
                    <h4>{{ std.nickname ?? std.username ?? 'Student# '  + std.uid }}</h4>
                    <span class="date">{{
                      std.creationDateTS?.toDate() | date : "MMM, dd yyyy"
                    }}</span>
                    <button class="btn btn-default" (click)="openChat(std.uid)">
                      {{ "Message" | translate }}
                    </button>
                  </div>
                </div>
                <!-- <div class="col-md-3">
                  <div class="box">
                    <img src="assets/images/students/2.png" alt="" />
                    <h4>Hazel Housten</h4>
                    <span class="date">From Jul, 27 2022</span>
                    <button class="btn btn-default">Message</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="box">
                    <img src="assets/images/students/3.png" alt="" />
                    <h4>Hazel Housten</h4>
                    <span class="date">From Jul, 27 2022</span>
                    <button class="btn btn-default">Message</button>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="box">
                    <img src="assets/images/students/4.png" alt="" />
                    <h4>Hazel Housten</h4>
                    <span class="date">From Jul, 27 2022</span>
                    <button class="btn btn-default">Message</button>
                  </div>
                </div> -->
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
<div class="loader" *ngIf="!classData">
  <app-loader> </app-loader>
</div>
