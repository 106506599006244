<div class="connect-discord-popup">
  <div class="connect-discord-dialog" [ngClass]="{'mobile-dialog': isMobile}">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>

    <img
      class="discord-img"
      src="assets/images/profile/dialog/discord.png"
      alt=""
    />
    <h3>{{"Connect Your Discord" | translate}}</h3>
    <p>
      {{"Please Connect your discord account with Sweech before you subscribe to any class" | translate}}
    </p>
    <div class="text-center w-100">
      <div class="btn btn-primary" [ngClass]="{
        'padding-mobile': !isMobile
      }" (click)="confirmPurchase()">
        {{"Connect Discord" | translate}}
      </div>
    </div>
  </div>
</div>
