<div class="class-scheduled-popup">
    <div class="class-scheduled-dialog">
      <img class="done-img" src="assets/images/profile/dialog/done.png" alt="" />
      <h3>{{"Class Not Scheduled" | translate}}</h3>
      <p>{{"You can find a master and try to reschedule it." | translate}}</p>
      <div class="text-center w-100">
        <div class="btn btn-primary" (click)="takeMeBack()">{{"Take me back" | translate}}</div>
      </div>
    </div>
  </div>
  