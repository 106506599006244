import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { environment } from "environments/environment";
import { FileUploader, FileUploadModule } from "ng2-file-upload";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Observable, Subject, throwError } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { AuthService } from "app/shared/services/auth.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { User } from "app/shared/services/user";

const CLIENT_SECRET = environment.clientSecret;

@Component({
  selector: "signup-detail",
  templateUrl: "./signup-detail.component.html",
  styleUrls: ["./signup-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class SignUpDetailComponent implements OnInit {
  signUpForm: FormGroup;
  hide: boolean = false;
  hidee: boolean = false;
  uploadUrl = "";
  defaultImageUrl: string = "assets/images/qazxsw.png";
  user: firebase.User;
  discordLink$: Observable<string>;
  content: any;
  users: any;
  isLoggedin: Boolean = false;

  btnDisabled: boolean = false;
  username$: Observable<string>;
  name$: Observable<string>;
  country$: Observable<string>;
  vat$: Observable<string>;
  fiscalCode$: Observable<string>;
  profilePic$: Observable<string>;

  aboutMe: string;
  placeholderText: string = "Select a country";

  languages$: Observable<string[][]>;

  registerDateTS$: Observable<Date>;
  private destroy$ = new Subject<void>();
  userAlreadyExist = false;
  profilePicUrl = "";
  userStep = 2;
  isMobile: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    private _fuseConfigService: FuseConfigService,
    private breakpoint: BreakpointObserver,
    private firestore: AngularFirestore,
    private router: Router,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore // add this line
  ) {
    this.afAuth.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      if (user) {
        this.loadData(user.uid);
        return;
      }
    });
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    // Define the structure and validators of the form
    this.signUpForm = this.formBuilder.group({
      fullName: ["", []],
      userName: ["", Validators.required],
      discordLink: ["", Validators.required],
      country: [{ value: "Italy", disabled: true }, Validators.required],
    });
  }
  loadData(uid: string) {
    const usersRef = this.afs.collection<User>("users");

    const loadData$ = (fieldName: string) =>
      usersRef
        .doc(uid)
        .valueChanges()
        .pipe(
          map((data) => data[fieldName]),
          takeUntil(this.destroy$)
        );

    // check if username already exist then set   userAlreadyExist = true

    this.profilePic$ = loadData$("profilePic");

    usersRef
      .doc(uid)
      .valueChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((doc) => {
        console.log(doc);
        if (doc.isMaster) {
          this.userAlreadyExist = true;
        }
        this.profilePicUrl = doc.profilePic;
        this.userStep = doc.step;
        this.signUpForm.patchValue({
          fullName: doc.name,
          userName: doc.username,
          discordLink: doc.discordLink,
          country: doc.country ?? "Italy",
        });
        // disable all fields
        if (doc.username && doc.discordLink && doc.country && doc.isMaster) {
          this.signUpForm.disable();
        }
      });
  }
  onCountrySelect(event) {
    this.placeholderText = event.name;
  }

  ngOnInit(): void {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;
        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: true,
            },
            toolbar: {
              hidden: true,
            },
            footer: {
              hidden: true,
            },
            sidepanel: {
              hidden: true,
            },
          },
        };
      });
    this.signUpForm
      .get("userName")
      .valueChanges.pipe(
        debounceTime(500), // wait for 500ms after user stops typing
        distinctUntilChanged(), // only emit if the value has changed
        switchMap((username) => this.checkUsernameTaken(username))
      )
      .subscribe((usernameTaken) => {
        if (usernameTaken) {
          this.signUpForm.get("userName").setErrors({ taken: true });
        } else {
          this.signUpForm.get("userName").setErrors(null);
        }
      });
  }
  checkUsernameTaken(username: string) {
    return this.afs
      .collection("users", (ref) => ref.where("username", "==", username))
      .valueChanges()
      .pipe(
        map((users) => users.length > 0) // WRONG
      );
  }

  onselectFile(e) {
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.uploadUrl = event.target.result;
      };
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  async onSubmit() {
    const currentUser = await this.authService.getCurrentUser(); // get the current user

    // create an object with the user input data
    let userData = {
      name: this.signUpForm.get("fullName").value,
      username: this.signUpForm.get("userName").value,
      discordLink: this.signUpForm.get("discordLink").value,
      country: this.signUpForm.get("country").value,
      vat: "",
      fiscalCode: "",
      step: this.userAlreadyExist ? this.userStep : 2,
      about: "",
      enabled: true,
    };

    // Handle image file upload to Firebase Storage
    if (this.uploadUrl) {
      const filePath = `profilePics/${currentUser.uid}.png`; // define the path where the image will be stored
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.uploadUrl);

      // get the download URL and add it to the user's Firestore document
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((downloadURL) => {
              userData["profilePic"] = downloadURL; // add the downloadURL to userData
              this.updateUserData(currentUser.uid, userData);
            });
          }),
          catchError((error) => {
            console.error("Upload failed: ", error);
            return throwError(error);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.updateUserData(currentUser.uid, userData);
    }
  }

  // Function to update user data in Firestore
  updateUserData(userId: string, userData: any) {
    this.authService
      .updateCurrentUser(userId, userData)
      .then(() => {
        console.log("User data updated successfully!");
        this.router.navigate(["/choose-game"], {
          queryParamsHandling: "preserve"
        });
      })
      .catch((error) => {
        console.log("Error updating user data: ", error);
      });
  }
}
