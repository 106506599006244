import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
   MatDialogRef,
 MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { classScheduledDialogComponent } from "../class-scheduled-dialog/class-scheduled-dialog.component";
import { StripeWService } from "app/shared/services/stripe-w.service";

@Component({
  selector: "choose-rate-dialog",
  templateUrl: "./choose-rate-dialog.component.html",
  styleUrls: ["./choose-rate-dialog.component.scss"],
})
export class chooseRateDialogComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<chooseRateDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<classScheduledDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripeService: StripeWService
  ) {}

  classScheduled() {
    this.stripeService.checkout(this.data.selectedRate.price, this.data.cls.uid).then((e) => {
      window.location.href = e.url;
      this.dialogRef.close();
      // this.matDialog.open(classScheduledDialogComponent);
    })
  
  }
}
