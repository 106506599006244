import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from "@fuse/components";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { appConfig } from "app/app-config";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { DashboardModule } from "app/main/dashboard/dashboard.module";
import { AuthorizationsModule } from "./main/authorizations/authorizations.module";
import { AppAuthGuard } from "./app.authguard";
import { InterceptorService } from "./helper-services/interceptor.service";
import { ErrorInterceptor } from "./helper-services/error.interceptor";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire/compat";
import { VerticalLayout1Module } from "./layout/vertical/layout-1/layout-1.module";
import { MessagesModule } from "./main/messages/messages.module";
import { ManagerModule } from "./main/manager/manager.module";
import { ReferralsModule } from "./main/referrals/referrals.module";
import { ProductsModule } from "./main/products/products.module";
import { ManageRatesModule } from "./main/manage-rates/manage-rates.module";
import { SettingsModule } from "./main/settings/settings.module";
import { ProfileModule } from "./main/profile/profile.module";
import { ActivitiesModule } from "./main/activities/activities.module";
import { MyProfileModule } from "./main/my-profile/my-profile.module";
import { logoutConfirmDialogModule } from "./layout/confirm-dialog/confirm-dialog.module";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { CarouselModule } from "ngx-owl-carousel-o";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { SharedAppModule } from "./shared/shared-app.module";
import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";
import { MatMenuModule } from "@angular/material/menu";
import { DatePipe } from "@angular/common";
import { NgxStripeModule } from "ngx-stripe";
import { BarRatingModule } from "ngx-bar-rating";

import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import localeITExtra from '@angular/common/locales/extra/it';
import { MAT_DATE_LOCALE } from "@angular/material/core";

registerLocaleData(localeIT, 'it-IT', localeITExtra);
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    AppAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    // Material
    MatMomentDateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    // Fuse modules
    FuseModule.forRoot(appConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    logoutConfirmDialogModule,
    // App modules
    LayoutModule,
    DashboardModule,
    MessagesModule,
    ManagerModule,
    ReferralsModule,
    ProductsModule,
    ManageRatesModule,
    SettingsModule,
    ProfileModule,
    ActivitiesModule,
    MyProfileModule,
    AuthorizationsModule,
    StoreModule.forRoot({}, {}),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    VerticalLayout1Module,
    NgxIntlTelInputModule,
    CarouselModule,
    TranslateModule.forRoot({
      defaultLanguage: "",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SharedAppModule,
    ToastrModule.forRoot({}),
    NgxStripeModule.forRoot(environment.stripe),
    BarRatingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
