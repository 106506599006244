import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
   MatDialogRef,
 MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { joinServerDialogComponent } from "../join-server-dialog/join-server-dialog.component";

@Component({
  selector: "class-scheduled-dialog",
  templateUrl: "./class-scheduled-dialog.component.html",
  styleUrls: ["./class-scheduled-dialog.component.scss"],
})
export class classScheduledDialogComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<classScheduledDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<joinServerDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  joinServer() {
    this.dialogRef.close();
    this.matDialog.open(joinServerDialogComponent);
  }
}
