import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { FuseConfirmDialogModule, FuseWidgetModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { AuthorizationService } from "./authorization.service";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { LoginComponent } from "./login/login.component";
import { SignUpComponent } from "./signup/signup.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { AppAuthGuard } from "app/app.authguard";
import { LoginService } from "./login/login.service";
import { MatIconModule } from "@angular/material/icon";
import { SignUpService } from "./signup/signup.service";
import { VerificationComponent } from "./verification/verification.component";
import { SignUpDetailComponent } from "./signup-detail/signup-detail.component";

import { ProfileReadyComponent } from "./profile-ready/profile-ready.component";
import { ChooseGameComponent } from "./choose-game/choose-game.component";
import { FileUploadModule } from "ng2-file-upload";
import { Math } from "core-js/library/fn/math";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { RequestGameDialogComponent } from "./choose-game/request-game-dialog/request-game-dialog.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { PhoneAuthComponent } from "./phone-auth/phone-auth.component";
import { TranslateModule } from "@ngx-translate/core";
import { GamingCurriculmComponent } from "./gaming-curriculm/gaming-curriculm.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "signup",
    component: SignUpComponent,
  },
  {
    path: "signup-detail",
    component: SignUpDetailComponent,
  },
  {
    path: "verification",
    component: VerificationComponent,
  },
  {
    path: "choose-game",
    component: ChooseGameComponent,
  },
  {
    path: "gaming-curriculm",
    component: GamingCurriculmComponent,
  },
  {
    path: "phone-auth",
    component: PhoneAuthComponent,
  },
  {
    path: "profile-ready",
    component: ProfileReadyComponent,
  },
  {
    path: "forgot-password",
    pathMatch: "full",
    component: ForgetPasswordComponent,
  },
  {
    path: "**",
    component: SignUpComponent,
  },
  // {
  //     path: 'auth',
  //     canActivate: [AppAuthGuard],
  //     children: [
  //         {
  //             path: 'login',
  //             component: LoginComponent
  //         },
  //         {
  //             path: 'signup',
  //             component: SignUpComponent
  //         },
  //         {
  //             path: 'forgot-password',
  //             pathMatch: 'full',
  //             component: ForgetPasswordComponent
  //         },
  //         {
  //             path: '**',
  //             component: LoginComponent,
  //             canActivate: [AppAuthGuard]
  //         }
  //     ]
  // },
  // {
  //     path: 'reset-password/:id',
  //     component: ResetPasswordComponent
  // }
];

@NgModule({
  declarations: [
    RequestGameDialogComponent,
    LoginComponent,
    SignUpComponent,
    SignUpDetailComponent,
    VerificationComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    PhoneAuthComponent,
    ProfileReadyComponent,
    ChooseGameComponent,
    GamingCurriculmComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NgSelectModule,
    FileUploadModule,
    MatAutocompleteModule,
    CommonModule,
    RouterModule.forRoot(routes),
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDialogModule,
    FuseConfirmDialogModule,
    FuseSharedModule,
    FuseWidgetModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgxIntlTelInputModule,
    TranslateModule,


  ],
  providers: [AuthorizationService, LoginService, SignUpService],
})
export class AuthorizationsModule {}
