import { NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GamingCardComponent } from "./components/gaming-card/gaming-card.component";
import { LoaderComponent } from "./loader/loader.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StripeIntComponent } from "./stripe-int/stripe-int.component";
import { NgxStripeModule } from "ngx-stripe";
import { TranslateModule } from "@ngx-translate/core";
import { ScrollHorizontalDirective } from "./horizontal-scroll.directive";
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { MatIconModule } from "@angular/material/icon";
import { BackButtonComponent } from "./components/back-button/back-button.component";
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatToolbarModule } from "@angular/material/toolbar";
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MatSidenavModule } from "@angular/material/sidenav";
import { SuccessPaymentComponent } from './components/success-payment/success-payment.component';
import { CancelPaymentComponent } from './components/cancel-payment/cancel-payment.component';

@NgModule({
  declarations: [
    GamingCardComponent,
    LoaderComponent,
    ConfirmationModalComponent,
    StripeIntComponent,
    ScrollHorizontalDirective,
    BottomNavComponent,
    BackButtonComponent,
    NavbarComponent,
    SidenavComponent,
    SuccessPaymentComponent,
    CancelPaymentComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    NgxStripeModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
  ],
  exports: [
    GamingCardComponent,
    LoaderComponent,
    ConfirmationModalComponent,
    StripeIntComponent,
    ScrollHorizontalDirective,
    BottomNavComponent,
    BackButtonComponent,
    NavbarComponent,
    SidenavComponent,
    SuccessPaymentComponent
  ],
  // schemas: [NO_ERRORS_SCHEMA]
})
export class SharedAppModule {}
