export const environment = {
  baseUrl: "",
  clientSecret: "",
  hmr: false,
  secure: false,
  production: true,
  version: "",
  firebase: {
    apiKey: "AIzaSyBymBvNlixVLT54VLh6TQY1q8BYrS_Tvrw",
    authDomain: "sweech-3ec98.firebaseapp.com",
    projectId: "sweech-3ec98",
    storageBucket: "sweech-3ec98.appspot.com",
    messagingSenderId: "447760769119",
    appId: "1:447760769119:web:4adbfe90f044c50f2dbbea",
    measurementId: "G-SN1WFBJ2ME",
  },

  stripe: 'pk_live_51NEWk5EDKC04WtEHimy35KXVwulpMOxbxxyLcI2ydqxb0SQhkU9cQeDQNKuvd6GYlQ31JNTzxgY6CppA9sbjlHIP00Y2OldNVu',
  stripe_secret: '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',
  host: 'http://student.sweech.gg'
};
