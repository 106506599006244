import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
declare var $: any;

@Component({
  selector: "manage-rates-private",
  templateUrl: "./manage-rates-private.component.html",
  styleUrls: ["./manage-rates-private.component.scss"],
})
export class ManageRatesPrivateComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor() {}

  ngOnInit() {}
}
