import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { RequestGameDialogComponent } from "../authorizations/choose-game/request-game-dialog/request-game-dialog.component";
import { calendarDialogComponent } from "./calendar/calendar-dialog.component";
import { chooseRateDialogComponent } from "./choose-rate-dialog/choose-rate-dialog.component";
import { subDealDialogComponent } from "./sub-deal-dialog/sub-deal-dialog.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { map, takeUntil } from "rxjs/operators";
import { Observable, Subject, firstValueFrom, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Master } from "app/shared/services/master";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { FirestoreService } from "app/shared/services/firestore.service";
import * as urlMetadata from "url-metadata";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

declare var $: any;

interface Game {
  fieldName: string;
  creationDateTS: Date;
  image: string;
  uid: string;
  name: string;
}

export class ReviewModel {
  uid?: string;
  userId: string;
  userName: string;
  userImage: string;
  userCountry: string;
  review: string;
  date: Date;
  gameAbility: number;
  teachAbility: number;
  pleasantness: number;
  qualityPerPrice: number;
  overallRating: number;
  reply?: string;

  constructor({
    uid,
    userId,
    userName,
    userImage,
    userCountry,
    review,
    gameAbility,
    teachAbility,
    pleasantness,
    qualityPerPrice,
    date,
    reply,
  }: {
    uid?: string;
    userId?: string;
    userName?: string;
    userImage?: string;
    userCountry?: string;
    review?: string;
    gameAbility?: number;
    teachAbility?: number;
    pleasantness?: number;
    qualityPerPrice?: number;
    date?: Date;
    reply?: string;
  }) {
    this.uid = uid;
    this.userId = userId;
    this.userName = userName;
    this.userImage = userImage;
    this.userCountry = userCountry;
    this.review = review;
    this.gameAbility = gameAbility;
    this.teachAbility = teachAbility;
    this.pleasantness = pleasantness;
    this.qualityPerPrice = qualityPerPrice;
    this.date = date;
    this.reply = reply;
    this.overallRating =
      (gameAbility + teachAbility + pleasantness + qualityPerPrice) / 4;
  }
}

interface Product {
  productImage: string;
  productName: string;
  productLink: string;
}

@Component({
  selector: "profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  about: boolean = true;
  deals: boolean = false;
  user: any;
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ].map((e) => this.translate.instant(e));
  formatTime(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  customOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  profilePicture$: Observable<string>;
  rating$: Observable<number>;
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  aboutMe$: Observable<string>;
  name$: Observable<string>;
  country$: Observable<string>;
  aboutMe: string;

  products$: Observable<string[]>;
  reviews$: Observable<ReviewModel[]>;
  gameAbility$: Observable<number>;
  abilitytoteach$: Observable<number>;
  Pleasantness$: Observable<number>;
  QualityPrice$: Observable<number>;
  rates: any[] = [];
  rates$;
  coverPic$: Observable<string>;
  games$: Observable<Game[]>;
  registerDateTS$: Observable<firebase.default.firestore.Timestamp>;

  languages$: Observable<string[]> = of([]);

  rating: number = 0;
  registereddate: Date;
  abilitytogame: number = 0;
  abilitytoteach: number = 0;
  Pleasantness: number = 0;
  QualityPrice: number = 0;

  userData$: Observable<any>;
  userData: any;
  gamesData = [];
  gameIds: any;
  reviews: any = [];
  isMobile: boolean;

  selectedRate: any;
  isRateClicked = false;

  classesForSelectedRate = [];
  isAllowedToReview = false;

  newReview: ReviewModel = new ReviewModel({});

  loggedInUserData;

  today = new Date();
  private _unsubscribeAll: Subject<any> = new Subject();
  showAboutMe = false; // Added for toggle
  userUid = '';
  toggleAboutMe() {
    this.showAboutMe = !this.showAboutMe;
  }
  constructor(
    private matDialog: MatDialog,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dynamicLinkService: DynamicLinkService,

    ) {}
  public ngAfterViewInit(): void {
    window.dispatchEvent(new Event("resize"));
  }

  onFaoRate($event) {}
  async queryClassesWithMasterIdAndStudentId(
    uid: string,
    studentId: string
  ): Promise<any[]> {
    const classesWithStudent: any[] = [];

    const querySnapshot = await firstValueFrom(
      this.firestoreService
        .col("classes", (ref) => ref.where("teacherId", "==", uid))
        .get()
    );

    for (const doc of querySnapshot.docs) {
      const classData = doc.data();
      if (
        classData["classStudentsList"] &&
        classData["classStudentsList"].includes(studentId)
      ) {
        // Check if the student has already reviewed this class
        const reviewSnapshot = await firstValueFrom(
          this.firestoreService
            .col(`users/${uid}/reviews`, (ref) =>
              ref.where("userId", "==", studentId)
            )
            .get()
        );

        if (reviewSnapshot.empty) {
          // If the review documents are empty, the student has not reviewed this class
          classesWithStudent.push(classData);
        }
      }
    }

    return classesWithStudent;
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  ngOnInit(): void {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((o) => {
        if (o.matches) {
          this.isMobile = o.matches;
          this._fuseConfigService.config = {
            layout: {
              navbar: {
                hidden: this.isMobile,
              },
              toolbar: {
                hidden: this.isMobile,
              },
              footer: {
                hidden: this.isMobile,
              },
              sidepanel: {
                hidden: this.isMobile,
              },
            },
          };
        }
      });
    this.route.queryParams.subscribe((params) => {
      let userUid = localStorage.getItem("userUid");
      if (params["userId"]) {
        userUid = params["userId"];
      }
      this.userUid = userUid;
      this.afAuth.authState
        .pipe(
          switchMap((user) => {
            if (user) {
              this.user = user;
              this.queryClassesWithMasterIdAndStudentId(userUid, user.uid).then(
                (e) => {
                  console.log(e);
                  if (e && e.length) {
                    this.isAllowedToReview = true;
                  }
                }
              );

              this.afs
                .collection("users")
                .doc(user.uid)
                .valueChanges()
                .subscribe((us) => {
                  console.log(us);
                  this.loggedInUserData = us;
                });
              const userDoc$ = this.afs
                .collection<Master>("users")
                .doc(userUid)
                .get();
              userDoc$.subscribe((doc) => {
                if (doc.exists) {
                  const data = doc.data();
                  this.userData = data;
                  data.productLinks = (data.productLinks ?? []).filter(
                    (p) => !!p
                  );
                  // data.productLinks.map((p) => {
                  //   console.log(p);
                  //   return urlMetadata(p, {
                  //     mode: "no-cors",
                  //   })
                  //     .then((data) => {
                  //       console.log(data);
                  //     })
                  //     .catch((err) => {
                  //       console.log(err);
                  //     });
                  // });
                  this.gameIds = (data.gameIds ?? []) as [];
                  this.products$ = of(
                    (data.productLinks ?? []).filter((p) => !!p)
                  );
                  this.firestoreService.col$("games").subscribe((games: []) => {
                    this.gamesData = games.filter((g: any) =>
                      (data.gameIds ?? []).includes(g.uid)
                    );
                    this.games$ = of(
                      games.filter((g: any) =>
                        (data.gameIds ?? []).includes(g.uid)
                      )
                    );
                  });
                }
              });

              this.rates$ = this.afs
                .collection("users")
                .doc(userUid)
                .collection("rates", (e) => e.where("hidden", "==", false))
                .valueChanges();
              this.rates$.subscribe((rates) => {
                this.rates = rates;
              });
              this.languages$ = userDoc$.pipe(
                map((doc) => doc.data().languages)
              );
              this.profilePicture$ = userDoc$.pipe(
                map(
                  (doc) =>
                    doc.data().profilePic ?? "assets/images/dummy_user.png"
                )
              );
              this.name$ = userDoc$.pipe(map((doc) => doc.data().username));
              this.country$ = userDoc$.pipe(map((doc) => doc.data().country));
              this.aboutMe$ = userDoc$.pipe(map((doc) => doc.data().about));
              this.rating$ = userDoc$.pipe(map((doc) => doc.data().rating));
              this.coverPic$ = userDoc$.pipe(map((doc) => doc.data().coverPic));
              this.gameAbility$ = userDoc$.pipe(
                map((doc) => doc.data().gameAbility)
              );
              this.abilitytoteach$ = userDoc$.pipe(
                map((doc) => doc.data().teachAbility)
              );
              this.Pleasantness$ = userDoc$.pipe(
                map((doc) => doc.data().pleasantness)
              );
              this.QualityPrice$ = userDoc$.pipe(
                map((doc) => doc.data().qualityPerPrice)
              );
              this.registerDateTS$ = userDoc$.pipe(
                map((doc) => doc.data().creationDateTS)
              );

              return userDoc$;
            } else {
              throw "User not logged in";
            }
          }),
          switchMap((userDoc) => {
            const userGames = userDoc.data().gameIds;
            console.log(userGames);
            this.rating = userDoc.data().rating;
            const productsRef = this.afs
              .collection("users")
              .doc(userUid)
              .collection<Product>("products");
            const reviewsRef = this.afs
              .collection("users")
              .doc(userUid)
              .collection<ReviewModel>("reviews");

            // this.products$ = productsRef.valueChanges();
            this.reviews$ = reviewsRef.valueChanges();

            return of(true);
          })
        )
        .subscribe(() => {});
    });
  }

  showDeals() {
    this.about = false;
    this.deals = true;
  }
  getDiscountedPrice(rate) {
    return parseFloat((rate.discounts.length > 0
      ? rate.price * ((100 - rate.discounts[0].percent) / 100)
      : rate.price).toFixed(2));
  }

  shareLink() {
    console.log(this.userUid);
    this.dynamicLinkService
      .createDynamicLink(this.userUid)
      .pipe(map((link: any) => link.shortLink))
      .subscribe((link) => {
        console.log(link);
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(link)
            .then(() => {
              this.toastr.success(
                this.translate.instant("Link copied to clipboard"),
                "Success"
              );
            })
            .catch((err) => {
              this.toastr.error(
                this.translate.instant("Failed to copy link"),
                "Error"
              );
            });
        } else {
          // Clipboard API not available
          this.toastr.error(
            this.translate.instant("Clipboard not supported"),
            "Error"
          );
        }
        this.openDialog(link);
      });
  }
  openDialog(link: string): void {
    this.matDialog.open(LinkDialogComponent, {
      width: "450px",
      data: { link: link },
    });
  }
  formatURL(link: string): string {
    if (!link) return "";

    if (link.startsWith("http://") || link.startsWith("https://")) {
      return link;
    } else if (link.startsWith("www.")) {
      return "https://" + link;
    } else {
      return "https://" + "www." + link;
    }
  }
  showAbout() {
    this.deals = false;
    this.about = true;
    this.isRateClicked = false;
  }
  formatUrl(url: string): string {
    if (!url) {
      return ""; // Return an empty string if the URL is not provided
    }

    // Check if the URL starts with 'http://' or 'https://'
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      // If not, prepend 'https://' to the URL
      return "https://" + url;
    }
  }

  supportDialog() {
    this.matDialog.open(RequestGameDialogComponent, {
      data: {
        isFromClass: false,
      },
    });
  }

  openInfoDialog(classInfo, event: MouseEvent): void {
    event.stopPropagation(); // This prevents the click from bubbling up to the parent
    this.matDialog.open(InfoDialogComponent, {
      width: "350px",
      data: {
        cls: classInfo,
      }, // pass data to the dialog
    });
  }
  subDealDialog(cls) {
    this.matDialog.open(subDealDialogComponent, {
      data: {
        cls: cls,
        selectedRate: this.selectedRate,
      },
    });
  }

  calendarDialog() {
    this.matDialog.open(calendarDialogComponent);
  }
  chooseRateDialog(rate) {
    this.isRateClicked = true;
    this.selectedRate = rate;
    this.afs
      .collection("classes", (ref) => ref.where("rateId", "==", rate.uid))
      .valueChanges()
      .subscribe((classes: any[]) => {
        this.classesForSelectedRate = classes;
        console.log(classes);
      });
    // show list of all classes
    // this.matDialog.open(chooseRateDialogComponent);
  }

  gameNames(rate) {
    return rate.gamesForRate
      ? rate.gamesForRate.map((g) => g.name).join(",")
      : "";
  }
  scheduleDayClass(cls) {
    const scheduleDays = cls.scheduleDays;

    if (scheduleDays) {
      const scheduled = scheduleDays.reduce((acc, cur, index) => {
        if (cur) {
          acc.push(this.days[index]);
        }
        return acc;
      }, []);

      const scheduledDaysString = scheduled.join(", ");
      return scheduledDaysString;
    }
    return "";
  }

  shouldRateEnable() {
    return !(
      this.newReview.review != "" &&
      this.newReview.gameAbility &&
      this.newReview.teachAbility &&
      this.newReview.pleasantness &&
      this.newReview.qualityPerPrice
    );
  }

  async saveReview() {
    if (
      this.newReview.review != "" &&
      this.newReview.gameAbility &&
      this.newReview.teachAbility &&
      this.newReview.pleasantness &&
      this.newReview.qualityPerPrice
    ) {
      this.newReview.date = new Date();
      this.newReview.userId = this.loggedInUserData.uid;
      this.newReview.userName = this.loggedInUserData.nickname || this.loggedInUserData.username  ;
      this.newReview.userImage = this.loggedInUserData.profilePic;
      this.newReview.userCountry = this.loggedInUserData.country;

      this.newReview.uid = this.afs.createId();
      this.newReview.reply = "";
      this.newReview.overallRating =
        (this.newReview.gameAbility +
          this.newReview.teachAbility +
          this.newReview.pleasantness +
          this.newReview.qualityPerPrice) /
        4;

      console.log(this.newReview);
      this.afs
        .collection("users")
        .doc(this.userData.uid)
        .collection("reviews")
        .doc(this.newReview.uid)
        .set({ ...this.newReview })
        .then((e) => {
          this.newReview = new ReviewModel({});
          this.toastr.success("Review added successfully");
          this.isAllowedToReview = false;
        });
    }
  }

  isInteger(fltNumber) {
    return fltNumber % 1 === 0;
  }
}

import { Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DynamicLinkService } from "app/shared/services/dynamic-link.service";
import { LinkDialogComponent } from "../manager/group-class-detail/group-class-detail.component";

@Component({
  selector: "app-info-dialog",
  template: `
    <p mat-dialog-content>
      {{ data.cls.description == "" ? "No Description" : data.cls.description }}
    </p>
    <div mat-dialog-actions>
      <button mat-button color='primary' mat-dialog-close>Close</button>
    </div>
  `,
  styleUrls: [],
})
export class InfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
