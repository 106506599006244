<div class="card-detail-popup">
  <div class="card-detail-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      <i class="fa fa-times cross"></i>
    </button>
    <h3>{{"Payment Information" | translate}}</h3>
    <!-- <img class="card-design" src="assets/images/card-design.png" alt="" /> -->
    <form>
      <div class="row">
        <div class="col-md-12">
          <div id="card-element" class="field"></div>
          <!-- <div class="form-group">
            <label>{{"Name" | translate}}</label>
            <input
              type="text"
              class="form-control"
              id=""
              placeholder=""
              autocomplete="off"
              name="name"
              [(ngModel)]="name"
            />
          </div> -->
        </div>
        <!-- <div class="col-md-12">
          <div class="form-group">
            <label>{{"Card No" | translate}}</label>
            <input
              type="text"
              class="form-control"
              id=""
              placeholder=""
              [maxlength]="19"
              autocomplete="off"
              [(ngModel)]="card.number"
              name="number"
              appCreditCardFormatter
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>CVC</label>
            <input
              type="text"
              class="form-control"
              id=""
              placeholder=""
              autocomplete="off"
              [(ngModel)]="card.cvc"
              name="cvc"
              [maxlength]="3"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>{{"Month" | translate}}</label>
            <select class="form-control" [(ngModel)]="card.exp_month" name="exp_month">
              <option *ngFor="let m of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]">
                {{ m }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>{{"Year" | translate}}</label>
            <input class="form-control" [(ngModel)]="card.exp_year" name="exp_year" [min]="yearsList" />
          </div>
        </div> -->
        <!-- <div class="col-md-6">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="remember" />
            <label class="form-check-label" for="remember"
              >Save Credit Card Information</label
            >
          </div>
        </div> -->
        <div class="col-md-12 text-center mt-3" style="margin-bottom: 2rem;">
          <button class="btn btn-primary" type="button" (click)="addCard()">
            {{"Add Credit Card" | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
