<div class="class-scheduled-popup">
  <div class="class-scheduled-dialog" [ngClass]="{ 'mobile-dialog': isMobile }">
    <img class="done-img" src="assets/images/profile/dialog/done.png" alt="" />
    <h3>{{ "Class Scheduled" | translate }}</h3>
    <p>
      {{
        "You can find your class under “My Classes” tab on the App" | translate
      }}
    </p>
    <div class="text-center w-100">
      <div
        class="btn btn-primary"
        [ngClass]="{
          'padding-mobile': !isMobile
        }"
        (click)="joinServer()"
      >
        {{ "Done" | translate }}
      </div>
    </div>
  </div>
</div>
