import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { bookLessonDialogComponent } from "../book-lesson-dialog/book-lesson-dialog.component";
declare var $: any;

@Component({
  selector: "private-class-detail",
  templateUrl: "./private-class-detail.component.html",
  styleUrls: ["./private-class-detail.component.scss"],
})
export class PrivateClassDetailComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {}

  bookLessonDialog() {
    this.matDialog.open(bookLessonDialogComponent);
  }
}
