import { Component, Input } from '@angular/core';
import * as firebase from 'firebase/compat';
@Component({
  selector: 'app-gaming-card',
  templateUrl: './gaming-card.component.html',
  styleUrls: ['./gaming-card.component.scss']
})
export class GamingCardComponent {
  @Input() imageUrl: string = '';
  @Input() title: string = '';
  @Input() subtitleGreen: string = '';
  @Input() subtitleWhite: string = '';
  @Input() startDate: any;
  @Input() startTime: string = '';
  @Input() endTime: string = '';
}
