import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { calendarDialogComponent } from "./calendar/calendar-dialog.component";
declare var $: any;

@Component({
  selector: "rates-edit",
  templateUrl: "./rates-edit.component.html",
  styleUrls: ["./rates-edit.component.scss"],
})
export class RatesEditComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {}

  calendar() {
    this.matDialog.open(calendarDialogComponent);
  }
}
