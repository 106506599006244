<div class="main">
  <div class="class-setting">
    <div class="container-fluid">
      <div class="class-cover">
        <img src="assets/images/manager/cover.png" alt="" />
      </div>
      <div class="setting-area">
        <div class="row">
          <div class="col-md-5">
            <h3>Manage Students</h3>
            <div class="setting-box">
              <form>
                <div class="head-area">
                  <div class="checkbox-area">
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="move"
                      />
                      <label class="form-check-label" for="move"
                        >Move Students</label
                      >
                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="remove"
                      />
                      <label class="form-check-label" for="remove"
                        >Remove Students</label
                      >
                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="add"
                      />
                      <label class="form-check-label" for="add"
                        >Add Students</label
                      >
                    </div>
                  </div>
                  <div class="right-side">
                    <h4>Students</h4>
                    <input type="text" class="form-control" />
                    <h4 class="mt-4">Class</h4>
                    <input
                      type="text"
                      class="form-control"
                      value="Valorant Tips & Tricks "
                    />
                    <span class="to">to</span>
                    <input
                      type="text"
                      class="form-control"
                      value="Fornite One Shot Maniac"
                    />
                  </div>
                </div>
                <div class="description">
                  <h4 class="mb-3">Description</h4>
                  <textarea class="form-control"></textarea>
                </div>
                <div class="text-right">
                  <button class="btn btn-primary">Send</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-5 offset-md-1">
            <h3>Manage Class</h3>
            <div class="setting-box">
              <form>
                <div class="head-area">
                  <div class="checkbox-area">
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="block"
                      />
                      <label class="form-check-label" for="block"
                        >Block Subscriptions</label
                      >
                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="delete"
                      />
                      <label class="form-check-label" for="delete"
                        >Delete Class</label
                      >
                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="postpone"
                      />
                      <label class="form-check-label" for="postpone"
                        >Postpone Start Date</label
                      >
                    </div>
                  </div>
                  <div class="right-side">
                    <div class="motivation">
                      <h4 class="mb-3">Motivation</h4>
                      <textarea class="form-control"></textarea>
                    </div>
                  </div>
                </div>
                <div class="text-right mt-5">
                  <button class="btn btn-primary">Send</button>
                </div>
              </form>
            </div>
            <div class="discount">
              <h5>Discounts :</h5>
              <div class="discount-box">
                <div class="list">
                  <span class="text"
                    ><u>25%</u> off for the first <u>15</u> students by using
                    this promo code
                  </span>
                  <input type="text" class="form-control" value="W2xyB" />
                </div>
                <div class="list">
                  <span class="text"
                    ><u>15%</u> off for the first <u>10</u> students by using
                    this promo code
                  </span>
                  <input type="text" class="form-control" value="10x15zY" />
                </div>
              </div>
            </div>

            <button class="btn support-btn" (click)="supportDialog()">
              Contact Support
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
