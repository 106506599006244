import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
declare var $: any;

@Component({
  selector: "private-fininshed-class-detail",
  templateUrl: "./private-fininshed-class-detail.component.html",
  styleUrls: ["./private-fininshed-class-detail.component.scss"],
})
export class PrivateFinishedClassDetailComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor() {}

  ngOnInit() {}
}
