import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { SettingsComponent } from "./settings.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateModule } from "@ngx-translate/core";
import { SharedAppModule } from "app/shared/shared-app.module";
import { cardDetailDialogComponent } from "./card-detail-dialog/card-detail-dialog.component";
import { deleteConfirmDialogModule } from "./confirm-dialog/confirm-dialog.module";

const routes = [
  {
    path: "settings",
    component: SettingsComponent,
  },
];

@NgModule({
  declarations: [SettingsComponent, cardDetailDialogComponent],
  imports: [RouterModule.forChild(routes), FuseSharedModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    SharedAppModule,
    MatSnackBarModule,
    deleteConfirmDialogModule,
    TranslateModule,],
})
export class SettingsModule {}
