import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { connectDiscordDialogComponent } from "../connect-discord-dialog/connect-discord-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "sub-deal-dialog",
  templateUrl: "./sub-deal-dialog.component.html",
  styleUrls: ["./sub-deal-dialog.component.scss"],
})
export class subDealDialogComponent {
  cls;
  days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((r) =>
    this.translate.instant(r)
  );
  selectedRate;
  isMobile: boolean;
  private _unsubscribeAll: Subject<any> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<connectDiscordDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {
    this.cls = data.cls;
    this.selectedRate = data.selectedRate;

    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((o) => {
        if (o.matches) {
          this.isMobile = o.matches;
          this._fuseConfigService.config = {
            layout: {
              navbar: {
                hidden: this.isMobile,
              },
              toolbar: {
                hidden: this.isMobile,
              },
              footer: {
                hidden: this.isMobile,
              },
              sidepanel: {
                hidden: this.isMobile,
              },
            },
          };
        }
      });
  }

  formatTime(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  getDiscountedPrice(rate, cls) {
    let discountPercent = 0;
  
    // Check for discounts in rate
    if (rate.discounts && rate.discounts.length > 0) {
      discountPercent = Math.max(discountPercent, rate.discounts[0].percent);
    }
  
    // Check for discounts in cls
    if (cls.discounts && cls.discounts.length > 0) {
      discountPercent = Math.max(discountPercent, cls.discounts[0].percent);
    }
  
    // Calculate discounted price
    return rate.price * ((100 - discountPercent) / 100);
  }
  
  cardDetail() {
    this.dialogRef.close();
    this.matDialog.open(connectDiscordDialogComponent, {
      data: {
        cls: this.cls,
        selectedRate: this.selectedRate,
      },
    });
  }

  scheduleDayClass(cls) {
    const scheduleDays = cls.scheduleDays;

    if (scheduleDays) {
      const scheduled = scheduleDays.reduce((acc, cur, index) => {
        if (cur) {
          acc.push(this.days[index]);
        }
        return acc;
      }, []);

      const scheduledDaysString = scheduled.join(", ");
      return scheduledDaysString;
    }
    return "";
  }
}
