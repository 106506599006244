import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {  MatDialogModule } from "@angular/material/dialog";
import { logoutConfirmDialogComponent } from "./confirm-dialog.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [logoutConfirmDialogComponent],
  imports: [MatDialogModule, MatButtonModule, TranslateModule],
})
export class logoutConfirmDialogModule {}
