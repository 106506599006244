<div class="join-server-popup">
  <div class="join-server-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <img
      class="discord-img"
      src="assets/images/profile/dialog/discord.png"
      alt=""
    />
    <p>{{"Click on the join button to join the Discord server of your class" | translate}}</p>
    <div class="text-center w-100">
      <div class="btn btn-primary" (click)="goClasses()">{{"Join Server" | translate}}</div>
    </div>
  </div>
</div>
