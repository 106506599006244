<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main pt-2">
  <div class="dashboard">
    <div class="container-fluid">
      <div class="search-games">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="d-flex align-items-center">
              <div class="form-group" >
                <i class="fa fa-search ng-tns-c208-14"></i>
                <input
                  type="text"
                  class="form-control"
                  [placeholder]="'Search game you love' | translate"
                  [(ngModel)]="searchText"
                  (keyup.enter)="searchGames()"
                />
              </div>
              <button class="btn-2" style='margin-right: 0.5rem;' (click)="searchGames()">
                <mat-icon>search</mat-icon>
              </button>
              <!-- <button class="btn" routerLink="/choose-game">
                <img src="assets/images/controler.png" alt="" />
              </button> -->
            </div>
          </div>
        </div>
      </div>

      <h2>{{"Recommended Masters :" | translate}}</h2>
      <div class="students-tab">
        <div class="row stu-master hide-scrollbar" appScrollHorizontal>
          <div class="col-md-3" *ngFor="let user of users">
            <div class="box">
              <a [routerLink]="['/profile']" (click)="storeUid(user.uid)"
                ><img
                  *ngIf="user.profilePic; else noProfilePicture"
                  src="{{ user.profilePic }}"
                  class="rounded-circle"
                  (error)="imageDidNotLoad($event)"
                  alt="" />
                <ng-template #noProfilePicture>
                  <img
                    src="assets/images/dummy_user.png"
                    class="rounded-circle"
                    alt="Profile Picture"
                  /> </ng-template
              ></a>
              <h4>{{ user.username }}</h4>
              <span class="date"
                >{{"From" | translate}} {{ formatDate(user.creationDateTS) }}</span
              >
              <button class="btn btn-default" routerLink="/messages/{{user.uid}}">
                {{"Message" | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <h2>{{"Top Games" | translate}}</h2>
      <div class="row mb-4 stu-master  hide-scrollbar" appScrollHorizontal>
        <div class="col-md-4 col-6" *ngFor="let game of games">
          <div class="top-game-box" (click)="gameClicked(game)" routerLink="/filter-result">
            <img class="img-fluid" [ngClass]="{'mobilecard': isMobile}" src="{{ game.image }}" />
            <span class="name">{{ game.name }}</span>
          </div>
        </div>
      </div>
      <h2>{{"Categories" | translate}}</h2>
      <div class="row category hide-scrollbar " appScrollHorizontal>
        <div class="col-md-3 col-6" *ngFor="let category of categories">
          <div
            class="box"
            routerLink="/filter-result"
            [queryParams]="{ tab: 'games', category: category.name }"
          >
            <img class="img-fluid" [src]="'assets/images/cat-images/'+category.pic + '-cat.png'" alt="" />
            <span>{{ category.name | translate }}</span>
          </div>
        </div>
      </div>

      <h2>{{"Top Masters" | translate}}</h2>
      <div class="students-tab top-masters">
        <div class="row  stu-master hide-scrollbar" appScrollHorizontal>
          <div class="col-md-3 col-6" *ngFor="let user of topusers">
            <div class="box">
              <a [routerLink]="['/profile']" (click)="storeUid(user.uid)"
                ><img
                  *ngIf="user.profilePic; else noProfilePicture"
                  src="{{ user.profilePic }}"
                  class="rounded-circle"
                  (error)="imageDidNotLoad($event)"
                  alt="" />
                <ng-template #noProfilePicture>
                  <img
                    src="assets/images/dummy_user.png"
                    class="rounded-circle"
                    alt="Profile Picture"
                  /> </ng-template
              ></a>
              <h4>{{ user.username }}</h4>
              <span class="date">{{ formatDate(user.creationDateTS) }}</span>
              <div class="master-rating">
                <span class="total">{{(user.rating ?? 0)}}</span>
                <div class="stars">
                  <ng-container *ngFor="let i of [0, 1, 2, 3, 4]">
                    <i class="fa-star fas" *ngIf="i < (user.rating ?? 0)"></i>
                    <i class="fa-star far" *ngIf="i >= (user.rating ?? 0)"></i>
                  </ng-container>
                </div>
              </div>
              <button class="btn btn-default" routerLink="/messages/{{user.uid}}">
                {{"Message" | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <h2>{{"New Masters" | translate}}</h2>
      <div class="students-tab">
        <div class="row stu-master hide-scrollbar" appScrollHorizontal>
          <div class="col-md-3 col-6" *ngFor="let user of newusers">
            <div class="box">
              <a [routerLink]="['/profile']" (click)="storeUid(user.uid)"
                ><img
                  *ngIf="user.profilePic; else noProfilePicture"
                  src="{{ user.profilePic }}"
                  class="rounded-circle"
                  alt=""
                  (error)="imageDidNotLoad($event)"
                />
                <ng-template #noProfilePicture>
                  <img
                    src="assets/images/dummy_user.png"
                    class="rounded-circle"
                    alt="Profile Picture"
                  />
                </ng-template>
              </a>
              <h4>{{ user.username }}</h4>
              <span class="date">{{ formatDate(user.creationDateTS) }}</span>
              <button class="btn btn-default" routerLink="/messages/{{user.uid}}">
                {{"Message" | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
