import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { User } from "app/shared/services/user";
import * as firebase from "firebase/compat/app";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
declare var $: any;

@Component({
  selector: "my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.scss"],
})
export class MyProfileComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  user: any;
  name$: any;
  profilePicture$: any;
  classes$: Observable<any[]>;
  finishedClasses$: Observable<any[]>;
  privateclasses$: Observable<any[]>;
  loggedInUser = "";
  isMobile: any = false;
  formatDate(timestamp: any) {
    const date = new Date(timestamp.seconds * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" } as const;
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {}

  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
      const loggedInUserId = user?.uid;
      this.loggedInUser = loggedInUserId;
      if (user) {
        const usersRef = this.afs.collection<User | any>("users");

        this.classes$ = this.afs
          .collection("classes", (ref) =>
            ref
              .where("isGroupClass", "==", true)
              .where("classStudentsList", "array-contains", loggedInUserId)
              .where(
                "endDate",
                ">=",
                firebase.default.firestore.Timestamp.now()
              )
          )
          .snapshotChanges()
          .pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as object;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );
        this.finishedClasses$ = this.afs
          .collection("classes", (ref) =>
            ref
              .where("isGroupClass", "==", true)
              .where("classStudentsList", "array-contains", loggedInUserId)
              .where("endDate", "<", firebase.default.firestore.Timestamp.now())
          )
          .snapshotChanges()
          .pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as object;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );

        this.name$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(map((doc) => doc.data().nickname || "User"));
        this.profilePicture$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(
            map(
              (doc) => doc.data().profilePic || "assets/images/dummy_user.png"
            )
          );
      }
    });
  }
}
