import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FilterDialogComponent } from "../filter-dialog/filter-dialog.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Master } from "app/shared/services/master";
import {
  BehaviorSubject,
  Observable,
  forkJoin,
  lastValueFrom,
  map,
  of,
  take,
} from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
declare var $: any;
// INFO: users means masters
@Component({
  selector: "filter-result",
  templateUrl: "./filter-result.component.html",
  styleUrls: ["./filter-result.component.scss"],
})
export class FilterResultComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  selectedDurations: string[] = [];
  selectedLanguages: string[] = [];

  selectedPlatforms: string[] = [];
  user: any;
  search: string;

  games: any[] = [];

  gamesData: any[] = [];
  mastersData: Master[] = [];
  rates: any[][] = [];
  ratesData: any[][] = [];

  dropdownValue = new BehaviorSubject<string>("");
  selectedIndex = 0;

  isMobile: any = false;
  formatDate(timestamp: any) {
    const date = new Date(timestamp.seconds * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" } as const;
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  constructor(
    private matDialog: MatDialog,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {}
  storeUid(uid: string) {
    localStorage.setItem("userUid", uid);
    const userUid = localStorage.getItem("userUid");
    console.log(userUid);
  }

  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.search = localStorage.getItem("search");

    this.selectedDurations = JSON.parse(
      localStorage.getItem("selectedDurations")
    );
    this.selectedLanguages = JSON.parse(
      localStorage.getItem("selectedLanguages")
    );
    this.selectedPlatforms = JSON.parse(
      localStorage.getItem("selectedPlatforms")
    );
    this.route.queryParams.subscribe((params) => {
      if (params.category) {
        this.search = params.category;
      }
      if (params.tab) {
        this.selectedIndex = params.tab == "games" ? 1 : 0;
      }
    });

    this.afs
      .collection("users", (w) =>
        w.where("isMaster", "==", true).where("isVerified", "==", 2).where('hideMaster', '==', false)
      )
      .get()
      .subscribe((querySnapshot) => {
        // Convert the query snapshot to an array of users
        this.users = querySnapshot.docs.map((doc) => doc.data());
        this.mastersData = this.users;
        console.log(this.users);
        this.users = this.filterMasters(this.search, this.users);
        console.log(this.users);
        this.filterData();
        // this.fetchAndMapRates(this.users).subscribe((rates) => {
        //   this.rates = rates;
        //   this.ratesData = rates;
        //   this.filterData();
        // });
      });
    this.afs
      .collection("games")
      .get()
      .subscribe((querySnapshot) => {
        // Convert the query snapshot to an array of users
        this.games = querySnapshot.docs.map((doc) => doc.data());
        this.gamesData = this.games;
        this.games = this.filterGames(this.search, this.games);
      });

    const userUid = localStorage.getItem("userUid");
    console.log(userUid);

    // Get the currently logged-in user
  }

  fetchAndMapRates(users: Master[]): Observable<any[][]> {
    if (users.length === 0) {
      return of([]);
    }

    const fetchRatesObservables = users.map((user) =>
      this.afs
        .collection(`users/${user.uid}/rates`)
        .get()
        .pipe(
          map((querySnapshot) => {
            if (querySnapshot.empty) {
              return [];
            }

            return querySnapshot.docs.map((doc) => {
              const data = doc.data() as any; // Replace 'any' with your actual data model
              return data; // Assuming you have a fromMap method in RateModel
            });
          })
        )
    );

    return forkJoin(fetchRatesObservables);
  }

  filter() {
    this.matDialog.open(FilterDialogComponent);
  }
  imageDidNotLoad(event) {
    console.log("image did not load");
    event.target.src = "assets/images/dummy_user.png";
  }
  filterGames(searchStr: string, games: any[]) {
    return games.filter(
      (game) =>
        game.name?.toLowerCase().includes(searchStr.toLowerCase()) ||
        game.altName
          ?.map((tag) => tag.toLowerCase())
          .includes(searchStr.toLowerCase()) ||
        game.category
          ?.map((category) => category.toLowerCase())
          .includes(searchStr.toLowerCase())
    );
  }
  filterMasters(searchStr: string, users: Master[]): Master[] {
    return users.filter(
      (user) => {
        console.log(user.gamesTeached);
        return user.name?.toLowerCase() == searchStr.toLowerCase() ||
        user.username?.toLowerCase().includes(searchStr.toLowerCase()) ||
        user.gamesTeached
          ?.map((game) => game.toLowerCase())
          .some((game) => game.includes(searchStr.toLowerCase()))
      });
  }

  updateRates() {
    this.ratesData;
  }

  searchMasters() {
    this.users = this.filterMasters(this.search, this.mastersData);
    this.games = this.filterGames(this.search, this.gamesData);
    localStorage.setItem("search", this.search);
  }

  updateData(updateArray?: Master[]) {
    this.mastersData = updateArray || this.mastersData;
    this.users = this.filterMasters(this.search, this.mastersData);
    console.log(this.users);
    return this.fetchAndMapRates(this.users).subscribe((rates) => {
      this.rates = rates;
      this.ratesData = rates;
    });
  }

  sortMasters(): void {
    this.dropdownValue.pipe(take(1)).subscribe((dropdownValue) => {
      console.log(dropdownValue);
      const indices: number[] = Array.from(
        { length: this.users.length },
        (_, i) => i
      );

      if (dropdownValue === "Ascending Price") {
        indices.sort((aIndex, bIndex) => {
          const aMinRate =
            this.rates[aIndex].length === 0
              ? Number.MAX_SAFE_INTEGER
              : Math.min(...this.rates[aIndex].map((e: any) => e.price)); // assuming 'price' property exists
          const bMinRate =
            this.rates[bIndex].length === 0
              ? Number.MAX_SAFE_INTEGER
              : Math.min(...this.rates[bIndex].map((e: any) => e.price));
          return aMinRate - bMinRate;
        });
      } else if (dropdownValue === "Descending Price") {
        indices.sort((aIndex, bIndex) => {
          const aMaxRate =
            this.rates[aIndex].length === 0
              ? 0
              : Math.max(...this.rates[aIndex].map((e: any) => e.price));
          const bMaxRate =
            this.rates[bIndex].length === 0
              ? 0
              : Math.max(...this.rates[bIndex].map((e: any) => e.price));
          return bMaxRate - aMaxRate;
        });
      } else if (dropdownValue === "Ascending Ratings") {
        indices.sort(
          (aIndex, bIndex) =>
            this.users[aIndex].rating - this.users[bIndex].rating
        );
      } else if (dropdownValue === "Descending Ratings") {
        indices.sort(
          (aIndex, bIndex) =>
            this.users[bIndex].rating - this.users[aIndex].rating
        );
      }

      this.users = indices.map((i) => this.users[i]);
      this.rates = indices.map((i) => this.rates[i]);
    });
  }

  onDropdownChange(newValue: string): void {
    console.log(newValue);
    this.dropdownValue.next(newValue);
    this.sortMasters();
  }
  getClassesForRate(rateId: string): Observable<any> {
    return this.afs
      .collection("classes", (ref) => ref.where("rateId", "==", rateId))
      .get()
      .pipe(
        map((querySnapshot) => querySnapshot.docs.map((doc) => doc.data()))
      );
  }

  filterData(): void {
    console.log(this.search);

    this.updateData().add(async () => {
      let finalFilteredMasterList: Master[] = [];
      let finalFilteredRates: any[][] = [];

      for (let i = 0; i < this.users.length; i++) {
        let master = this.users[i];

        // Filter by selectedLanguage
        // let languageFilter =
        //   !this.selectedLanguages.length ||
        //   this.selectedLanguages.includes("None") ||
        //   (master.languages &&
        //     this.selectedLanguages.some((lang) =>
        //       master.languages.includes(lang)
        //     ));

        let masterRates = this.rates.length ? this.rates[i] : [];
        let filteredRates = masterRates.filter(
          (rate) =>
            !this.selectedDurations.length ||
            this.selectedDurations.includes(rate.duration)
        );
        let durationFilter = filteredRates.length > 0;

        // Check if rates for this master have classes that are filtered by platform
        let platformFilter = true;
        if (this.selectedPlatforms.length) {
          platformFilter = false; // initialize to false and only set to true if a match is found
          for (let rate of masterRates) {
            let classesForRate = await lastValueFrom(
              this.getClassesForRate(rate.uid)
            ); // Assuming rate has an id field.
            platformFilter = classesForRate.some((cls) =>
              cls.gamingPlatform.some((platform) =>
                this.selectedPlatforms.includes(platform)
              )
            );
            if (platformFilter) break; // If one rate matches, we don't need to continue checking.
          }
        }

        // Combine the results of each filter languageFilter && 
        if (durationFilter && platformFilter) {
          finalFilteredMasterList.push(master);
          finalFilteredRates.push(filteredRates);
        }
      }

      this.users = finalFilteredMasterList;
      this.rates = finalFilteredRates;

      console.log(`Filtered Masters Length:${this.users.length}`);
    });
  }

  gameClicked(g) {
    this.search = g.name;
    // load all the masters that teach this game
    this.users = this.mastersData.filter((master) =>
      master.gameIds.includes(g.uid)
    );
    // this.updateData(this.users);
    this.selectedIndex = 0;
  }
}
