import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { FirestoreService } from "app/shared/services/firestore.service";
import * as firebase from "firebase/compat/app";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-phone-auth",
  templateUrl: "./phone-auth.component.html",
  styleUrls: ["./phone-auth.component.scss"],
})
export class PhoneAuthComponent implements OnInit {
  verificationId: string;
  defaultCountry: string = "";
  isLoading: boolean = false;
  verificationForm: FormGroup;
  private recaptchaVerifier: firebase.default.auth.RecaptchaVerifier | null =
    null;
  sentOnce = false;
  nickname = '';
  user: firebase.default.User;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
  private formBuilder: FormBuilder,
    private _fuseConfigService: FuseConfigService,
    private firestore: FirestoreService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit() {
    this.http
      .get("https://ipinfo.io?token=c33841b0e98713")
      .subscribe((resp: any) => {
        if (resp) {
          this.defaultCountry = resp.country.toLowerCase();
          console.log(resp);
        } else {
          this.defaultCountry = 'it'
        }
      });
    this.verificationForm = this.formBuilder.group({
      phone: ["", Validators.required],
      code: [""],
    });
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
    });
  }

  get phone() {
    return this.verificationForm.get("phone");
  }

  get code() {
    return this.verificationForm.get("code");
  }
  async sendVerificationCode() {
    if (this.phone.value == '') {
      return;
    }
    this.isLoading = true;
    if (!this.sentOnce) {
      this.sentOnce = true;
    }
    try {
      const appVerifier = new firebase.default.auth.RecaptchaVerifier(
        "recaptcha-container"
      );
      this.verificationId = (
        await this.afAuth.signInWithPhoneNumber(
          this.phone.value.e164Number,
          appVerifier
        )
      ).verificationId;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  resendVerificationCode() {
    console.log("resendVerificationCode called");
    if (this.recaptchaVerifier) {
      console.log("Clearing existing recaptchaVerifier");
      this.recaptchaVerifier.clear();
      this.recaptchaVerifier = null; // Ensure recaptchaVerifier is nullified
    }
    this.sendVerificationCode();
  }
  async verifyPhoneNumber() {
    try {
      this.isLoading = true;
      const credential = firebase.default.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.code.value
      );
      // Link phone number to the current user
      let usr = await this.user.linkWithCredential(credential);
       
      console.log("Phone number is verified.");
      this.isLoading = false;
      await this.addData();
      this.router.navigate(["dashboard"]);
    } catch (e) {
      console.log(e);
      this.isLoading = false;
      this.toastr.error(this.translate.instant("This phone number already exists."));
    }
    
  }

  public async addData() {
    if(this.nickname == '') {
      return;
    }
    await this.firestore.update(`users/${this.user.uid}`, {
      step: 1,
      nickname: this.nickname
    });
    this.router.navigate(["dashboard"]);
  }
}
