import { Injectable, NgZone } from "@angular/core";
import { User } from "./user";
import * as auth from "firebase/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentSnapshot,
} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user1: firebase.User;
  user2: any;

  user: Observable<any | null>;
  userData: any; // Save logged in user data
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    this.user = this.afAuth.authState.pipe(
      map((user) => {
        if (user) {
          return {
            uid: user.uid,
            email: user.email,
            name: user.displayName,
          };
        } else {
          return null;
        }
      })
    );
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem("user")!);
      } else {
        localStorage.setItem("user", "null");
        JSON.parse(localStorage.getItem("user")!);
      }
    });
  }
  async updateCurrentUser(uid: string, data: any): Promise<void> {
    const user = await this.afAuth.currentUser;
    if (user) {
      const userRef = this.afs.collection("users").doc(uid);
      return userRef.update(data);
    }
    return Promise.resolve();
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.user2 = user;
          resolve(user);
        } else {
          reject("No user logged in");
        }
      });
    });
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        return this.afs.doc(`users/${result.user.uid}`).get().toPromise();
      })
      .then((userDocument) => {
        const userData = userDocument.data() as any;
        if (!userData.enabled) {
          this.afAuth.signOut().then(() => {
            alert("Your account is disabled. Please contact admin.");
            this.router.navigate(["login"]);
            return;
          });
          return;
        }
        switch (userData.step) {
          // case 1:
          //   this.router.navigate(["signup-detail"]);
          //   break;
          // case 2:
          //   this.router.navigate(["choose-game"]);
          //   break;
          // case 3:
          //   this.router.navigate(["gaming-curriculm"]);
          //   break;
          default:
            this.router.navigate(["dashboard"]);
        }
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string, phone: string, nickname: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        return this.SetUserData(result.user, nickname, '', );
      })
      .catch((error) => {
        window.alert(error.message);
        return Promise.reject(error);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {});
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert("Password reset email sent, check your inbox.");
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user")!);
    return user !== null && user.emailVerified !== false ? true : false;
  }
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
  //     // Get the user's Firestore document
  //     const userRef = this.afs.collection("users").doc(res.user.uid);
  //     userRef.get().subscribe((doc) => {
  //       const userDoc = doc as DocumentSnapshot<User>;
  //       if (userDoc.exists && userDoc.data().username) {
  //         // User exists and has a username, go to dashboard
  //         this.router.navigate(["dashboard"]);
  //       } else {
  //         // User is new, go to signup detail page
  //         this.router.navigate(["signup-detail"]);
  //       }
  //     });
  //   });
  // }
  // Method to authenticate user and get user data
  authenticateUser(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        // Get the user's Firestore document
        const userRef = this.afs.collection("users").doc(result.user.uid);

        // Check if user already exists
        userRef.get().subscribe((doc) => {
          const userDoc = doc as DocumentSnapshot<User>;

          // If user data exists
          if (userDoc.exists) {
            // Update user data

            // Navigate user based on the step
            this.navigateUser(userDoc.data());
          } else {
            // User does not exist, navigate to sign up detail page
            this.SetUserData(result.user, '', "", true);

            this.router.navigate(["phone-auth"]);
          }
        });
      })
      .catch((error) => {
        console.error(error);
        window.alert(error.message);
      });
  }

  // Method to navigate user based on the step
  navigateUser(userData: any) {
    // if (userData.step === 0) {
    //   this.router.navigate(["phone-auth"]);
    // } //  else if (userData.step === 1) {
    //   // User is on step 1, navigate to sign up detail page
    //   this.router.navigate(["signup-detail"]);
    // } else if (userData.step == 2) {
    //   // User is on step 2, navigate to choose game page
    //   this.router.navigate(["choose-game"]);
    // } else if (userData.step == 3) {
    //   this.router.navigate(["gaming-curriculm"]);
    //   // Default navigation
    //   // Add navigation logic for other steps if necessary
    // } 
    // else {
      // User is on step 4, navigate to dashboard
      this.router.navigate(["dashboard"]);
    // }
}

  // Methods for Google sign up and login
  GoogleSignUp() {
    return this.authenticateUser(new auth.GoogleAuthProvider());
  }

  GoogleLogin() {
    return this.authenticateUser(new auth.GoogleAuthProvider());
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any, nickname: string, phone?: string, isStep?: boolean) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    console.log(user);
    const now = firebase.firestore.Timestamp.now();
    const userData: any = {
      // username: user.username,
      phone: phone ?? "",
      uid: user.uid,
      email: user.email,
      name: user.displayName,
      profilePic: user.photoURL,
      emailVerified: user.emailVerified,
      creationDateTS: firebase.firestore.Timestamp.now().toDate(),
      settings: {
        selectedLanguage: "it",
      },
      step: isStep ? 0 : 1,
      isMaster: false,
      gamesTeached: [],
      languages: ["Italian", "English"],
      supportRequested: 0,
      gameIds: [],
      gameEnabled: [],
      productLinks: [],
      enabled: true,
      username: nickname,
      about: "",
      discordLink: "",
      country: "Italy",
      fiscalCode: "",
      vat: "",
      isVerified: 0,
      documentType: "",
      documentURL: "",
      masterRequest: 0,
      studentDiscordName: '',
      hideMaster: false,
      nickname,

    };
    return userRef.set(userData, {
      merge: true,
    });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem("user");
      this.router.navigate(["login"]);
    });
  }
}
