import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
 MatDialogRef,
MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { User } from "firebase/auth";

@Component({
  selector: "request-game-dialog",
  templateUrl: "./request-game-dialog.component.html",
  styleUrls: ["./request-game-dialog.component.scss"],
})
export class RequestGameDialogComponent implements OnInit {
  message: string;
  isLoading: boolean = false;

  isFromClass = false;
  isRefund = false;

  isMobile = false;
  constructor(
    private afAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<RequestGameDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService

  ) {

    this.isFromClass = data.isFromClass;
  }

  ngOnInit(): void {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
    .subscribe((o) => {
      if (o.matches) {
        this.isMobile = o.matches;
        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      }
    });
  }

  saveMessageToFirestore(message: string) {
    const uid = this.afs.createId();
    const category = "Missing Game Request";
    this.afAuth.authState.subscribe((user: User | null) => {
      if (user) {
        const senderEmailId = user.email;
        const senderID = user.uid;

        this.afs
          .collection("support")
          .doc(uid)
          .set({
            message: message,
            uid: uid,
            senderEmail: senderEmailId,
            creationDateTS: new Date(),
            category: category,
            isMaster: true,
            isNew: true,
            isReplied: false,
            senderID: senderID,
            isRefund: this.isRefund,
            isFromClass: this.isFromClass
          })
          .then(() => {
            this.isLoading = true;
            this.matSnackBar.open("Your request has been submitted!", "Close", {
              duration: 3000,
            });
            this.dialogRef.close();
          })
          .catch((error) => {
            console.error(error);
            this.matSnackBar.open(
              "Error submitting request. Please try again.",
              "Close",
              {
                duration: 3000,
              }
            );
          });
      }
    });
  }
}
