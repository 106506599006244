<div class="main">
  <div class="private-finished-class-detail">
    <div class="container-fluid">
      <div class="class-name-etc">
        <span class="class-name">Alex Housten</span>
        <span class="tips">Valorant Rank up Tips</span>
        <span class="remain">Remaining Lessons : 4</span>
      </div>
      <div class="class-cover">
        <img src="assets/images/manager/cover.png" alt="" />
      </div>
      <div class="tabs-area">
        <div class="nav-action-btns">
          <button class="small-btn">
            <i class="fa fa-exclamation-triangle"></i>
          </button>
          <button class="btn btn-default" routerLink="/my-profile">
            Go Back
          </button>
        </div>
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Register-valorant">
            <ng-template mat-tab-label>
              Register<br /><span>(Valorant)</span>
            </ng-template>
            <div class="lesson-list">
              <a class="box">
                <div class="row">
                  <div class="col-md">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Valorant" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Valorant Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-5">
                    <h5>Class Activites:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                  <div class="col-md">
                    <div class="tags">
                      <h5>Class Activites:</h5>
                      <div class="tag-box">Alexa Bliss</div>
                      <div class="tag-box">Liv Morgan</div>
                      <div class="tag-box">Alex Housten</div>
                      <div class="tag-box">Luke Harper</div>
                      <div class="tag-box">Jenny James</div>
                      <div class="tag-box">Nick Fury</div>
                    </div>
                  </div>
                </div>
              </a>
              <a class="box">
                <div class="row">
                  <div class="col-md">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Valorant" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Valorant Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-5">
                    <h5>Class Activites:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                  <div class="col-md">
                    <div class="tags">
                      <h5>Class Activites:</h5>
                      <div class="tag-box">Alexa Bliss</div>
                      <div class="tag-box">Liv Morgan</div>
                      <div class="tag-box">Alex Housten</div>
                      <div class="tag-box">Luke Harper</div>
                      <div class="tag-box">Jenny James</div>
                      <div class="tag-box">Nick Fury</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </mat-tab>
          <mat-tab label="Register-fortnite">
            <ng-template mat-tab-label>
              Register<br /><span>(fortnite)</span>
            </ng-template>
            <div class="lesson-list">
              <a class="box">
                <div class="row">
                  <div class="col-md">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Fornite" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Fornite Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-5">
                    <h5>Class Activites:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                  <div class="col-md">
                    <div class="tags">
                      <h5>Class Activites:</h5>
                      <div class="tag-box">Alexa Bliss</div>
                      <div class="tag-box">Liv Morgan</div>
                      <div class="tag-box">Alex Housten</div>
                      <div class="tag-box">Luke Harper</div>
                      <div class="tag-box">Jenny James</div>
                      <div class="tag-box">Nick Fury</div>
                    </div>
                  </div>
                </div>
              </a>
              <a class="box">
                <div class="row">
                  <div class="col-md">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Fornite" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Fornite Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-5">
                    <h5>Class Activites:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                  <div class="col-md">
                    <div class="tags">
                      <h5>Class Activites:</h5>
                      <div class="tag-box">Alexa Bliss</div>
                      <div class="tag-box">Liv Morgan</div>
                      <div class="tag-box">Alex Housten</div>
                      <div class="tag-box">Luke Harper</div>
                      <div class="tag-box">Jenny James</div>
                      <div class="tag-box">Nick Fury</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
