import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FilterDialogComponent } from "../filter-dialog/filter-dialog.component";
declare var $: any;

@Component({
  selector: "filter-result-master",
  templateUrl: "./filter-result-master.component.html",
  styleUrls: ["./filter-result-master.component.scss"],
})
export class FilterResultMasterComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {}

  filter() {
    this.matDialog.open(FilterDialogComponent);
  }
}
