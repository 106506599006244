import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { ManageRatesComponent } from "./manage-rates.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { ManageRatesPrivateComponent } from "./manage-rates-private/manage-rates-private.component";
import { RatesEditComponent } from "./rates-edit/rates-edit.component";

const routes = [
  {
    path: "manage-rates",
    component: ManageRatesComponent,
  },
  {
    path: "manage-rates-private",
    component: ManageRatesPrivateComponent,
  },
  {
    path: "rates-edit",
    component: RatesEditComponent,
  },
];

@NgModule({
  declarations: [
    ManageRatesComponent,
    ManageRatesPrivateComponent,
    RatesEditComponent,
  ],
  imports: [RouterModule.forChild(routes), FuseSharedModule, MatTabsModule],
})
export class ManageRatesModule {}
