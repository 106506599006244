<app-navbar
  *ngIf="isMobile"
  [title]="'My Classes' | translate"
  [hideBackIcon]="true"
></app-navbar>

<div class="main">
  <div
    class="manager"
    [ngStyle]="{ padding: isMobile ? '0' : '30px 30px 100px' }"
  >
    <div class="container-fluid">
      <div [ngClass]="{ 'mt-4': isMobile }">
       
            <div class="mt-4">
              <ng-container *ngIf="(classes$ | async)?.length; else noClasses">
                <div class="grid_container">
                  <div
                    *ngFor="let c of classes$ | async"
                    class="h-100"
                    [ngStyle]="{ padding: isMobile ? '0' : '10px' }"
                  >
                    <a
                      routerLink="/group-class-view/{{ c.uid }}"
                      class="box d-flex flex-column"
                      style="cursor: pointer"
                    >
                      <img
                        style="border-radius: 13px"
                        src="{{ c.image }}"
                        alt=""
                      />
                      <div class="text-area d-grid">
                        <h4 style="color: #19b133" class="overflow-ellipis">
                          {{ c.title }}
                        </h4>
                        <span class="overflow-ellipis">{{
                          scheduleDayClass(c)
                        }}</span>
                        <span class="time overflow-ellipis">
                          {{ c.startTime }} - {{ c.endTime }}
                        </span>

                        <span class="date overflow-ellipis"
                          >({{ c.startDate.toDate() | date : "MMM, dd yyyy": "" : 'it-IT' }})
                          - ({{
                            c.endDate.toDate() | date : "MMM, dd yyyy": "" : 'it-IT'
                          }})</span
                        >
                        <span class="student-number overflow-ellipis"
                          >{{ "Student Number" | translate }}
                          {{ c.classStudentsList.length }} /
                          {{ c.maxNumberOfStudents }}</span
                        >
                        <span class="d-block overflow-ellipis">
                          {{ c.language }}
                        </span>
                        <span class="d-block overflow-ellipis">
                          {{ c.gamingPlatform }}
                        </span>
                        <span class="d-block overflow-ellipis peripherals">
                          {{ c.peripherals }}
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </ng-container>

              <ng-template #noClasses>
                <div class="no-data" style="text-align: center;">
                  <h4>{{ "No Classes yet" | translate }}</h4>
                </div>
              </ng-template>
            </div>
          
          <!-- <mat-tab label="Private">
            <div class="mt-4">
              <div class="row">
                <div *ngFor="let p of privateClasses$ | async" class="col-md-3">
                  <a routerLink="/private-class-view" class="box">
                    <img src="{{ p.image }}" alt="" />
                    <div class="text-area">
                      <span class="name">{{ p.studentName }}</span>
                      <h4>{{ p.gameTitle }}s</h4>
                      <span class="remaining"
                        >Remaining Lessons : {{ p.remainingLessons }}</span
                      >
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </mat-tab> -->
    
      </div>
    </div>
  </div>
</div>
