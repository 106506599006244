import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { RequestGameDialogComponent } from "../authorizations/choose-game/request-game-dialog/request-game-dialog.component";
import { cardDetailDialogComponent } from "./card-detail-dialog/card-detail-dialog.component";
import { selectPaymentDialogComponent } from "./select-payment-dialog/select-payment-dialog.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { FirestoreService } from "app/shared/services/firestore.service";
import { StripeWService } from "app/shared/services/stripe-w.service";
import { AuthService } from "app/shared/services/auth.service";
declare var $: any;
import { ToastrService } from "ngx-toastr";
import { validateIBAN } from "ngx-iban-validator";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { deleteConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";

@Component({
  selector: "settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  public isVisible = false;
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  userId: string = "";
  user: any = {};

  ibanForm: FormGroup;
  isMobile: boolean = false;
  studentDiscordName: string = "";
  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  constructor(
    private matDialog: MatDialog,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private stripeService: StripeWService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {}

  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.ibanForm = this.formBuilder.group({
      iban: ["", [Validators.required, validateIBAN]],
    });
    this.authService.user.subscribe((usr) => {
      this.userId = usr.uid;
      this.firestoreService.doc$(`users/${this.userId}`).subscribe((user) => {
        this.user = user;
        this.studentDiscordName = this.user.studentDiscordName;
        this.user.settings = this.user.settings || {};
        this.user.settings.selectedLanguage =
          this.user.settings.selectedLanguage || "en";
        this.user.vat = this.user.vat || "";
        this.ibanForm.patchValue({
          iban: this.user.iban || "",
        });
      });
    });
  }
  removeCard(card: string, i: number) {
    const dialogRef = this.matDialog.open(ConfirmationModalComponent, {
      data: {
        title: this.translate.instant("Remove Card"),
        text: this.translate.instant(
          "Are you sure you want to delete this card?"
        ),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Delete card from Stripe here
        this.user.card.splice(i, 1);
        this.user.stripePaymentMethodId.splice(i, 1);
        this.user.fingerprints.splice(i, 1);
        this.firestoreService
          .update(`users/${this.userId}`, {
            card: this.user.card,
            stripePaymentMethodId: this.user.stripePaymentMethodId,
            fingerprints: this.user.fingerprints,
          })
          .then(() => {
            this.stripeService.detachCard(this.user.stripePaymentMethodId[i]);
          });
      }
    });
  }
  selectPayment() {
    this.matDialog.open(cardDetailDialogComponent);
  }
  supportDialog() {
    this.matDialog.open(RequestGameDialogComponent, {
      data: {
        isFromClass: false,
      },
    });
  }
  async saveDiscordName() {
    try {
      await this.firestoreService.update(`users/${this.userId}`, {
        studentDiscordName: this.studentDiscordName,
        discordUsername: this.studentDiscordName
      });
      this.toastr.success(
        this.translate.instant("Discord Name updated successfully")
      );
      this.studentDiscordName = '';
    } catch (e) {
      this.toastr.error(this.translate.instant("Something went wrong"));
    }
  }
  async setSetting() {
    try {
      await this.firestoreService.update(`users/${this.userId}`, {
        settings: this.user.settings,
      });
      this.translate.use(this.user.settings.language);
      this.toastr.success(
        this.translate.instant("Settings updated successfully")
      );
    } catch (e) {
      this.toastr.error(this.translate.instant("Something went wrong"));
    }
  }

  onDeleteAccount() {
    const dialogRef = this.matDialog.open(deleteConfirmDialogComponent, {
      width: "350px",
      data: {
        message: this.translate.instant(
          "Are you sure you want to delete your account?"
        ),
        user: this.user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Delete user data from Firebase here
        this.firestoreService.update(`users/${this.userId}`, {
          enabled: false,
        });
      }
    });
  }
}
