import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/shared/services/auth.service";
import { Observable } from "rxjs";
declare var $: any;

@Component({
  selector: "manager",
  templateUrl: "./manager.component.html",
  styleUrls: ["./manager.component.scss"],
})
export class ManagerComponent implements OnInit {
  groupBtn: Boolean = true;
  classes$: Observable<any[]>;
  privateClasses$: Observable<any[]>;
  days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((d) => this.translate.instant(d));
  isMobile = false;
  constructor(
    private firestore: AngularFirestore,
    public authService: AuthService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private afAuth: AngularFireAuth,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .subscribe((o) => {
      this.isMobile = o.matches;

      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: this.isMobile,
          },
          toolbar: {
            hidden: this.isMobile,
          },
          footer: {
            hidden: this.isMobile,
          },
          sidepanel: {
            hidden: this.isMobile,
          },
        },
      };
    });
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        const loggedInUserId = user.uid;
        // this.privateClasses$ = this.firestore
        //   .collection("classes", (ref) =>
        //     ref
        //       .where("classStudentsList", "==", loggedInUserId)
        //       .where("isGroupClass", "==", false)
        //   )
        //   .valueChanges();
  
        this.classes$ = this.firestore
          .collection("classes", (ref) =>
            ref
              .where("classStudentsList", "array-contains" , loggedInUserId)
              .where("isGroupClass", "==", true)
              .where("endDate", ">=", new Date())
          )
          .valueChanges();
      }
    
    });
  }

  TabChanged(tab = { index: 0 }) {
    if (tab.index == 0) {
      this.groupBtn = true;
    } else {
      this.groupBtn = false;
    }
  }

  scheduleDayClass(cls) {
    const scheduleDays = cls.scheduleDays;

    if (scheduleDays) {
      const scheduled = scheduleDays.reduce((acc, cur, index) => {
        if (cur) {
          acc.push(this.days[index]);
        }
        return acc;
      }, []);

      const scheduledDaysString = scheduled.join(", ");
      return scheduledDaysString;
    }
    return "";
  }
}
