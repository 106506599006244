import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { InfoDialogComponent, ProfileComponent } from "./profile.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SharedAppModule } from "app/shared/shared-app.module";
import { TranslateModule } from "@ngx-translate/core";
import { chooseRateDialogComponent } from "./choose-rate-dialog/choose-rate-dialog.component";
import { classScheduledDialogComponent } from "./class-scheduled-dialog/class-scheduled-dialog.component";
import { confirmPurchaseDialogComponent } from "./confirm-purchase-dialog/confirm-purchase-dialog.component";
import { connectDiscordDialogComponent } from "./connect-discord-dialog/connect-discord-dialog.component";
import { joinServerDialogComponent } from "./join-server-dialog/join-server-dialog.component";
import { subDealDialogComponent } from "./sub-deal-dialog/sub-deal-dialog.component";
import { BarRatingModule } from "ngx-bar-rating";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

const routes = [
  {
    path: "profile",
    component: ProfileComponent,
  },
];

@NgModule({
  declarations: [ProfileComponent, chooseRateDialogComponent, classScheduledDialogComponent, confirmPurchaseDialogComponent, connectDiscordDialogComponent, joinServerDialogComponent, subDealDialogComponent, InfoDialogComponent],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    CarouselModule,
    SharedAppModule,
    TranslateModule,
    BarRatingModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule
  ],
})
export class ProfileModule {}
