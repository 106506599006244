import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CalendarView } from "angular-calendar";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
declare var $: any;
interface User {
  profilePic: string;
  name: string;
}

@Component({
  selector: "activities",
  templateUrl: "./activities.component.html",
  styleUrls: ["./activities.component.scss"],
})
export class ActivitiesComponent implements OnInit {
  @ViewChild("dateSelect", { static: false })
  dateSelectRef!: ElementRef<HTMLInputElement>;
  daysOfWeek: string[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  view: CalendarView = CalendarView.Month;
  calendar: any[] = [];

  viewDate: Date = new Date();

  selectedDate: Date = new Date();

  activeDayIsOpen: boolean = false; // declare the property here

  dropdownOptions = [
    { value: "this_month", label: "This Month" },
    { value: "next_month", label: "Next Month" },
    { value: "month_after_next", label: "Month After Next" },
  ];
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  upcomingClasses: Observable<any[]>;
  selectedMonth: Date = new Date();
  daysInMonth: number[] = [];
  qaz: Date = new Date();
  nextMonth: Date = new Date();
  monthAfterNext: Date = new Date();
  today: Date = new Date();
  blankDays: any;
  thismonth: Date = new Date();
  selected: number;
  upcomingClassesData: any[];
  name$: any;
  classes$: any;
  profilePicture$: any;
  privateclasses$: any;

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
    this.selectedMonth = new Date();

    const today = new Date();
    this.thismonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.selectedMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);
    this.monthAfterNext = new Date(today.getFullYear(), today.getMonth() + 2);

    this.dropdownOptions[0].label = this.getMonthName(today);
    this.dropdownOptions[1].label = this.getMonthName(this.nextMonth);
    this.dropdownOptions[2].label = this.getMonthName(this.monthAfterNext);
  }
  getMonthName(date: Date): string {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[date.getMonth()] + " " + date.getFullYear();
    console.log(monthNames[date.getMonth()] + " " + date.getFullYear());
  }
  onDateClick(day: number) {
    const year = this.selectedMonth.getFullYear();
    const month = this.selectedMonth.getMonth();
    this.selectedDate = new Date(year, month, day);
    this.selected = day;
    console.log(`Selected date: ${this.selectedDate.toISOString()}`);
  }
  getTimePosition(time: string): number {
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    return (totalMinutes / 60) * 50; // Assumes each hour is 50 pixels tall
  }

  ngOnInit() {
    this.generateCalendar();
    this.afAuth.authState.subscribe((user) => {
      this.users = user;
      const loggedInUserId = user?.uid;
      if (user) {
        const usersRef = this.afs.collection<User>("users");
        console.log(loggedInUserId);

        this.classes$ = this.afs
          .collection("classes", (ref) =>
            ref
              .where("isGroupClass", "==", true)
              .where("classStudentsList", "array-contains", loggedInUserId)
          )
          .snapshotChanges()
          .pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as object;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );
        this.privateclasses$ = this.afs
          .collection("classes", (ref) =>
            ref
              .where("isGroupClass", "==", false)
              .where("classStudentsList", "array-contains", loggedInUserId)
          )
          .snapshotChanges()
          .pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as object;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );
        this.classes$.subscribe((classes) => console.log(classes));

        this.name$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(map((doc) => doc.data().name));
        this.profilePicture$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(map((doc) => doc.data().profilePic));
      }
    });
  }

  generateCalendar() {
    const dayOfWeek = this.selectedMonth.getDay();
    this.blankDays = new Array(dayOfWeek === 0 ? 6 : dayOfWeek).fill("");
    const year = this.selectedMonth.getFullYear();
    const month = this.selectedMonth.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (let i = 1; i <= daysInMonth; i++) {
      this.daysInMonth.push(i);
    }
  }

  onMonthChange(event) {
    var t;

    if (event.target.value == "next_month") {
      t = this.nextMonth;
    } else if (event.target.value == "month_after_next") {
      t = this.monthAfterNext;
    } else {
      t = this.thismonth;
    }
    const dayOfWeek = t.getDay();
    this.blankDays = new Array(dayOfWeek === 0 ? 6 : dayOfWeek).fill("");

    console.log(this.blankDays);

    this.selectedMonth = new Date(t);
    this.daysInMonth = [];
    this.generateCalendar();
  }
}
