<div class="main pt-2">
  <div class="products">
    <div class="container-fluid">
      <h2>Earnings</h2>
      <div class="nav-row">
        <h3>Money Earned :</h3>
        <div class="links">
          <a routerLink="/earnings">General</a>
          <a routerLink="/referrals">Referrals</a>
          <a class="active">Products</a>
        </div>
      </div>
      <h3 class="withdraw">Withdrawable Money: <span>1200€</span></h3>
      <div class="earning-graph-box">
        <div class="box-head">
          <span class="heading">
            <img src="assets/images/earnings/doller.png" alt="" />
            Earnings
          </span>
          <select class="form-control" id="">
            <option>Last 30 days</option>
            <option>Last 15 days</option>
            <option>Last 7 days</option>
          </select>
        </div>
        <div class="box-body">
          <div class="graph-area">
            <img src="assets/images/earnings/graph.png" alt="" />
          </div>
          <div class="total-earn-box">
            <img src="assets/images/earnings/img.png" alt="" />
            <span class="total">$2769</span>
          </div>
        </div>
      </div>

      <div class="sold">
        <div class="head">
          <h3>Mostly Sold :</h3>
          <h3>Sales | <span>76</span></h3>
        </div>
        <div class="main-bar">
          <div class="sold-bar">
            <div class="fill">Razer Keyboard</div>
          </div>
          <div class="text"><span>27%</span> | Earned : $ 570</div>
        </div>
        <div class="main-bar">
          <div class="sold-bar">
            <div class="fill">Hyper X Headphones</div>
          </div>
          <div class="text"><span>44%</span> | Earned : $ 1280</div>
        </div>
        <div class="main-bar">
          <div class="sold-bar">
            <div class="fill">T-Dagger Mouse</div>
          </div>
          <div class="text"><span>22%</span> | Earned : $ 570</div>
        </div>
        <div class="main-bar">
          <div class="sold-bar">
            <div class="fill">Others</div>
          </div>
          <div class="text"><span>7%</span> | Earned : $ 570</div>
        </div>
      </div>

      <div class="recent-withdraws">
        <h3>All Products :</h3>
        <div class="row">
          <div class="col-md-4">
            <div class="box">
              <img src="assets/images/earnings/recent.png" alt="" />
              <div>
                <span class="bank">Bank of Posturikal</span>
                <span class="date">28 May, 2022</span>
              </div>
              <span class="cost">$1569.0</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box">
              <img src="assets/images/earnings/recent.png" alt="" />
              <div>
                <span class="bank">Bank of Posturikal</span>
                <span class="date">28 May, 2022</span>
              </div>
              <span class="cost">$1569.0</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box">
              <img src="assets/images/earnings/recent.png" alt="" />
              <div>
                <span class="bank">Bank of Posturikal</span>
                <span class="date">28 May, 2022</span>
              </div>
              <span class="cost">$1569.0</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box">
              <img src="assets/images/earnings/recent.png" alt="" />
              <div>
                <span class="bank">Bank of Posturikal</span>
                <span class="date">28 May, 2022</span>
              </div>
              <span class="cost">$1569.0</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box">
              <img src="assets/images/earnings/recent.png" alt="" />
              <div>
                <span class="bank">Bank of Posturikal</span>
                <span class="date">28 May, 2022</span>
              </div>
              <span class="cost">$1569.0</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box">
              <img src="assets/images/earnings/recent.png" alt="" />
              <div>
                <span class="bank">Bank of Posturikal</span>
                <span class="date">28 May, 2022</span>
              </div>
              <span class="cost">$1569.0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
