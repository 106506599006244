import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
   MatDialogRef,
 MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { classScheduledDialogComponent } from "../class-scheduled-dialog/class-scheduled-dialog.component";
import { StripeWService } from "app/shared/services/stripe-w.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Subject, takeUntil } from "rxjs";
import { FuseConfigService } from "@fuse/services/config.service";
import { environment } from "environments/environment";

@Component({
  selector: "confirm-purchase-dialog",
  templateUrl: "./confirm-purchase-dialog.component.html",
  styleUrls: ["./confirm-purchase-dialog.component.scss"],
})
export class confirmPurchaseDialogComponent {

  isMobile: boolean;
  private _unsubscribeAll: Subject<any> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<confirmPurchaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripeService: StripeWService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private router: Router

  ) {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((o) => {
      if (o.matches) {
        this.isMobile = o.matches;
        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      }
    });
  }

  classScheduled() {
    if (this.data.selectedRate.price == 0) {
      this.router.navigateByUrl(`/success_payment?purchaseAmount=${0}&classId=` + this.data.cls.uid);
      return;
    }
    this.stripeService.checkout(this.getDiscountedPrice(this.data.selectedRate, this.data.cls), this.data.cls).then((e) => {
      window.location.href = e.url;
    });
  }
  getDiscountedPrice(rate, cls) {
    if (rate.discounts && rate.discounts.length > 0) {
      if (rate.discounts[0].count <= 0) {
        return rate.price;
      }
    }
    let discountPercent = 0;
  
    // Check for discounts in rate
    if (rate.discounts && rate.discounts.length > 0) {
      discountPercent = Math.max(discountPercent, rate.discounts[0].percent);
    }
  
    // Check for discounts in cls
    if (cls.discounts && cls.discounts.length > 0) {
      discountPercent = Math.max(discountPercent, cls.discounts[0].percent);
    }
  
    // Calculate discounted price
    return rate.price * ((100 - discountPercent) / 100);
  }
  
}
