<app-navbar
  *ngIf="isMobile"
  [title]="'Messages' | translate"
  [hideBackIcon]="true"
></app-navbar>
<div class="main pt-2">
  <div class="messages">
    <div class="container-fluid">
      <h2>{{ "Messages" | translate }}</h2>
      <div class="chat-box">
        <div class="row">
          <div class="col-md-5">
            <div class="list-area">
              <div class="login-user-info" *ngIf="user$ | async as usr">
                <img
                  *ngIf="
                    usr.profilePic as profilePicture;
                    else noProfilePicture
                  "
                  src="{{ profilePicture }}"
                  alt="Profile Picture"
                  style="border-radius: 50%"
                />
                <ng-template #noProfilePicture>
                  <img
                    src="assets/images/dummy_user.png"
                    class="rounded-circle"
                    alt="Profile Picture"
                  />
                </ng-template>
                <span>
                  <h5 class="login-user-name">{{ usr.nickname ?? usr.username ?? 'User# ' + usr.uid }}</h5>
                </span>
              </div>

              <form>
                <div class="search-message">
                  <i class="fa fa-search"></i>
                  <input
                    type="text"
                    class="form-control"
                    id=""
                    placeholder="{{ 'Search Messages' | translate }}"
                    autocomplete="off"
                    (keyup.enter)="searchMessages($event.target.value)"
                  />
                </div>
              </form>

              <mat-tab-group
                animationDuration="0ms"
                (selectedTabChange)="TabChanged($event)"
              >
                <mat-tab [label]="'General' | translate">
                  <div class="chat-list mt-4">
                    <div class="loader" *ngIf="roomsLoading">
                      <app-loader> </app-loader>
                    </div>
                    <div *ngIf="!roomsLoading && filteredRooms.length == 0">
                      <div class="no-data">
                        <!-- <img
                          src="assets/images/no-data.png"
                          alt="No Data"
                          class="img-fluid"
                        /> -->
                        <h4>{{ "No Chat yet" | translate }}</h4>
                      </div>
                    </div>
                    <div
                      *ngFor="let room of filteredRooms"
                      class="box"
                      [ngClass]="{ active: selectedRoom.id == room.id }"
                      (click)="clickonRoom(room)"
                    >
                      <img
                        src="{{
                          room.user?.profilePic ??
                            '/assets/images/dummy_user.png'
                        }}"
                        alt="Profile"
                        class="img-fluid img-round"
                      />
                      <div class="b-text">
                        <span class="user-name">{{ room.user?.username }}</span>
                        <span class="last-msg text-green"
                          >{{
                            room.metadata?.lastMessage | summary : 50
                          }}.</span
                        >
                      </div>
                      <span
                        class="last-msg-time d-flex flex-column align-items-end"
                        *ngIf="room.updatedAt"
                      >
                      
                        <span
                          *ngIf="
                            room.metadata.lastMessageRead == false &&
                            room.metadata.lastMessageSentBy != user.uid
                          "
                        >
                          <i class="fa fa-circle text-success"></i> </span
                        >{{ room.updatedAt.toDate() | relativeTime }}</span
                      >
                    </div>
                  </div>
                </mat-tab>
                <!-- <mat-tab label="Reservations"> </mat-tab> -->
                <!-- <mat-tab label="Requests">
                  <div class="chat-list"></div>
                </mat-tab> -->
              </mat-tab-group>
            </div>
          </div>
          <div class="col-md-7" *ngIf="selectedRoom">
            <div class="chat-area">
              <div class="chat-head" *ngIf="selectedRoom != null">
                <div class="d-flex flex-row justify-content-between w-100">
                  <div class="d-flex flex-row">
                    <img
                      src="{{
                        selectedRoom.user?.profilePic ??
                          '/assets/images/dummy_user.png'
                      }}"
                      alt="Profile"
                      class="img-fluid img-round"
                    />
                    <div class="flex-column">
                      <!-- <p class="m-0">{{ selectedRoom.user?.name }}</p> -->
                      <p class="text-green">
                        {{ selectedRoom.user?.nickname ?? selectedRoom.user?.username ?? 'User# ' + selectedRoom.user?.uid}}
                      </p>
                    </div>
                  </div>
                  <div>
                    <!-- Class Name -->
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="More chat options"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="blockUser()">
                        <mat-icon>block</mat-icon>
                        <span>{{ "Block User" | translate }}</span>
                      </button>
                      <button mat-menu-item (click)="deleteChat()">
                        <mat-icon>delete</mat-icon>
                        <span>{{ "Delete Chat" | translate }}</span>
                      </button>
                      <button mat-menu-item (click)="reportUser()">
                        <mat-icon>flag</mat-icon>
                        <span>{{ "Report User" | translate }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="message-list mt-4 mb-4" #scrollMe>
                <div class="loader" *ngIf="messagesLoading">
                  <app-loader> </app-loader>
                </div>
                <div
                  *ngIf="!messagesLoading && (messages | async)?.length == 0"
                >
                  <div class="no-data">
                    <!-- <img
                      src="assets/images/no-data.png"
                      alt="No Data"
                      class="img-fluid"
                    /> -->
                    <h4>{{ "No Data Found" | translate }}</h4>
                  </div>
                </div>
                <ng-container *ngFor="let message of messages | async">
                  <div
                    class="message-bubble"
                    [ngClass]="{
                      user: message.authorId !== selectedRoom.user.uid,
                      other: message.authorId === selectedRoom.user.uid
                    }"
                  >
                    <p>{{ message.text }}</p>
                  </div>
                </ng-container>
                <p *ngIf="isBlocked" style="text-align: center">
                  {{
                    "You have blocked this user. You cannot send messages to this user."
                      | translate
                  }}
                </p>
                <p *ngIf="isBlockedByUser" style="text-align: center">
                  {{
                    "You have been blocked. You cannot send messages to this user."
                      | translate
                  }}
                </p>
                <div style="text-align: center">
                  <button
                    mat-button
                    color="warn"
                    (click)="unblock()"
                    *ngIf="
                      isBlocked &&
                      !selectedRoom.metadata.blockedUsers?.includes(user.uid)
                    "
                  >
                    {{ "Unblock User" | translate }}
                  </button>
                </div>
              </div>
              <div class="typing-area" *ngIf="!isBlocked">
                <form>
                  <div class="typing-field">
                    <input
                      type="text"
                      class="form-control"
                      id=""
                      [(ngModel)]="message"
                      name="message"
                      placeholder="{{ 'Write your message...' | translate }}"
                      autocomplete="off"
                      (keyup.enter)="sendMessage()"
                      [disabled]="isBlocked || isBlockedByUser"
                      [max]="200"
                    />
                    <span class="btns">
                      <!-- <button class="emoji-btn">
                        <img src="assets/images/chat/emoji.png" alt="" />
                      </button> -->
                      <!-- <emoji-mart
                        [(set)]="selectedSet"
                        [title]="emojiTitle"
                        [emoji]="selectedEmoji"
                        [style]="{
                          position: 'absolute',
                          bottom: '20px',
                          right: '20px'
                        }"
                      ></emoji-mart> -->
                      <button
                        class="msg-send-btn"
                        type="button"
                        (click)="sendMessage()"
                        [disabled]="isBlocked || isBlockedByUser"
                      >
                        <img src="assets/images/chat/send.png" alt="" />
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
