import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { requestSentDialogComponent } from "../request-sent-dialog/request-sent-dialog.component";

@Component({
  selector: "book-lesson-dialog",
  templateUrl: "./book-lesson-dialog.component.html",
  styleUrls: ["./book-lesson-dialog.component.scss"],
})
export class bookLessonDialogComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<bookLessonDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<bookLessonDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  requestSent() {
    this.dialogRef.close();
    this.matDialog.open(requestSentDialogComponent);
  }
}
