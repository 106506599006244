<div class="choose-rate-popup">
  <div class="choose-rate-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <img
      class="confirm-img"
      src="assets/images/profile/dialog/confirm.png"
      alt=""
    />
    <h3>{{"Choose Rate" | translate}}</h3>
    <p>{{"Select the Number of lessons you want to get" | translate}}</p>
    <div class="quantity">
      <span class="text">{{"Quantity" | translate}}</span>
      <div class="numbers">
        <span>-</span>
        <input type="text" class="form-control" value="1" />
        <span>+</span>
      </div>
    </div>
    <div class="text-center w-100">
      <div class="btn btn-primary" (click)="classScheduled()">
        $40 {{"Confirm Payment" | translate}}
      </div>
    </div>
  </div>
</div>
