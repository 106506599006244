import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
 MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
  selector: "filter-dialog",
  templateUrl: "./filter-dialog.component.html",
  styleUrls: ["./filter-dialog.component.scss"],
})
export class FilterDialogComponent {
  selectedLanguages: string[] = [];
  selectedDurations: string[] = [];
  selectedPlatforms: string[] = [];
  search: string = "";
  language = "";
  languages = [
    { id: "2", label: "English", checked: false },
    { id: "3", label: "Italian", checked: false },
    { id: "4", label: "German", checked: false },
    { id: "5", label: "French", checked: false },
  ];
  durations = [
    { id: "6", label: "1 Months", checked: false },
    { id: "7", label: "3 Months", checked: false },
    { id: "8", label: "6 Months", checked: false },
    { id: "9", label: "12 Months", checked: false },
  ];
  platforms = [
    { id: "10", label: "PC", checked: false },
    { id: "11", label: "PlayStation", checked: false },
    { id: "12", label: "Xbox", checked: false },
    { id: "13", label: "Mobile", checked: false },
  ];

  isMobile = false;
  updateSelectedDurations() {
    this.selectedDurations = this.durations
      .filter((d) => d.checked)
      .map((d) => d.label);
  }
  updateSelectedPlatforms() {
    this.selectedPlatforms = this.platforms
      .filter((d) => d.checked)
      .map((d) => d.label);
  }
  updateSelectedlanguages() {
    this.selectedLanguages = this.languages
      .filter((d) => d.checked)
      .map((d) => d.label);
  }
  [x: string]: any;

  /**
   * Constructor
   *
   * @param {MatDialogRef<FilterDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
  ) {
    this.search = data.search;
  }
  @ViewChildren("languageCheckbox") languageCheckboxes: QueryList<ElementRef>;

  resultPage() {
    // this.selectedLanguages = [this.language];
    this.dialogRef.close();
    // this._router.navigate(['/filter-result']);
    localStorage.setItem("search", this.search);
    localStorage.setItem(
      "selectedDurations",
      JSON.stringify(this.selectedDurations)
    );
    localStorage.setItem(
      "selectedLanguages",
      JSON.stringify(this.selectedLanguages)
    );
    localStorage.setItem(
      "selectedPlatforms",
      JSON.stringify(this.selectedPlatforms)
    );
    this._router.navigate(["/filter-result"]);

    if (this._router.url === "/filter-result") {
      // Reload the current route
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this._router.onSameUrlNavigation = "reload";
      this._router.navigate([this._router.url]);
    } else {
      // Navigate to the filter-result page
      this._router.navigate(["/filter-result"]);
    }
  }

  ngOnInit() {
    this.breakpoint
        .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
        .subscribe((o) => {
          if (o.matches) {
            this.isMobile = o.matches;
            this._fuseConfigService.config = {
              layout: {
                navbar: {
                  hidden: this.isMobile,
                },
                toolbar: {
                  hidden: this.isMobile,
                },
                footer: {
                  hidden: this.isMobile,
                },
                sidepanel: {
                  hidden: this.isMobile,
                },
              },
            };
          }
        });
  }

  clear() {
    this.language = "";
    this.selectedDurations = [];
    this.selectedLanguages = [];
    this.selectedPlatforms = [];
    this.durations.forEach((d) => (d.checked = false));
    this.languages.forEach((d) => (d.checked = false));
    this.platforms.forEach((d) => (d.checked = false));
    this.search = '';
  }
}
