<div class="main pt-2">
  <div class="referrals">
    <div class="container-fluid">
      <h2>Referrals</h2>
      <div class="row">
        <div class="col-md-11">
          <div class="referral-link">
            <div class="head">
              <h4>Referral Link</h4>
              <span class="credit">Credit : $80</span>
            </div>
            <div class="code">
              <input
                type="text"
                class="form-control"
                value="123456788900/sweech.com"
              />
              <i
                class="far fa-copy"
                (click)="openSnackBar()"
                [cdkCopyToClipboard]="value"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Copy Referral Link"
              ></i>
            </div>
            <p>
              Invite anyone using you referral link & You both will get<br />
              a credit of <span>$10</span> after he spends his first $100
            </p>
          </div>

          <div class="transactions">
            <div class="row">
              <div class="col-md-5">
                <h5>Confirmed Transactions</h5>
                <div class="list">
                  <ul>
                    <li class="head">
                      <span class="left-side">Inivited User</span>
                      <span class="right-side">Credit Recieved</span>
                    </li>
                    <li>
                      <span class="left-side"></span>
                      <span class="right-side"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-5 offset-md-2 pending">
                <h5>Pending Transactions</h5>
                <div class="list">
                  <ul>
                    <li class="head">
                      <span class="left-side">Inivited User</span>
                      <span class="right-side">Credit Recieved</span>
                    </li>

                    <li>
                      <span class="left-side"></span>
                      <span class="right-side"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
