<div class="sub-deal-popup">
  <div class="sub-deal-dialog" [ngClass]="{'mobile-dialog': isMobile}">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <div class="box" [ngClass]="{
      'd-flex': isMobile,
      'flex-column': isMobile
    }">
      <img class="main-img" src="{{cls.image}}" alt="" />
      <div class="text-area">
        <span>{{cls.title}} ( {{cls.language}} )</span>
        <span> {{
          scheduleDayClass(cls)
        }}</span>
        <span> ({{ formatTime(cls.startTime) }} - {{ formatTime(cls.endTime) }})</span>
        <span>({{cls.startDate.toDate() | date: 'dd MMM, yyyy':'': 'it-IT'}}) - ({{cls.endDate.toDate() | date:'dd MMM, yyyy':'': 'it-IT'}})</span>
        <span>{{cls.classStudentsList.length}} / {{cls.maxNumberOfStudents}} {{"Students" |translate}}</span>
        <span class="green">€{{getDiscountedPrice(selectedRate, cls)}}/{{ selectedRate.duration.split(' ')[0] + ' ' + (selectedRate.duration.split(' ')[1] | translate) }}</span>
      </div>
    </div>
    <h3>{{"Class Platform & Requirments" | translate}}</h3>
    <div class="tags">
      <span class="overflow-ellipis">
        {{ cls.language }}
      </span>
      <span class="overflow-ellipis">
        {{ cls.gamingPlatform }}
      </span>
      <span class="overflow-ellipis peripherals">
        {{ cls.peripherals }}
      </span>
    </div>
    <div class="text-center w-100">
      <div class="btn btn-primary" (click)="cardDetail()" [ngClass]="{
        'padding-mobile': !isMobile
      }">{{"Subscribe Class" | translate}}</div>
    </div>
  </div>
</div>
