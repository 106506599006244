import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, of } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { LoginService } from "./main/authorizations/login/login.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FirestoreService } from "./shared/services/firestore.service";
import { Router } from "@angular/router";

@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  isMobile: boolean = false;
  private _unsubscribeAll: Subject<any>;
  user;
  public userFirestore$: Observable<any>;
  userFirestore: any;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    public LoginService: LoginService,
    private translate: TranslateService,
    private breakpoint: BreakpointObserver,
    private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private router: Router
  ) {
    this.navigation = navigation;
    this._fuseNavigationService.register("main", this.navigation);
    this._fuseNavigationService.setCurrentNavigation("main");

    translate.addLangs(["en", "it"]);
    translate.setDefaultLang("it");
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|it/) ? browserLang : "it");
    translate.use("it");
    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add("is-mobile");
    }
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.userFirestore$ = this.afAuth.authState.pipe(
      takeUntil(this._unsubscribeAll),
      switchMap((user) => {
        if (user) {
          return this.firestoreService.doc$("users/" + user.uid);
        } else {
          return of(null);
        }
      })
    );
    this.afAuth.authState
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        console.log(user);
        if (user) {
          this.firestoreService
            .doc$("users/" + user.uid)
            .subscribe((user: any) => {
              this.userFirestore = user;
              if (user.enabled == false) {
                this.afAuth.signOut();

                return;
              }
              console.log(user.settings.selectedLanguage);
              this.translate.use("it");
            });
        } else {
          this.userFirestore = null;
          this.router.navigateByUrl("/login");
        }
      });
  }

  ngOnInit() {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === "boxed") {
          this.document.body.classList.add("boxed");
        } else {
          this.document.body.classList.remove("boxed");
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith("theme-")) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(false);
    this._unsubscribeAll.complete();
  }
}
