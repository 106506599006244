import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";

@Component({
  selector: "request-sent-dialog",
  templateUrl: "./request-sent-dialog.component.html",
  styleUrls: ["./request-sent-dialog.component.scss"],
})
export class requestSentDialogComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<requestSentDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<requestSentDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
