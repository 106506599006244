<form [formGroup]="editProfileForm" (ngSubmit)="onSubmit()">
  <input type="text" formControlName="name" placeholder="Name" />
  <img [src]="imgSrc" alt="Profile Image Preview" />
  <input
    type="file"
    (change)="showPreview($event)"
    style="display: none"
    #image
  />
  <button type="button" class="btn btn-default" (click)="image.click()">
    {{ "Select Image" | translate }}
  </button>
  <button type="submit" [disabled]="!editProfileForm.valid" class="btn btn-primary">
    {{ "Update Profile" | translate }}
  </button>
</form>
