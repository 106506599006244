import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { joinServerDialogComponent } from "app/main/profile/join-server-dialog/join-server-dialog.component";
import { FirestoreService } from "app/shared/services/firestore.service";
import * as firebase from "firebase/compat/app";
import {
  filter,
  switchMap,
  map,
  forkJoin,
  of,
  tap,
  catchError,
  take,
  firstValueFrom,
  takeUntil,
  Subject,
} from "rxjs";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "app-success-payment",
  templateUrl: "./success-payment.component.html",
  styleUrls: ["./success-payment.component.scss"],
})
export class SuccessPaymentComponent implements OnInit {
  isMobile: boolean;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    public matDialog: MatDialog,
    private afAuth: AngularFireAuth,
    private afs: FirestoreService,
    private route: ActivatedRoute,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {
    this.observeBreakpoints();
  }

  ngOnInit() {
    this.observeAuthState();
  }

  private observeBreakpoints(): void {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((o) => {
        this.isMobile = o.matches;
        this.updateLayoutForMobile(this.isMobile);
      });
  }

  private updateLayoutForMobile(isMobile: boolean): void {
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: isMobile },
        toolbar: { hidden: isMobile },
        footer: { hidden: isMobile },
        sidepanel: { hidden: isMobile },
      },
    };
  }

  private observeAuthState(): void {
    this.afAuth.authState.subscribe(async (user) => {
      if (!user) return;

      try {
        const params = await firstValueFrom(this.route.queryParams);
        await this.processPaymentSuccess(user, params);
      } catch (error) {
        console.error("Error:", error);
      }
    });
  }

  private async processPaymentSuccess(user: any, params: any): Promise<void> {
    const classId = params.classId;
    const classData = await firstValueFrom(
      this.afs.doc$<any>(`classes/${classId}`)
    );

    const [studentData, teacherData, rateData] = await Promise.all([
      firstValueFrom(this.afs.doc$<any>(`users/${user.uid}`)),
      firstValueFrom(this.afs.doc$<any>(`users/${classData.teacherId}`)),
      firstValueFrom(
        this.afs.doc$<any>(
          `users/${classData.teacherId}/rates/${classData.rateId}`
        )
      ),
    ]);

    const uid = uuidv4();
    await Promise.all([
      this.updatePaymentDocument(
        uid,
        user,
        studentData,
        teacherData,
        classData,
        params
      ),
      this.updateClassDocument(classId, user),
      this.updateDiscounts(rateData, classData, teacherData.uid),
    ]);
  }

  private async updatePaymentDocument(
    uid: string,
    user: any,
    studentData: any,
    teacherData: any,
    classData: any,
    params: any
  ): Promise<void> {
    await this.afs.doc(`payments/${uid}`).set({
      transactionID: uid,
      masterName: teacherData.name ?? "Master",
      masterID: teacherData.uid,
      studentName: studentData.name,
      studentID: user.uid,
      classID: classData.uid,
      lessonCount: classData.scheduledDates.filter(
        (date) => new Date(date) > new Date()
      ).length,
      purchaseDate: firebase.default.firestore.Timestamp.now(),
      purchaseAmount: params.purchaseAmount ?? "0",
      rateId: classData.rateId
    });
  }

  private async updateClassDocument(classId: string, user: any): Promise<void> {
    await this.afs.doc(`classes/${classId}`).update({
      classStudentsList: firebase.default.firestore.FieldValue.arrayUnion(
        user.uid
      ),
    });
  }

  private async updateDiscounts(rateData, classData, uid) {
    if (rateData.discounts && rateData.discounts.length > 0) {
      rateData.discounts[0].count--;
      if (rateData.discounts[0].count == 0) {
        rateData.discounts = [];
      }
      await this.afs.doc(`users/${uid}/rates/${classData.rateId}`).update({
        ...rateData,
      });
    } else if (classData.discounts && classData.discounts.length > 0) {
      classData.discounts[0].count--;
      if (classData.discounts[0].count == 0) {
        classData.discounts = [];
      }
      await this.afs.doc(`classes/${classData.uid}`).update({
        discounts: classData.discounts,
      });
    }
  }

  // Rest of your code...

  private createMessage(
    studentName: string,
    classData: any,
    teacherName: string,
    isPaymentMail: boolean
  ) {
    if (!isPaymentMail) {
      return `Ciao ${teacherName},

      hai un nuovo iscritto!
      ${studentName} si è iscritto alla Tua classe ${classData.title}.
      
      Contattalo per dargli il benvenuto e per istruirlo su come preparare la sua postazione gaming in vista della prima lezione. Se non sai cosa fare, puoi seguire i nostri consigli nel "MANUALE TATTICO MAESTRO" che ti abbiamo inviato per mail alla tua iscrizione.
      Per qualunque dubbio siamo disponibili tramite il servizio clienti accessibile direttamente dalla piattaforma sweech, o inviando una mail a support@sweech.gg
      
      GG ${teacherName}!
      
      Team Sweech`;
    } else {
      return `Ciao ${studentName},

      Il tuo pagamemto su Sweech.gg è andato a buon fine!
      Sei iscritto correttamente alla classe ${
        classData.title
      } del maestro ${teacherName} che avrà inizio il ${classData.startDate.toDate()}.
      
      In attesa del giorno di inizio delle lezioni ti consigliamo di prendere contatto con il tuo maestro attraverso la piattaforma sweech student.sweech.gg , per sistemare la tua postazione gaming in vista della prima lezione.
      Per qualunque dubbio siamo disponibili tramite il servizio clienti accessibile direttamente dalla piattaforma sweech, o inviando una mail a support@sweech.gg.
      
      GG ${studentName}!
      
      Team Sweech`;
    }
  }

  joinServer() {
    this.matDialog.open(joinServerDialogComponent);
  }
}
