import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { MyProfileComponent } from "./my-profile.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { FinishedClassesComponent } from "./finished-classes-detail/finished-classes-detail.component";
import { PrivateFinishedClassDetailComponent } from "./private-fininshed-class-detail/private-fininshed-class-detail.component";
import { MatButtonModule } from "@angular/material/button";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { SharedAppModule } from "app/shared/shared-app.module";

const routes = [
  {
    path: "my-profile",
    component: MyProfileComponent,
  },
  {
    path: "finished-classes-detail",
    component: FinishedClassesComponent,
  },
  {
    path: "private-finished-classes-detail",
    component: PrivateFinishedClassDetailComponent,
  },
  {
    path: "my-profile/edit/:id",
    component: EditProfileComponent,
  },
];

@NgModule({
  declarations: [
    MyProfileComponent,
    FinishedClassesComponent,
    PrivateFinishedClassDetailComponent,
    EditProfileComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    MatButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    ToastrModule,
    SharedAppModule
  ],
})
export class MyProfileModule {}
