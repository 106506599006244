import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "app/shared/services/user";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  uid = "";
  user: any;
  editProfileForm: FormGroup;
  imgSrc: string = "assets/images/dummy_user.png";
  selectedImage: any = null;
  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.editProfileForm = this.fb.group({
      name: ["", Validators.required],
      image: [""],
    });
  }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((usr) => {
      if (usr) {
        this.route.params.subscribe((e) => {
          if (e.id) {
            this.uid = e.id;
            this.afs
              .collection("users")
              .doc(e.id)
              .valueChanges()
              .subscribe((user: any) => {
                if (user) {
                  this.user = user;
                  if (this.user.nickname) {
                    this.editProfileForm.get("name")?.setValue(this.user.nickname);
                  }
                  if (this.user.profilePic) {
                    this.imgSrc = this.user.profilePic ?? "assets/images/dummy_user.png";
                  }
                }
              });
          }
        });
      }
    });
  }
  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => (this.imgSrc = e.target.result);
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    } else {
      this.imgSrc = "assets/images/dummy_user.png";
      this.selectedImage = null;
    }
  }

  onSubmit(): void {
    const values = this.editProfileForm.value;
    values.image = this.selectedImage ?? "assets/images/dummy_user.png"; // assign selected image to form value

    if (this.selectedImage) {
      const filePath = `userImages/${this.uid}/${values.image.name
        .split(".")
        .slice(0, -1)
        .join(".")}_${new Date().getTime()}`;
      const fileRef = this.storage.ref(filePath);

      this.storage
        .upload(filePath, values.image)
        .then(() => {
          const obser = {
            next: (url) => {
              this.afs
                .collection("users")
                .doc(this.uid)
                .update({
                  nickname: values.name,
                  username: values.name,
                  profilePic: url,
                })
                .then(() => {
                  this.toastr.success("User profile updated");
                  console.log("User profile updated");
                  this.router.navigateByUrl('/my-profile');
                })
                .catch((error) => {
                  this.toastr.error("Error updating user profile");
                  console.error("Error updating user profile", error);
                });
            },
            error: (error) => {
              this.toastr.error("Error updating user profile");
              console.error("Error getting download URL", error);
            },
          };
          fileRef.getDownloadURL().subscribe(obser);
        })
        .catch((error) => {
          this.toastr.error("Error updating user profile");
          console.error("Error uploading image", error);
        });
    } else {
      this.afs
        .collection("users")
        .doc(this.uid)
        .update({
          nickname: values.name,
          username: values.name,
        })
        .then(() => {
          this.toastr.success("User profile updated");
          this.router.navigateByUrl('/my-profile');
          console.log("User profile updated");
        })
        .catch((error) => {
          this.toastr.error("Error updating user profile");
          console.error("Error updating user profile", error);
        });
    }
  }
}
