<div class="main pt-3">
  <div class="rates-edit">
    <div class="container-fluid">
      <h2>Edit Rates</h2>
      <div class="form-area">
        <form>
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <div class="set-availability">
                <div class="form-group" (click)="calendar()">
                  <input
                    type="text"
                    class="form-control"
                    id=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <img src="assets/images/caledar-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-box">
            <div class="general">
              <h3>General :</h3>
              <div class="form-group">
                <label>No of Lessons :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder=""
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label>Rate Title :</label>
                <select class="form-control" id="">
                  <option>1 Month</option>
                  <option>3 Month</option>
                  <option>6 Month</option>
                  <option>1 Year</option>
                </select>
              </div>
              <div class="form-group">
                <label>Price :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder=""
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label>Hours/Lessons :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder=""
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="earnings-preview">
              <h3>Earnings Preview :</h3>
              <div class="form-group">
                <label>Total Lessons :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder=""
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label>Service Cost per Student :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder=""
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label>Full Earning (Class) :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder=""
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <h4>Description :</h4>
          <textarea class="form-control"></textarea>

          <h4>Discounts :</h4>
          <div class="discount-box">
            <div class="list">
              <span class="text"
                ><u>25%</u> off for the first <u>15</u> students by<br />using
                this promo code
              </span>
              <input type="text" class="form-control" value="W2xyB" />
            </div>
            <div class="list">
              <span class="text"
                ><u>15%</u> off for the first <u>10</u> days of class<br />using
                this promo code
              </span>
              <input type="text" class="form-control" value="10x15zY" />
            </div>
          </div>
          <div class="text-right mt-5">
            <button class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
