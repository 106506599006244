import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { RequestGameDialogComponent } from "app/main/authorizations/choose-game/request-game-dialog/request-game-dialog.component";
declare var $: any;

@Component({
  selector: "class-setting",
  templateUrl: "./class-setting.component.html",
  styleUrls: ["./class-setting.component.scss"],
})
export class ClassSettingComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {}

  supportDialog() {
    this.matDialog.open(RequestGameDialogComponent);
  }
}
