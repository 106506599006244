import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import Stripe from "stripe";
import { FirestoreService } from "./firestore.service";
import * as firebase from "firebase/compat/app";
import { firstValueFrom } from "rxjs";
const stripe = new Stripe(environment.stripe_secret, {
  apiVersion: "2022-11-15",
});
@Injectable({
  providedIn: "root",
})
export class StripeWService {
  constructor(private firestore: FirestoreService) {}

  async createCustomer(email: string, name: string) {
    return await stripe.customers.create({
      email: email,
      name: name,
    });
  }

  async getCustomer(custId: string) {
    return await stripe.customers.retrieve(custId);
  }

  async createCardToken(
    card: Stripe.TokenCreateParams.Card,
    customerId: string
  ) {
    return await stripe.tokens.create({
      card: card,
      customer: customerId,
    });
  }

  createPaymentMethod(card: Stripe.PaymentMethodCreateParams.Card1) {
    return stripe.paymentMethods.create({
      type: "card",
      card: card,
    });
  }

  checkIfPaymentMethodAlreadyExist() {
    return stripe.paymentMethods.list({
      customer: "cus_J4fU0R9qQ9YzGj",
      type: "card",
    });
  }
  checkIfPaymentMethodAlreadyExistUsingCard(card: any) {
    return stripe.paymentMethods.list({
      customer: "cus_J4fU0R9qQ9YzGj",
      type: "card",
    });
  }
  async attachPaymentMethodtoCustomer(
    paymentMethodId: string,
    customerId: string
  ) {
    return await stripe.paymentMethods.attach(paymentMethodId, {
      customer: customerId,
    });
  }

  async createCharge(
    amount: number,
    token: string,
    currency: string = "usd",
    description: string = ""
  ) {
    return await stripe.charges.create({
      amount,
      currency,
      source: token,
      description,
    });
  }

  async detachCard(paymethodMethodId: string) {
    return await stripe.paymentMethods.detach(paymethodMethodId);
  }

  async checkout(classPrice, cls): Promise<Stripe.Checkout.Session> {
    // const stripe = await this.stripePromise;
    console.log(classPrice * 100)
    const price = await stripe.prices.create({
      unit_amount: Math.ceil(classPrice * 100),
      currency: "eur",
      product_data: {
        name: cls.title ?? 'Class',
      },
    });

    const session = await stripe.checkout.sessions.create({
      line_items: [{ price: price.id, quantity: 1 }],
      mode: "payment",
      success_url: `${environment.host}/success_payment?purchaseAmount=${classPrice}&classId=` + cls.uid,
      cancel_url: `${environment.host}/cancel_payment`,
      allow_promotion_codes: true
    });
    console.log(session);

    return session;
  }
  async savePaymentMethodToFirestore(userId: string, paymentMethodId: string) {
    // Save the payment method ID for the specific user in Firestore
    return this.firestore.update(`users/${userId}`, {
      paymentMethodIds:
        firebase.default.firestore.FieldValue.arrayUnion(paymentMethodId),
    });
  }

  async removePaymentMethodFromFirestore(
    userId: string,
    paymentMethodId: string
  ) {
    // Remove the payment method ID for the specific user from Firestore
    return this.firestore.update(`users/${userId}`, {
      paymentMethodIds:
        firebase.default.firestore.FieldValue.arrayRemove(paymentMethodId),
    });
  }

  async getPaymentMethodsFromFirestore(userId: string) {
    const userDoc = await firstValueFrom(
      this.firestore.doc$<any>(`users/${userId}`)
    );
    return userDoc?.paymentMethodIds || [];
  }

}
