<div class="confirm-purchase-popup">
  <div class="confirm-purchase-dialog" [ngClass]="{'mobile-dialog': isMobile}">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <img
      class="confirm-img"
      src="assets/images/profile/dialog/confirm.png"
      alt=""
    />
    <h3>{{"Confirm Purchase" | translate}}</h3>
    <p>
      {{"You are about to purchase Monthly Subscription of " | translate}}<span>€{{getDiscountedPrice(data.selectedRate, data.cls)}}</span>. 
    </p>
    <div class="text-center w-100">
      <div class="btn btn-primary" [ngClass]="{
        'padding-mobile': !isMobile
      }" (click)="classScheduled()">{{"Purchase" | translate}}</div>
    </div>
  </div>
</div>
