import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DatePipe } from "@angular/common";
import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/helper-services/auth.service";
import { RequestGameDialogComponent } from "app/main/authorizations/choose-game/request-game-dialog/request-game-dialog.component";
import { DynamicLinkService } from "app/shared/services/dynamic-link.service";
import { FirestoreService } from "app/shared/services/firestore.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;
import { Observable, firstValueFrom, map, tap } from "rxjs";
// import { AngularFireDynamicLink, DynamicLink, NavigationInfo } from '@angular/fire/compat/';

@Component({
  selector: "group-class-detail",
  templateUrl: "./group-class-detail.component.html",
  styleUrls: ["./group-class-detail.component.scss"],
})
export class GroupClassDetailComponent implements OnInit {
  groupBtn: Boolean = true;
  blackboardText: string = "";
  classId: string = "";
  classData: any;

  classActivities: any = [];

  scheduleDays: any = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ].map((d) => this.translate.instant(d));
  days = "";
  upcomingClasses: Observable<any[]>;

  students = [];
  selectedDayClasses: any[] = [];
  month: number;
  year: number;
  upcomingClassesData: any[] = [];
  daysWithClasses: any[] = [];
  selectedMonth: Date;
  thismonth: Date;
  nextMonth: Date;
  monthAfterNext: Date;
  dropdownOptions: { value: string; label: string }[];
  daysInMonth: any[];
  blankDays: any[];
  selDate: Date = new Date();
  isMobile: boolean = false;
  uid = "";
  master$: Observable<any>;
  selectedMonthVal: string = "";
  selectedWeek: number;
  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private router: Router,
    private translate: TranslateService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private dialog: MatDialog,
    private dynamicLinkService: DynamicLinkService
  ) {
    this.selectedMonth = new Date();

    const today = new Date();
    this.year = today.getFullYear();
    this.month = today.getMonth();
    this.selectedMonthVal = `${this.year}-${this.month}`;
    this.thismonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.selectedMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);
    this.monthAfterNext = new Date(today.getFullYear(), today.getMonth() + 2);
    // of length 12
    const currentYear = new Date().getFullYear();
    const startYear = 2023;
    const endYear = currentYear + 2;
    const totalYears = endYear - startYear + 1;
    const totalMonths = 12 * totalYears;
    let monthIndex = 0;
    this.dropdownOptions = Array.from({ length: totalMonths }, () => ({
      value: "",
      label: "",
    }));

    for (let year = startYear; year <= endYear; year++) {
      for (let month = 0; month < 12; month++) {
        this.dropdownOptions[monthIndex].label = this.getMonthName(
          new Date(year, month, 1)
        );
        this.dropdownOptions[monthIndex].value = `${year}-${month}`;
        monthIndex++;
      }
    }
  }
  formatTime(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.uid = user.uid;
            this.classId = params["id"];
            this.fetchClassData();
            this.getDaysOfMonth();
          }
        });

        // this.getUpcomingClasses();
      }
    });
  }

  async fetchClassData() {
    try {
      const classDocSnapshot = await firstValueFrom(
        this.firestore.doc(`classes/${this.classId}`).get()
      );
      this.classData = classDocSnapshot.data();
      this.blackboardText = this.classData.notice;
      this.master$ = this.firestoreService.doc$(
        `users/${this.classData.teacherId}`
      );

      // Convert it to a string of comma separated days
      this.days = this.classData.scheduleDays
        .reduce((acc, d, index) => {
          if (d) {
            acc.push(this.scheduleDays[index]);
          }
          return acc;
        }, [])
        .join(", ");

      const classActivitiesSnapshot = await firstValueFrom(
        this.firestoreService.colWithIds$<any>(
          `classes/${this.classId}/classActivities`
        )
      );
      // this.classData.classStudentsList = this.classData.classStudentsList.filter((e) => e != this.uid)
      this.classActivities = classActivitiesSnapshot;
      this.students = await this.fetchClassStudentList(
        this.classData.classStudentsList
      );
      this.classData.scheduledDates.forEach((date) => {
        this.daysWithClasses.push(
          this.datePipe.transform(date.toDate(), "yyyy-MM-dd")
        );
      });

      this.upcomingClassesData = [this.classData];
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  }

  onMonthChange(event) {
    this.month = event.target.value.split("-")[1];
    this.year = event.target.value.split("-")[0]; // get year from the selected month
    this.selectedMonthVal = event.target.value;
    this.getDaysOfMonth();
  }

  getDaysOfMonth() {
    this.daysInMonth = [];
    this.blankDays = [];

    const daysInThisMonth = new Date(this.year, this.month + 1, 0).getDate();
    const firstDayThisMonth = new Date(this.year, this.month, 1).getDay();
    const prevMonthDays = new Date(this.year, this.month, 0).getDate();

    for (let i = 0; i < firstDayThisMonth; i++) {
      this.blankDays.push(prevMonthDays - i);
    }
    this.blankDays.reverse();

    for (let i = 0; i < daysInThisMonth; i++) {
      this.daysInMonth.push(i < 9 ? "0" + (i + 1) : i + 1);
    }
  }
  convertTo12HourFormat(time: string): string {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hoursIn12HourFormat = hours % 12 || 12;
    return `${hoursIn12HourFormat}:${minutes} ${period}`;
  }

  async fetchClassStudentList(studentIds: string[]): Promise<any[]> {
    const studentPromises = studentIds.map((id) =>
      firstValueFrom(this.firestoreService.doc(`users/${id}`).get())
    );
    const studentDocs = await Promise.all(studentPromises);

    return studentDocs.map((doc) => {
      const data: any = doc.data();
      return { ...data, id: doc.id };
    });
  }

  getActivityStudentName(presentStudents: string[]): string[] {
    return this.students.filter((student) =>
      presentStudents.includes(student.id)
    );
    // .map((student) => student.username)
  }

  TabChanged(tab = { index: 0 }) {
    if (tab.index == 0) {
      this.groupBtn = true;
    } else {
      this.groupBtn = false;
    }
  }

  getMonthName(date: Date): string {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((m) => this.translate.instant(m));
    return monthNames[date.getMonth()] + " " + date.getFullYear();
  }
  onDateClick(day: number) {
    const clickedDate = new Date(this.year, this.month, day);
    clickedDate.setHours(0, 0, 0, 0); // Ensure the time part is zeroed out for accurate comparison
    this.selDate = clickedDate;
    this.selectedWeek = this.getWeekOfMonth(clickedDate);
    this.selectedDayClasses = this.upcomingClassesData
      .filter((classData) =>
        classData.scheduledDates.some((timestamp) => {
          const scheduledDate = timestamp.toDate();
          scheduledDate.setHours(0, 0, 0, 0);
          return scheduledDate.getTime() === clickedDate.getTime();
        })
      )
      .map((classData) => {
        return {
          ...classData,
          startTimeMinutes: this.timeToMinutes(classData.startTime),
          endTimeMinutes: this.timeToMinutes(classData.endTime),
        };
      });
  }
  getWeekOfMonth(date) {
    const month = date.getMonth();
    let count = 0;
    for (let i = 1; i < date.getDate(); i++) {
      const temp = new Date(date.getFullYear(), month, i);
      if (temp.getDay() !== 6 || i === 1) {
        count++;
      }
    }
    return Math.ceil(count / 7);
  }
  timeToMinutes(time: string): number {
    const [timePart, period] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes;

    // If it's PM and it's not 12:xx PM, we add 12 hours to shift to 24h format
    // if (period.toUpperCase() === "PM" && hours !== 12) {
    //   totalMinutes += 12 * 60;
    // }
    // // If it's AM and it's 12:xx AM, we subtract 12 hours to shift to 24h format
    // else if (period.toUpperCase() === "AM" && hours === 12) {
    //   totalMinutes -= 12 * 60;
    // }
    return totalMinutes;
  }
  shareLink() {
    this.dynamicLinkService
      .createDynamicLink(this.uid)
      .pipe(
        map((link: any) => {
          console.log(link);
          return link.shortLink;
        })
      )
      .subscribe((link) => {
        console.log(link);
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(link)
            .then(() => {
              this.toastr.success(
                this.translate.instant("Link copied to clipboard"),
                "Success"
              );
            })
            .catch((err) => {
              console.error("Could not copy text: ", err);
              this.toastr.error(
                this.translate.instant("Failed to copy link"),
                "Error"
              );
            });
        } else {
          // Clipboard API not available
          this.toastr.error(
            this.translate.instant("Clipboard not supported"),
            "Error"
          );
        }
        this.openDialog(link);
      });
  }
  openDialog(link: string): void {
    this.dialog.open(LinkDialogComponent, {
      width: "450px",
      data: { link: link },
    });
  }
  getTimePosition(time: string): number {
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    return (totalMinutes / 60) * 50; // Assumes each hour is 50 pixels tall
  }
  isClassScheduled(day: number): boolean {
    const date = new Date(this.year, this.month, day);
    const dateKey = this.datePipe.transform(date, "yyyy-MM-dd"); // Format the date as 'yyyy-MM-dd'
    return this.daysWithClasses.includes(dateKey);
  }
  // getUpcomingClasses() {
  //   this.afAuth.authState.subscribe((user) => {
  //     if (user == null) return;
  //     const loggedInUserId = user?.uid;
  //     const today = new Date();
  //     this.upcomingClasses = this.firestore
  //       .collection("classes", (ref) =>
  //         ref
  //           .where("teacherId", "==", loggedInUserId)
  //           .where("endDate", ">=", today)
  //           .orderBy("endDate", "asc")
  //       )
  //       .valueChanges();

  //     this.upcomingClasses.subscribe((classes) => {
  //       this.upcomingClassesData = classes;
  //       console.log(this.upcomingClassesData);
  //       this.upcomingClassesData.forEach((classData) => {
  //         classData.scheduledDates.forEach((date) => {
  //           this.daysWithClasses.push(
  //             this.datePipe.transform(date.toDate(), "yyyy-MM-dd")
  //           );
  //         });
  //       });
  //     });
  //   });
  // }

  openChat(userId: string) {
    this.router.navigate(["/messages/", userId]);
  }

  contactSupport() {
    this.dialog.open(RequestGameDialogComponent, {
      data: {
        classId: this.classId,
        isFromClass: true,
      },
    });
  }
}

import { Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-link-dialog",
  template: `
    <h2 mat-dialog-title>Link</h2>
    <div mat-dialog-content>
      <p>{{ link.link }}</p>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        class="btn"
        style="    margin-right: 0.5rem;"
        (click)="copyLink()"
      >
        Copy to Clipboard
      </button>
      <button mat-button class="btn" (click)="closeDialog()">Close</button>
    </div>
  `,
})
export class LinkDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public link: any
  ) {}

  copyLink() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.link.link)
        .then(() => {
          // Optionally, indicate to the user that the action succeeded
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
