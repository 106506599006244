<div class="main pt-2">
  <div class="activities">
    <div class="container-fluid">
      <h2>My Activities</h2>
      <h3>Active Classes :</h3>
      <div class="row mb-5">
        <div class="col-md-4">
          <a class="top-game-box" routerLink="/group-class-view">
            <img
              class="img-fluid"
              src="assets/images/dashboard/top-game1.png"
              alt=""
            />
            <div class="text-area">
              <span class="name">Fortnite</span>
              <span class="date">Aug,12 2022 - Oct, 12 2022</span>
            </div>
          </a>
        </div>
      </div>
      <h3>Active Private Lessons :</h3>
      <div class="row mb-5">
        <div class="col-md-4" *ngFor="let class of privateclasses$ | async">
          <a class="lessons-box" routerLink="/private-class-view">
            <img class="img-fluid" src="{{ class.image }}" alt="" />
            <div class="text-area">
              <span class="title">{{ class.gameTitle }}</span>
              <div class="bottom-row">
                <span class="name">Alex Wood</span>
                <span class="lessons">8 lessons</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="calendar-box mt-5">
        <div class="row">
          <div class="col-md-6">
            <select name="" id="" class="form-control">
              <option value="">Aug, 2022</option>
              <option value="">Sep, 2022</option>
              <option value="">Oct, 2022</option>
            </select>
            <div class="select-date">
              <ul class="weekdays">
                <li><span class="active">Mon</span></li>
                <li>Tue</li>
                <li>Wed</li>
                <li>Thu</li>
                <li>Fri</li>
                <li>Sat</li>
                <li>Sun</li>
              </ul>
              <ul class="days">
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li><span class="active">8</span></li>
                <li>9</li>
                <li>10</li>
                <li>11</li>
                <li>12</li>
                <li>13</li>
                <li>14</li>
                <li>15</li>
                <li>16</li>
                <li>17</li>
                <li>18</li>
                <li>19</li>
                <li>20</li>
                <li>21</li>
                <li>22</li>
                <li>23</li>
                <li>24</li>
                <li>25</li>
                <li>26</li>
                <li>27</li>
                <li>28</li>
                <li>29</li>
                <li>30</li>
                <li>31</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <select name="" id="" class="form-control">
              <option value="">Week 2</option>
              <option value="">Week 3</option>
              <option value="">Week 4</option>
            </select>
            <div class="events">
              <div class="event-time">
                <ul class="time">
                  <li>12:00 am</li>
                  <li>01:00 pm</li>
                  <li>02:00 pm</li>
                  <li>03:00 pm</li>
                  <li>04:00 pm</li>
                  <li>05:00 pm</li>
                  <li>06:00 pm</li>
                </ul>
              </div>
              <div class="event-list">
                <div class="box">
                  <img src="assets/images/event-small.png" alt="" />
                  <div>
                    <span class="title"
                      >Valorant Coaching with pro ESports Player
                    </span>
                    <span class="day">Tuesday & Friday</span>
                  </div>
                </div>
                <div class="box">
                  <img src="assets/images/event-small.png" alt="" />
                  <div>
                    <span class="title"
                      >Valorant Coaching with pro ESports Player
                    </span>
                    <span class="day">Tuesday & Friday</span>
                  </div>
                </div>
                <div class="box">
                  <img src="assets/images/event-small.png" alt="" />
                  <div>
                    <span class="title"
                      >Valorant Coaching with pro ESports Player
                    </span>
                    <span class="day">Tuesday & Friday</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
