import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { deleteConfirmDialogModule } from "./confirm-dialog.module";
import { AuthService } from "app/shared/services/auth.service";
import { FirestoreService } from "app/shared/services/firestore.service";
import { v4 as uuidv4 } from "uuid";
import * as firebase from "firebase/compat/app";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "delete-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class deleteConfirmDialogComponent {
  public confirmMessage: string;

  constructor(
    public dialogRef: MatDialogRef<deleteConfirmDialogComponent>,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  logout() {
    const uid = uuidv4();
    this.firestoreService.set(`support/${uid}`, {
      category: this.translate.instant("Delete Account"),
      createdAt: firebase.default.firestore.FieldValue.serverTimestamp(),
      fromClass: '',
      classId: '',
      isMaster: true,
      isNew: true,
      isReplied: false,
      message: this.translate.instant('Want to Delete Account'),
      reportedUserId: "",
      senderEmail: this.data.user.email,
      senderId: this.data.user.uid,
      senderID: this.data.user.uid,
      senderUsername: this.data.user.username,
      studentsToRemove: [],
      toClass: "",
      uid: uid,
      updatedAt: firebase.default.firestore.FieldValue.serverTimestamp(),
      wantRefund: false,
    }).then(() => {
      console.log('Logged out');
      this.authService.SignOut();
      //TODO: Disable Account

      this.firestoreService.doc('users/' + this.data.user.uid).update({
        enabled: false
      });

      this.dialogRef.close(true);
    });   
  }
}
