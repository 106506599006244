import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { deleteConfirmDialogComponent } from "./confirm-dialog.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [deleteConfirmDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class deleteConfirmDialogModule {}
