<div id="auth" class="auth">
  <div class="container-fluid" style="position: relative">
    <img
      class="sweech-logo"
      style="position: absolute; top: 20px; left: 20px"
      src="assets/images/sweech-logo.png"
      alt=""
    />

    <div class="row align-items-center">
      <div class="col-md-5">
        <div class="auth-box pt-0">
          <h1 class="hq">{{ "Student Login" | translate }}</h1>
          <p>
            {{ "Please fill your detail to access your account." | translate }}
          </p>
          <form [formGroup]="verificationForm" (ngSubmit)="addData()">
            <div class="form-group">
              <label>{{ "Nickname" | translate }}</label>

              <input
                class="form-control"
                id=""
                placeholder="Nickname"
                autocomplete="off"
                [disabled]="verificationId"
                [(ngModel)]="nickname"
                name="nickname"
                minlength="3"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <!-- <div class="form-group" *ngIf="!verificationId && defaultCountry">
              <ngx-intl-tel-input
                class="w-100"
                [cssClass]="'form-control w-100 phone-input'"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [phoneValidation]="true"
                name="phone"
                formControlName="phone"
                [defaultCountry]="defaultCountry"
                [preferredCountries]="[defaultCountry]"
                [inputId]="'phone'"
                #phoneInput
              ></ngx-intl-tel-input>
            </div> -->

            <!-- <div class="form-group" *ngIf="verificationId">
              <div>
                <label for="code">{{
                  "Enter Verification Code" | translate
                }}</label>
                <input
                  formControlName="code"
                  id="code"
                  type="text"
                  name="code"
                  class="form-control"
                />
              </div>
            </div>
            <div id="recaptcha-container"></div>
            <div *ngIf="verificationId" (click)="resendVerificationCode()">
              <p style="cursor: pointer">
                {{ "Resend Code 1" | translate }}
              </p>
              <p>
                {{ "Resend Code 2" | translate }}
              </p>
            </div> -->
            <button
              type="submit"
              class="btn btn-primary mb-3 mt-4"
              [disabled]="isLoading"
            >
              <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
              {{ "Save" | translate }}
            </button>
          </form>
        </div>
      </div>
      <div class="col-md-7">
        <img class="img-fluid" src="/assets/images/login.jpg" />
      </div>
    </div>
  </div>
</div>
