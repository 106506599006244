<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main pt-2">
  <div class="filter-result">
    <div class="container-fluid">
      <div class="search-games">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="d-flex align-items-center">
              <div class="form-group">
                <i class="fa fa-search ng-tns-c208-14"></i>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="search"
                  placeholder="Search Game & Master"
                  name="search"
                  (keyup.enter)="searchMasters()"
                />
              </div>
              <button class="btn" style='margin-right: 0.5rem;' (click)="searchMasters()">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-area">
        <div
          class="nav-filters"
          *ngIf="selectedIndex == 0"
          [ngStyle]="{ position: isMobile ? 'inherit' : 'absolute' }"
        >
          <span class="filter-text">{{ "Show Results :" | translate }}</span>
          <select
            class="form-control"
            id=""
            (change)="onDropdownChange($event.target.value)"
          >
            <!-- <option value="Ascending Price">
              {{ "Ascending Price" | translate }}
            </option>
            <option value="Descending Price">
              {{ "Descending Price" | translate }}
            </option> -->
            <option value="Ascending Ratings">
              {{ "Ascending Ratings" | translate }}
            </option>
            <option value="Descending Ratings">
              {{ "Descending Ratings" | translate }}
            </option>
          </select>
        </div>
        <mat-tab-group
          animationDuration="0ms"
          [(selectedIndex)]="selectedIndex"
        >
          <mat-tab [label]="'Masters' | translate">
            <div class="masters-list">
              <div
                class="row"
                *ngIf="users && users.length > 0; else noMasters"
              >
                <div class="col-md-6" *ngFor="let user of users; let i = index">
                  <a
                    class="box"
                    [routerLink]="['/profile']"
                    (click)="storeUid(user.uid)"
                  >
                    <img
                      src="{{
                        user.profilePic ?? 'assets/images/dummy_user.png'
                      }}"
                      alt=""
                      (error)="imageDidNotLoad($event)"
                    />
                    <div class="detail">
                      <span class="name" [ngStyle]="{'font-size': isMobile ? '16px' : '18px'}">{{ user.username }}</span>
                      <div class="text-row">
                        <span class="heading" [ngStyle]="{'font-size': isMobile ? '12px' : '16px'}"
                          >{{ "Rating :" | translate }}
                        </span>
                        <div class="stars">
                          <ng-container *ngFor="let i of [0, 1, 2, 3, 4]">
                            <i
                              class="fa-star fas"
                              *ngIf="i < (user.rating ?? 0)"
                            ></i>
                            <i
                              class="fa-star far"
                              *ngIf="i >= (user.rating ?? 0)"
                            ></i>
                          </ng-container>
                        </div>

                        <span class="end-text" [ngStyle]="{'font-size': isMobile ? '12px' : '15px'}">{{ user.rating ?? 0 }}</span>
                      </div>
                      <div class="text-row">
                        <span class="heading" [ngStyle]="{'font-size': isMobile ? '12px' : '16px'}">{{
                          "Registered :" | translate
                        }}</span>
                        <span class="end-text" [ngStyle]="{'font-size': isMobile ? '12px' : '15px'}">{{
                          formatDate(user.creationDateTS)
                        }}</span>
                      </div>
                      <!-- <div class="text-row">
                        <span class="heading">{{
                          "Games :" | translate
                        }}</span>
                        <span class="end-text">{{ user.gamesTeached.join(',') }}</span>
                      </div> -->
                    </div>
                    <div class="packages">
                      <!-- <span *ngFor="let rate of rates[i];">€{{rate.price}} / {{rate.duration}}</span> -->
                    </div>
                  </a>
                </div>
              </div>
              <ng-template #noMasters>
                <div class="row">
                  <div class="col-md-12 text-center mt-5">
                    {{ "No masters found" | translate }}
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab [label]="'Games' | translate">
            <div class="games-list">
              <div class="row" *ngIf="games && games.length > 0; else noGames">
                <div
                  class="col-md-6"
                  *ngFor="let g of games; let i = index"
                  (click)="gameClicked(g)"
                >
                  <img [src]="g.image" alt="" />
                </div>
              </div>
              <ng-template #noGames>
                <div class="row">
                  <div class="col-md-12 text-center mt-5">
                    {{ "No games found" | translate }}
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
