<div class="main pt-2">
  <div class="filter-result">
    <div class="container-fluid">
      <div class="search-games">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="d-flex align-items-center">
              <div class="form-group" (click)="filter()">
                <i class="fa fa-search ng-tns-c208-14"></i>
                <input
                  type="text"
                  class="form-control"
                  value="valorant"
                  placeholder="Search Game & Master"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-area">
        <div class="nav-filters">
          <span class="filter-text">Show Results :</span>
          <select class="form-control" id="">
            <option>Ascending Price</option>
            <option>Descending Price</option>
            <option>Ascending Ratings</option>
            <option>Descending Ratings</option>
          </select>
        </div>
        <mat-tab-group animationDuration="0ms" [selectedIndex]="2">
          <mat-tab label="Masters">
            <div class="masters-list">
              <div class="row">
                <div class="col-md-6">
                  <a class="box" routerLink="/profile">
                    <img src="assets/images/dashboard/master.png" alt="" />
                    <div class="detail">
                      <span class="name">Alex Housten</span>
                      <div class="text-row">
                        <span class="heading">Rating :</span>
                        <div class="stars">
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star far"></i>
                        </div>
                        <span class="end-text">4.0</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Registered :</span>
                        <span class="end-text">Jul 18, 2021</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Courses Finished :</span>
                        <span class="end-text">23</span>
                      </div>
                    </div>
                    <div class="packages">
                      <span>$60/ 1 Month</span>
                      <span>$200/ 3 Months</span>
                      <span>$500/ 6 Months</span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6">
                  <a class="box" routerLink="/profile">
                    <img src="assets/images/dashboard/master.png" alt="" />
                    <div class="detail">
                      <span class="name">Alex Housten</span>
                      <div class="text-row">
                        <span class="heading">Rating :</span>
                        <div class="stars">
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star far"></i>
                        </div>
                        <span class="end-text">4.0</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Registered :</span>
                        <span class="end-text">Jul 18, 2021</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Courses Finished :</span>
                        <span class="end-text">23</span>
                      </div>
                    </div>
                    <div class="packages">
                      <span>$60/ 1 Month</span>
                      <span>$200/ 3 Months</span>
                      <span>$500/ 6 Months</span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6">
                  <a class="box" routerLink="/profile">
                    <img src="assets/images/dashboard/master.png" alt="" />
                    <div class="detail">
                      <span class="name">Alex Housten</span>
                      <div class="text-row">
                        <span class="heading">Rating :</span>
                        <div class="stars">
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star far"></i>
                        </div>
                        <span class="end-text">4.0</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Registered :</span>
                        <span class="end-text">Jul 18, 2021</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Courses Finished :</span>
                        <span class="end-text">23</span>
                      </div>
                    </div>
                    <div class="packages">
                      <span>$60/ 1 Month</span>
                      <span>$200/ 3 Months</span>
                      <span>$500/ 6 Months</span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6">
                  <a class="box" routerLink="/profile">
                    <img src="assets/images/dashboard/master.png" alt="" />
                    <div class="detail">
                      <span class="name">Alex Housten</span>
                      <div class="text-row">
                        <span class="heading">Rating :</span>
                        <div class="stars">
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star far"></i>
                        </div>
                        <span class="end-text">4.0</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Registered :</span>
                        <span class="end-text">Jul 18, 2021</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Courses Finished :</span>
                        <span class="end-text">23</span>
                      </div>
                    </div>
                    <div class="packages">
                      <span>$60/ 1 Month</span>
                      <span>$200/ 3 Months</span>
                      <span>$500/ 6 Months</span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6">
                  <a class="box" routerLink="/profile">
                    <img src="assets/images/dashboard/master.png" alt="" />
                    <div class="detail">
                      <span class="name">Alex Housten</span>
                      <div class="text-row">
                        <span class="heading">Rating :</span>
                        <div class="stars">
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star far"></i>
                        </div>
                        <span class="end-text">4.0</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Registered :</span>
                        <span class="end-text">Jul 18, 2021</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Courses Finished :</span>
                        <span class="end-text">23</span>
                      </div>
                    </div>
                    <div class="packages">
                      <span>$60/ 1 Month</span>
                      <span>$200/ 3 Months</span>
                      <span>$500/ 6 Months</span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6">
                  <a class="box" routerLink="/profile">
                    <img src="assets/images/dashboard/master.png" alt="" />
                    <div class="detail">
                      <span class="name">Alex Housten</span>
                      <div class="text-row">
                        <span class="heading">Rating :</span>
                        <div class="stars">
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star fas"></i>
                          <i class="fa-star far"></i>
                        </div>
                        <span class="end-text">4.0</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Registered :</span>
                        <span class="end-text">Jul 18, 2021</span>
                      </div>
                      <div class="text-row">
                        <span class="heading">Courses Finished :</span>
                        <span class="end-text">23</span>
                      </div>
                    </div>
                    <div class="packages">
                      <span>$60/ 1 Month</span>
                      <span>$200/ 3 Months</span>
                      <span>$500/ 6 Months</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Games">
            <div class="games-list">
              <div class="row">
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game1.png" alt="" />
                </div>
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game2.png" alt="" />
                </div>
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game3.png" alt="" />
                </div>
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game4.png" alt="" />
                </div>
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game1.png" alt="" />
                </div>
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game2.png" alt="" />
                </div>
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game3.png" alt="" />
                </div>
                <div class="col-md-6">
                  <img src="assets/images/dashboard/game4.png" alt="" />
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
