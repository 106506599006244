import {
  Component,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";
import { FirestoreService } from "app/shared/services/firestore.service";
import { StripeWService } from "app/shared/services/stripe-w.service";
import { ToastrService } from "ngx-toastr";
import Stripe from "stripe";
import firebase from "firebase/compat/app";
import { TranslateService } from "@ngx-translate/core";

import { StripeService, StripePaymentElementComponent } from "ngx-stripe";
import {
  StripeElementsOptions,
  PaymentIntent,
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
} from "@stripe/stripe-js";
@Component({
  selector: "card-detail-dialog",
  templateUrl: "./card-detail-dialog.component.html",
  styleUrls: ["./card-detail-dialog.component.scss"],
})
export class cardDetailDialogComponent implements OnInit {
  elements: StripeElements;
  cardElement: StripeCardElement;

  cardOptions: StripeCardElementOptions = {
    classes: {
      base: "stripe-base",
      complete: "stripe-complete",
      empty: "stripe-empty",
      focus: "stripe-focus",
      invalid: "stripe-invalid",
      webkitAutofill: "stripe-webkit-autofill",
    },
    style: {
      base: {
        iconColor: "#666EE8",
        color: "black",
        fontWeight: "300",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "black",
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: "it",
  };
  email: string = "";
  card: Stripe.PaymentMethodCreateParams.Card1 = {
    number: "",
    exp_month: 0,
    exp_year: new Date().getFullYear(),
    cvc: "",
  };
  yearsList = new Date().getFullYear();
  userId: string = "";
  name = "";
  user: any;
  constructor(
    public dialogRef: MatDialogRef<cardDetailDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripeService: StripeWService,
    private firestoreService: FirestoreService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private stripeElementService: StripeService
  ) {}

  ngOnInit() {
    this.auth.user.subscribe((usr) => {
      if (usr) {
        this.stripeElementService
          .elements(this.elementsOptions)
          .subscribe((elements) => {
            this.elements = elements;
            // Only mount the element the first time
            if (!this.cardElement) {
              this.cardElement = this.elements.create("card", this.cardOptions);
              this.cardElement.mount("#card-element");
            }
          });
        this.userId = usr.uid;
        this.email = usr.email;
        this.firestoreService
          .doc$(`users/${this.userId}`)
          .subscribe((user: any) => {
            this.user = user;
            this.name = user.name;
          });
      }
    });
  }

  async addCard() {
    try {
      //   console.log(this.card)
      //   const cardNumber = this.card.number;
      // // Remove spaces from the card number
      // const processedCardNumber = cardNumber.replace(/\s/g, '');
      // this.card.number = processedCardNumber;
      //   this.card.exp_year = Number(this.card.exp_year);
      let customer;
      if (this.user.stripeCustomerId) {
        customer = await this.stripeService.getCustomer(
          this.user.stripeCustomerId
        );
      } else {
        customer = await this.stripeService.createCustomer(
          this.email,
          this.name
        );
      }
      // const card = await this.stripeService.createPaymentMethod(this.card);

      this.stripeElementService
        .createPaymentMethod({
          type: "card",
          card: this.cardElement,
        })
        .subscribe(async (paymentMethod) => {
          const card = paymentMethod.paymentMethod.card;
          if (
            this.user.fingerprints &&
            this.user.fingerprints.includes(card.fingerprint)
          ) {
            this.toastr.error(this.translate.instant("Card already exists"));
            this.dialogRef.close();
            return;
          }
          const res = await this.stripeService.attachPaymentMethodtoCustomer(
            paymentMethod.paymentMethod.id,
            customer.id
          );
          console.log(res);
          console.log({
            stripeCustomerId: customer.id,
            stripePaymentMethodId: paymentMethod.paymentMethod.id,
            card: card.last4,
            fingerprints: res.card.fingerprint,
          });
          await this.firestoreService.update(`users/${this.userId}`, {
            stripeCustomerId: customer.id,
            stripePaymentMethodId: firebase.firestore.FieldValue.arrayUnion(
              paymentMethod.paymentMethod.id
            ),
            card: firebase.firestore.FieldValue.arrayUnion(card.last4),
            fingerprints: firebase.firestore.FieldValue.arrayUnion(
              res.card.fingerprint
            ),
          });
          this.toastr.success(
            this.translate.instant("Card added successfully")
          );
          this.dialogRef.close();
        }),
        (err) => {
          console.log(err);
          this.toastr.error(err.error.message);
          this.dialogRef.close();
        };
    } catch (e) {
      console.log(e);
      this.toastr.error(e.message);
      this.dialogRef.close();
    }
  }
}

@Directive({
  selector: "[appCreditCardFormatter]",
})
export class CreditCardFormatterDirective {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener("input", ["$event"]) onInputChange(event: KeyboardEvent) {
    const initialValue = this.el.value;
    this.el.value = this.format(initialValue);
    if (this.el.value !== initialValue) {
      event.stopPropagation();
    }
  }

  format(value: string) {
    const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || "";
    const parts = [];

    for (let i = 0; i < match.length; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  }
}
