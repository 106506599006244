<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main pt-2" [ngClass]="{ mobile: isMobile }">
  <div class="profile">
    <div class="container-fluid">
      <h2>{{ "Profile" | translate }}</h2>
      <div class="profile-box">
        <ng-container #nocover>
          <ng-template #nocoverPic>
            <div class="cover">
              <div
                style="
                  border-radius: 10%;
                  width: 100%;
                  height: 349px;
                  background-color: #f0f0f0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <p style="font-size: 24px; font-weight: bold">
                  {{ "No Cover Photo" | translate }}
                </p>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <div
          class="cover"
          *ngIf="gamesData != null && gamesData.length > 0; else nocover"
        >
        <button
            class="small-btn btn edit-btn"
            (click)="shareLink()"
            style="margin-right: 2.5rem"
          >
            <i class="fa fa-share-alt"></i>
          </button>
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let gD of gamesData; let i = index">
              <ng-template
                carouselSlide
                [id]="i + (isMobile ? 'mobile' : 'web')"
              >
                <img
                  class="c-image"
                  *ngIf="gD.image; else nocoverPic"
                  src="{{ gD.image }}"
                  alt="Profile Picture"
                />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <div class="profile-body">
          <div class="info">
            <div
              class="dp-name"
              [ngClass]="{
                'd-flex': !isMobile,
                'justify-content-between': !isMobile,
                'w-90': !isMobile
              }"
            >
              <div
                [ngClass]="{
                  'd-flex': isMobile,
                  'flex-column': isMobile,
                  'align-items-center': isMobile
                }"
              >
                <img
                  *ngIf="
                    profilePicture$ | async as profilePicture;
                    else noProfilePicture
                  "
                  src="{{ profilePicture }}"
                  alt="Profile Picture"
                  style="border-radius: 50%"
                  class="mat-elevation-z8"
                />
                <ng-template #noProfilePicture>
                  <div class="profile-picture-container">
                    <i class="fa fa-user"></i>
                  </div>
                </ng-template>

                <h3>{{ name$ | async }}</h3>
                <span class="country"> ({{ country$ | async }}) </span>
                <button
                  *ngIf="isMobile"
                  class="btn btn-primary"
                  [ngClass]="{
                    'msg-btn': isMobile
                  }"
                  routerLink="/messages/{{ userData?.uid }}"
                >
                  {{ "Message" | translate }}
                </button>

                <button
                  *ngIf="isMobile"
                  (click)="supportDialog()"
                  class="btn report-btn"
                  [ngClass]="{
                    'mobile-report': isMobile
                  }"
                >
                  {{ "Report Master" | translate }}
                </button>
              </div>

              <div class="right-side">
                <div class="box" [ngClass]="{ boxDesktop: !isMobile }">
                  <div
                    class="d-flex flex-column align-items-center"
                    style="position: absolute; left: -10rem"
                  >
                    <button
                      *ngIf="!isMobile"
                      class="btn btn-primary msg-btn-custom"
                      style="margin-bottom: 1rem"
                      [ngClass]="{
                        'msg-btn': isMobile
                      }"
                      routerLink="/messages/{{ userData?.uid }}"
                    >
                      {{ "Message" | translate }}
                    </button>
                    <button
                      *ngIf="!isMobile"
                      (click)="supportDialog()"
                      class="btn report-btn w-100"
                    >
                      {{ "Report Master" | translate }}
                    </button>
                  </div>

                  <div class="text-row">
                    <span class="heading">{{ "Rating" | translate }} :</span>
                    <div class="stars">
                      <i
                        *ngFor="let i of [1, 2, 3, 4, 5]"
                        [ngClass]="i <= rating ? 'fa-star fas' : 'fa-star far'"
                      ></i>
                    </div>
                    <span class="green-text">{{ rating }}</span>
                  </div>
                  <div class="text-row">
                    <span class="heading">{{
                      "Registered :" | translate
                    }}</span>
                    <span class="green-text">{{
                      (registerDateTS$ | async)?.toDate() | date : "MMM,dd yyyy"
                    }}</span>
                  </div>

                  <div class="social">
                    <a
                      [href]="formatURL(userData?.facebookLink)"
                      *ngIf="userData?.facebookLink"
                      target="_blank"
                    >
                      <i
                        class="fab fa-facebook"
                        style="font-size: 35px; color: #1e3050"
                      ></i
                    ></a>
                    <a
                      [href]="formatURL(userData?.instagramLink)"
                      *ngIf="userData?.instagramLink"
                      target="_blank"
                      ><i
                        class="fab fa-instagram"
                        style="
                          font-size: 32px;
                          padding: 0.1rem;
                          border-radius: 20%;
                          color: white;
                          background: linear-gradient(
                            45deg,
                            #feda75,
                            #fa7e1e,
                            #d62976,
                            #962fbf,
                            #4f5bd5
                          );
                        "
                      ></i
                    ></a>
                    <a
                      [href]="formatURL(userData?.discordChannel)"
                      *ngIf="userData?.discordChannel"
                      target="_blank"
                      ><i
                        class="fab fa-discord"
                        style="font-size: 35px; color: #7289da"
                      ></i
                    ></a>
                    <a
                      [href]="formatURL(userData?.twitchLink)"
                      *ngIf="userData?.twitchLink"
                      target="_blank"
                      ><i
                        class="fab fa-twitch"
                        style="font-size: 35px; color: #6441a5"
                      ></i
                    ></a>
                    <a
                      [href]="formatURL(userData?.youtubeLink)"
                      *ngIf="userData?.youtubeLink"
                      target="_blank"
                    >
                      <i
                        class="fab fa-youtube"
                        style="font-size: 35px; color: red"
                      ></i>
                    </a>
                    <a
                      [href]="formatURL(userData?.twitterLink)"
                      *ngIf="userData?.twitterLink"
                      target="_blank"
                    >
                      <i
                        class="fab fa-twitter"
                        style="font-size: 35px; color: rgba(0, 213, 255, 0.92)"
                      ></i>
                    </a>
                    <a
                      [href]="formatURL(userData?.tiktokLink)"
                      *ngIf="userData?.tiktokLink"
                      target="_blank"
                    >
                      <i
                        class="fab fa-tiktok"
                        style="font-size: 35px; color: #1e3050"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabs-area">
              <mat-tab-group animationDuration="0ms">
                <mat-tab [label]="'General' | translate">
                  <div class="about">
                    <h4>{{ "About me:" | translate }}</h4>
                    <p *ngIf="aboutMe$ | async as aboutMe">
                      {{
                        showAboutMe
                          ? aboutMe
                          : aboutMe.length > 300
                          ? aboutMe.substring(0, 300) + "..."
                          : aboutMe
                      }}
                      <span
                        *ngIf="aboutMe.length >= 300"
                        (click)="toggleAboutMe()"
                        style="color: rgb(81, 81, 240); cursor: pointer"
                      >
                        {{ showAboutMe ? "Show Less" : "Show More" }}
                      </span>
                    </p>
                    <!-- <button
                      class="btn"
                      *ngIf="(aboutMe$ | async).length >= 50"
                      (click)="toggleAboutMe()"
                    >
                      {{ showAboutMe ? "Show Less" : "Show More" }}
                    </button> -->
                    <h4 *ngIf="!isRateClicked">
                      {{ "Rates:" | translate }}
                    </h4>
                    <ng-container *ngIf="rates?.length > 0; else loading">
                      <div class="row" *ngIf="!isRateClicked">
                        <div
                          class="col-md-4 col-xs-12 col-sm-6 col-lg-4"
                          *ngFor="let rate of rates"
                        >
                          <div class="box">
                            <h5 *ngIf="rate.discounts.length > 0">
                              €{{ getDiscountedPrice(rate) }}/{{
                                rate.duration.split(" ")[0] +
                                  " " +
                                  (rate.duration.split(" ")[1] | translate)
                              }}
                              <img src="assets/icons/icons/discount.png" />
                            </h5>
                            <h5
                              *ngIf="rate.discounts.length == 0"
                              style="height: 19px"
                            ></h5>
                            <h5 [class.crossed_out]="rate.discounts.length > 0">
                              €{{ rate.price }}/{{
                                rate.duration.split(" ")[0] +
                                  " " +
                                  (rate.duration.split(" ")[1] | translate)
                              }}
                            </h5>
                            <span class="day"
                              >{{ "Days per week:" | translate }}
                              <span class="green">{{
                                rate.daysPerWeek
                              }}</span></span
                            >
                            <span class="hours"
                              >{{ "Hours per day:" | translate }}
                              <span class="green">{{
                                rate.hrsPerDay
                              }}</span></span
                            >
                            <span class="games"
                              >{{ "Titles:" | translate }}
                              <span class="green">{{
                                gameNames(rate)
                              }}</span></span
                            >
                            <button
                              class="btn btn-primary"
                              (click)="chooseRateDialog(rate)"
                            >
                              {{ "Buy" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #loading>{{
                      "No rates added yet." | translate
                    }}</ng-template>
                  </div>
                  <div *ngIf="isRateClicked" class="deals">
                    <div class="haed-text">
                      <span class="heading">{{
                        "Choose your Class" | translate
                      }}</span>
                      <a (click)="showAbout()" class="green">
                        {{ "Go Back" | translate }}
                      </a>
                    </div>
                    <div class="deal-list">
                      <div
                        *ngFor="let cl of classesForSelectedRate"
                        (click)="subDealDialog(cl)"
                        class="box"
                        [ngClass]="{
                          'd-flex': isMobile,
                          'flex-column': isMobile
                        }"
                      >
                        <img
                          class="main-img"
                          src="{{ cl.image }}"
                          alt
                          [ngClass]="{ 'mobile-img': isMobile }"
                        />
                        <div class="text-area w-100">
                          <span
                            *ngIf="
                              selectedRate?.discounts.length > 0 ||
                              (cl.discounts && cl.discounts.length > 0)
                            "
                          >
                            <img
                              style="float: right"
                              src="assets/icons/icons/discount.png"
                            />
                          </span>
                          <span
                            style="float: right"
                            (click)="openInfoDialog(cl, $event)"
                          >
                            <mat-icon>info</mat-icon>
                          </span>
                          <span class="title">
                            {{ cl.title }}
                          </span>
                          <span class="date-time">
                            {{ scheduleDayClass(cl) }}
                          </span>
                          <span class="time overflow-ellipis">
                            {{ formatTime(cl.startTime) }} - {{ formatTime(cl.endTime) }}
                          </span>
                          <span class="text-green font-weight-bold">
                            {{ cl.classStudentsList.length }} /
                            {{ cl.maxNumberOfStudents }}
                            {{ "Students" | translate }}
                          </span>
                          <div class="start">
                            <img
                              *ngIf="cl.endDate.toDate() < today"
                              src="assets/images/profile/ClassStarted.png"
                              alt
                            />
                            <img
                              *ngIf="cl.startDate.toDate() > today"
                              src="assets/images/profile/ClassYetToStart.png"
                              alt
                            />
                            <div
                              *ngIf="
                                cl.startDate.toDate() > today;
                                else classStarted
                              "
                            >
                              {{ "Class will start on " | translate }} - ({{
                                cl.startDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }}) - ({{
                                cl.endDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }})
                            </div>
                            <ng-template #classStarted>
                              {{ "Class Started" | translate }} - ({{
                                cl.startDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }}) - ({{
                                cl.endDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }})
                            </ng-template>
                          </div>

                          <div class="tags">
                            <span class="overflow-ellipis">
                              {{ cl.language }}
                            </span>
                            <span class="overflow-ellipis">
                              {{ cl.gamingPlatform }}
                            </span>
                            <span class="ove rflow-ellipis peripherals">
                              {{ cl.peripherals }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab [label]="'Information' | translate">
                  <div
                    class="info-tab"
                    *ngIf="languages$ | async as languages"
                    class="info-tab"
                  >
                    <h4>{{ "Languages:" | translate }}</h4>
                    <span *ngFor="let language of languages" class="lang">
                      {{ language }}
                    </span>
                    <h4 class="mt-3">{{ "Games :" | translate }}</h4>
                    <ul class="tags" appScrollHorizontal>
                      <li *ngFor="let game of games$ | async">
                        {{ game.name }}
                      </li>
                    </ul>

                    <h4 class="mt-3 mb-4">
                      {{ "Recommended Products :" | translate }}
                    </h4>
                    <div class="product-area">
                      <div class="row">
                        <div
                          *ngFor="
                            let product of products$ | async;
                            let i = index
                          "
                          class="col-xl-3 col-md-4"
                        >
                          <a [href]="formatUrl(product.link)" target="_blank">
                            <mat-card>
                              <img
                                mat-card-image
                                [src]="product.image"
                                alt="Sweech Logo"
                                style="max-height: 15rem; height: 10rem"
                              />
                              <mat-card-content>
                                {{ product.name }}
                                <!-- {{ "Amazon Link " | translate }} # {{ i + 1 }} -->
                              </mat-card-content>
                            </mat-card>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab [label]="'Review' | translate">
                  <div class="rating-area">
                    <h4>{{ "Ratings & Reviews" | translate }} :</h4>
                    <div class="ratings-average">
                      <div class="rat-row">
                        <span>{{ "Ability in Game" | translate }} :</span>
                        <div class="d-flex align-items-center">
                          <div class="stars font-size-1 stars-review">
                            <i
                              *ngFor="let i of [1, 2, 3, 4, 5]"
                              [ngClass]="
                                i <= loggedInUserData?.gameAbility
                                  ? 'fa-star fas'
                                  : 'fa-star far'
                              "
                            ></i>
                          </div>
                          <div>
                            {{
                              isInteger(loggedInUserData?.gameAbility)
                                ? loggedInUserData?.gameAbility + ".0"
                                : loggedInUserData?.gameAbility
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="rat-row">
                        <span>{{ "Ability to Teach" | translate }} :</span>
                        <div class="d-flex align-items-center">
                          <div class="stars font-size-1 stars-review">
                            <i
                              *ngFor="let i of [1, 2, 3, 4, 5]"
                              [ngClass]="
                                i <= loggedInUserData?.teachAbility
                                  ? 'fa-star fas'
                                  : 'fa-star far'
                              "
                            ></i>
                          </div>
                          <div>
                            {{
                              isInteger(loggedInUserData?.teachAbility)
                                ? loggedInUserData?.teachAbility + ".0"
                                : loggedInUserData?.teachAbility
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="rat-row">
                        <span>{{ "Pleasantness" | translate }} :</span>
                        <div class="d-flex align-items-center">
                          <div class="stars font-size-1 stars-review">
                            <i
                              *ngFor="let i of [1, 2, 3, 4, 5]"
                              [ngClass]="
                                i <= loggedInUserData?.pleasantness
                                  ? 'fa-star fas'
                                  : 'fa-star far'
                              "
                            ></i>
                          </div>
                          <div>
                            {{
                              isInteger(loggedInUserData?.pleasantness)
                                ? loggedInUserData?.pleasantness + ".0"
                                : loggedInUserData?.pleasantness
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="rat-row">
                        <span>{{ "Quality/Price" | translate }} :</span>
                        <div class="d-flex align-items-center">
                          <div class="stars font-size-1 stars-review">
                            <i
                              *ngFor="let i of [1, 2, 3, 4, 5]"
                              [ngClass]="
                                i <= loggedInUserData?.qualityPerPrice
                                  ? 'fa-star fas'
                                  : 'fa-star far'
                              "
                            ></i>
                          </div>
                          <div>
                            {{
                              isInteger(loggedInUserData?.qualityPerPrice)
                                ? loggedInUserData?.qualityPerPrice + ".0"
                                : loggedInUserData?.qualityPerPrice
                            }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="sort-row">
                      <h5>{{ "Sort By :" | translate }}</h5>
                      <button class="btn">{{ "Relevant" | translate }}</button>
                    </div> -->

                    <div class="full-reviews">
                      <div
                        class="box"
                        *ngFor="let review of reviews$ | async; let i = index"
                      >
                        <img [src]="review.userImage" alt="User Image" />
                        <div>
                          <div class="box-head">
                            <span>
                              <span class="name">{{ review.userName }}</span>
                              <div class="d-flex align-items-center">
                                <div
                                  class="stars font-size-1 stars-review"
                                  style="margin-bottom: 0"
                                >
                                  <i
                                    *ngFor="let i of [1, 2, 3, 4, 5]"
                                    [ngClass]="
                                      i <= review?.overallRating
                                        ? 'fa-star fas'
                                        : 'fa-star far'
                                    "
                                  ></i>
                                </div>
                                <div>
                                  {{
                                    isInteger(review?.overallRating)
                                      ? review?.overallRating + ".0"
                                      : review?.overallRating
                                  }}
                                </div>
                              </div>
                            </span>
                            <span class="time">{{
                              review.date.toDate() | date
                            }}</span>
                          </div>
                          <div class="box-body">
                            <p>
                              {{ review.review }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="write-review" *ngIf="isAllowedToReview">
                      <input
                        type="text"
                        class="form-control"
                        [placeholder]="'Write Your Review' | translate"
                        [(ngModel)]="newReview.review"
                        [ngModelOptions]="{ standlone: true }"
                      />
                      <div class="ratings-average">
                        <div class="rat-row">
                          <span>{{ "Ability in Game" | translate }} :</span>
                          <bar-rating
                            [(rate)]="newReview.gameAbility"
                            [max]="5"
                          >
                            <ng-template ratingFraction>
                              <fa-icon
                                [icon]="['fas', 'star-half-alt']"
                                [fixedWidth]="true"
                                size="lg"
                                style="color: #ffff04"
                              ></fa-icon>
                            </ng-template>
                          </bar-rating>
                        </div>
                        <div class="rat-row">
                          <span>{{ "Ability to Teach" | translate }} :</span>
                          <bar-rating
                            [(rate)]="newReview.teachAbility"
                            [max]="5"
                          >
                            <ng-template ratingFraction>
                              <fa-icon
                                [icon]="['fas', 'star-half-alt']"
                                [fixedWidth]="true"
                                size="lg"
                                style="color: #ffff04"
                              ></fa-icon>
                            </ng-template>
                          </bar-rating>
                        </div>
                        <div class="rat-row">
                          <span>{{ "Pleasantness" | translate }} :</span>
                          <bar-rating
                            [(rate)]="newReview.pleasantness"
                            [max]="5"
                          >
                            <ng-template ratingFraction>
                              <fa-icon
                                [icon]="['fas', 'star-half-alt']"
                                [fixedWidth]="true"
                                size="lg"
                                style="color: #ffff04"
                              ></fa-icon>
                            </ng-template>
                          </bar-rating>
                        </div>
                        <div class="rat-row">
                          <span>{{ "Quality/Price" | translate }} :</span>
                          <bar-rating
                            [(rate)]="newReview.qualityPerPrice"
                            [max]="5"
                          >
                            <ng-template ratingFraction>
                              <fa-icon
                                [icon]="['fas', 'star-half-alt']"
                                [fixedWidth]="true"
                                size="lg"
                                style="color: #ffff04"
                              ></fa-icon>
                            </ng-template>
                          </bar-rating>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary mt-5 mb-3"
                        [disabled]="shouldRateEnable()"
                        (click)="saveReview()"
                      >
                        {{ "Submit" | translate }}
                      </button>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
