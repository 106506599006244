<div id="auth" class="auth choose-game">
  <div class="container-fluid">
    <img class="sweech-logo" src="assets/images/sweech-logo.png" alt="" />
    <div class="row align-items-center">
      <div class="col-md-5">
        <div class="auth-box pt-0 text-center">
          <h1>{{ "Games you Usually Play" | translate }}</h1>
          <p>{{ "Choose the Games that you mostly play" | translate }}</p>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ "Search for a game..." | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="searchTerm"
              name="filter"
              (keyup.enter)="filterGames()"
            />
          </mat-form-field>

          <div class="row games">
            <div
              (click)="onGameClicked(game.uid, game)"
              class="col-4"
              *ngFor="let game of games$ | async | slice : start : end"
            >
              <img
                [style.border]="
                  selectedGames.includes(game.uid) ? '3px solid green' : 'none'
                "
                src="{{ game.image }}"
                alt="{{ game.name }}"
              />

              <h5>{{ game.name }}</h5>
            </div>
          </div>
          <div
            class="pagination-container text-center"
            style="flex-direction: column; align-items: baseline"
          >
            <div class="pagination">
              <button (click)="previousPage()" [disabled]="currentPage <= 1">
                {{ "Previous" | translate }}
              </button>
              <span>{{ currentPage }}</span>
              <button (click)="nextPage()">{{ "Next" | translate }}</button>
            </div>
          </div>
          <p *ngIf="!isMasterApplying">{{ "Selected Games" | translate }}</p>
          <div *ngIf="!isMasterApplying" class="row games">
            <div
              class="col-4"
              *ngFor="
                let game of selectedGames$ | async | slice : start2 : end2
              "
            >
              <div class="game-container">
                <img
                  [style.border]="
                    selectedGames.includes(game.uid)
                      ? '3px solid green'
                      : 'none'
                  "
                  src="{{ game.image }}"
                  alt="{{ game.name }}"
                />
                <button
                  (click)="onGameClickedCancel(game.uid)"
                  class="remove-game-btn"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
              <h5>{{ game.name }}</h5>
            </div>
          </div>
          <div
            *ngIf="!isMasterApplying"
            class="pagination-container text-center"
            style="flex-direction: column; align-items: baseline"
          >
            <div class="pagination">
              <button (click)="previousPage2()" [disabled]="currentPage2 <= 1">
                {{ "Previous" | translate }}
              </button>
              <span>{{ currentPage2 }}</span>
              <button (click)="nextPage2()">{{ "Next" | translate }}</button>
            </div>
          </div>
          <button class="btn btn-default" (click)="requestGame()">
            {{ "Ask for a Missing Game" | translate }}</button
          ><br />
          <button
            class="btn btn-primary"
            [disabled]="selectedGames.length === 0"
            (click)="updateUser()"
          >
            {{ "Save" | translate }}
          </button>
        </div>
      </div>
      <div class="col-md-7">
        <img class="img-fluid" src="assets/images/login.jpg" />
      </div>
    </div>
  </div>
</div>
