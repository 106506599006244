<div class="game-filter">
  <div class="game-filter-dialog" [ngStyle]="{width: isMobile? 'inherit': '400px'}">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <h3>{{ "Search Filter" | translate }}</h3>
    <div class="form-group">
      <i class="fa fa-search ng-tns-c208-14"></i>
      <input
        type="text"
        class="form-control"
        placeholder="Search any Game & Master"
        [(ngModel)]="search"
      />
    </div>
    <h4>{{ "Language" | translate }}</h4>
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="language" name="language">
        <mat-option [value]="language.label" *ngFor="let language of languages">
          {{ language.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <div class="checkboxes">
      <span *ngFor="let language of languages">
        <input
          type="checkbox"
          id="{{ language.id }}"
          class="graphic"
          [(ngModel)]="language.checked"
          (ngModelChange)="updateSelectedlanguages()"
        />
        <label for="{{ language.id }}">{{ language.label }}</label>
      </span>
    </div> -->

    <h4>{{ "Duration" | translate }}</h4>
    <div class="checkboxes">
      <span *ngFor="let duration of durations">
        <input
          type="checkbox"
          id="{{ duration.id }}"
          class="graphic"
          [(ngModel)]="duration.checked"
          (ngModelChange)="updateSelectedDurations()"
        />
        <label for="{{ duration.id }}">{{ duration.label }}</label>
      </span>
    </div>

    <h4>{{ "Platforms" | translate }}</h4>
    <div class="checkboxes">
      <span *ngFor="let platform of platforms">
        <input
          type="checkbox"
          id="{{ platform.id }}"
          class="graphic"
          [(ngModel)]="platform.checked"
          (ngModelChange)="updateSelectedPlatforms()"
        />
        <label for="{{ platform.id }}">{{ platform.label }}</label>
      </span>
    </div>

    <div class="text-center">
      <button class="btn btn-default mr-3" (click)="clear()">Clear</button>
      <button class="btn btn-primary" (click)="resultPage()">Search</button>
    </div>
  </div>
</div>
