<div id="auth" class="auth profile-ready">
  <div class="container-fluid">
    <img
      class="sweech-logo"
      style="position: absolute; top: 25px"
      src="assets/images/sweech-logo.png"
      alt=""
    />
    <div class="row align-items-center">
      <div class="col-md-5">
        <div class="auth-box pt-0 text-center">
          <img class="check-img" src="assets/images/check.png" />
          <h1>Your Student profile is ready</h1>
          <p>
            You will be contacted through email once your profile is Reviewed
            and accepted for a teacher
          </p>
          <button class="btn btn-primary mb-3" routerLink="/dashboard">
            Let’s Go
          </button>
        </div>
      </div>
      <div class="col-md-7">
        <img class="img-fluid" src="assets/images/login.jpg" />
      </div>
    </div>
  </div>
</div>
