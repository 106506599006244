import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ElementRef, ViewChild } from "@angular/core";
import { fromEvent, Observable } from "rxjs";
import { Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { navigation } from "app/navigation/navigation";
import { LoginService } from "app/main/authorizations/login/login.service";
import { toolbarService } from "./toolbar.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { logoutConfirmDialogComponent } from "app/layout/confirm-dialog/confirm-dialog.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";

interface User {
  profilePic: string;
  name: string;
}
@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit {
  content: any;
  users: any;
  myDetail: any;
  policyContent: any;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  user: any;
  noRecord: boolean = false;
  searchUser: boolean = false;
  isSearching: boolean = false;
  isFocus: boolean = false;
  searchSubscribed: boolean = false;
  searchText: string = "";
  type: "PRIVACY";

  @ViewChild("SearchInput") SearchInput: ElementRef;

  // isUserLoggedIn: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;
  currentRoute$: Observable<string>;
  profilePicture$: any;
  loggedInUserId: string;
  userDoc;
  unreadMessagesCount$: Observable<number>;
  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private store: Store<any>,
    public loginService: LoginService,
    public toolbarService: toolbarService,
    private _matSnackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private http: HttpClient
  ) {
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.currentRoute$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url)
    );
  }

  ngOnInit() {
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
      this.loggedInUserId = user?.uid;
      if (user) {
        const usersRef = this.afs.collection<User>("users");
        this.unreadMessagesCount$ = this.toolbarService.getUnreadMessagesCount(this.user.uid).pipe(catchError((err) => {
          console.error(err);
          return [];
        }));
        this.profilePicture$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(map((doc) => { 
            this.userDoc = doc.data();
            return doc.data().profilePic;}));
      }
    });
  }

  openDialog() {
    this.dialog.open(logoutConfirmDialogComponent);
  }

  goToMaster() {
    window.location.href = `https://master.sweech.gg/dashboard?isFromApp=true&email=${this.userDoc.email}`
   
    // this.http
    //   .post("https://us-central1-sweech-3ec98.cloudfunctions.net/createToken", JSON.stringify({
    //     uid: this.loggedInUserId,
    //   }))
    //   .subscribe((res) => {
    //     this.location.go(
    //       "https://sweechmaster.web.app/dashboard?isFromApp=true&token=" + res
    //     );
    //   });
  }
}
