import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { MatDialog } from "@angular/material/dialog";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";

import { RequestGameDialogComponent } from "./request-game-dialog/request-game-dialog.component";
import { Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { map } from "rxjs/operators";
import { AuthService } from "app/shared/services/auth.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import * as firebase from "firebase/compat/app";

export interface Game {
  creationDateTS: Date;
  image: string;
  name: string;
  uid: string;
}
interface User {
  vat: string;
  profilePic: string;
  name: string;
  country: string;
  languages: string[];
  gameIds: string[];
  registerDateTS: Date;
  fiscalCode: string;
  discordLink: string;
  uid: string;
}
interface selectedGames {}

@Component({
  selector: "choose-game",
  templateUrl: "./choose-game.component.html",
  styleUrls: ["./choose-game.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ChooseGameComponent implements OnInit {
  gamesCollection: AngularFirestoreCollection<Game>;
  games$: Observable<Game[]>;
  uid$: Observable<string>;
  user: any;
  selectedGames: string[] = [];
  selectedGameName: string;

  selectedGameIds: string[] = [];
  currentPage = 1;
  currentPage2 = 1;
  gamesPerPage = 6;
  selectedGames$: Observable<Game[]>;
  searchTerm: string = '';
  private itemsCollection: AngularFirestoreCollection<selectedGames>;

  public isMasterApplying: boolean = false;
  gamesTeached: any[] = [];
  selectedGameData: any;
  constructor(
    private router: Router,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private _fuseConfigService: FuseConfigService,
    private matDialog: MatDialog,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute
  ) {
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
      if (user) {
        // Get a reference to the Firestore "users" collection

        this.route.queryParams.subscribe((params) => {
          if (params["isMasterApplying"]) {
            this.isMasterApplying = true;
          }
        });
        const usersRef = this.afs.collection<User>("users");
        this.uid$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(map((doc) => doc.data().uid));
      }
    });
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  onGameSelected(event: MatAutocompleteSelectedEvent) {
    const selectedGameName = event.option.value;
    const query = this.gamesCollection.ref.where(
      "name",
      "==",
      selectedGameName
    );
    query.get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        console.log("No matching documents");
      } else {
        const selectedGame = querySnapshot.docs[0].data() as Game;
        this.selectedGames.push(selectedGame.uid);
      }
    });
    this.selectedGameName = "";
  }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        const loggedInUserId = user.uid;
        this.firestore.doc(`users/${loggedInUserId}`).valueChanges().subscribe((user: any) => {
          const userGameIds = user.gameIds ?? [];
          this.selectedGames = userGameIds;
          this.gamesCollection = this.firestore.collection<Game>("games");
          this.games$ = this.gamesCollection.valueChanges().pipe(
            map(games => games.filter(game => !userGameIds.includes(game.uid)))
          );
          this.selectedGames$ = this.gamesCollection.valueChanges().pipe(
            map(games => games.filter(game => userGameIds.includes(game.uid)))
          );
        });
      }
    });
  }
  

  requestGame() {
    this.matDialog.open(RequestGameDialogComponent, {
      data: {
        isFromClass: false
      }
    });
  }
  get start(): number {
    return (this.currentPage - 1) * this.gamesPerPage;
  }

  get end(): number {
    return this.start + this.gamesPerPage;
  }

  get start2(): number {
    return (this.currentPage2 - 1) * this.gamesPerPage;
  }

  get end2(): number {
    return this.start + this.gamesPerPage;
  }

  get totalGames(): number {
    return this.selectedGameIds.length;
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  nextPage(): void {
    this.games$.pipe(map((games) => games.length)).subscribe((totalGames) => {
      if (this.currentPage < Math.ceil(totalGames / this.gamesPerPage)) {
        this.currentPage++;
      }
    });
  }

  previousPage2(): void {
    if (this.currentPage2 > 1) {
      this.currentPage2--;
    }
  }
  nextPage2(): void {
    this.selectedGames$.pipe(map((games) => games.length)).subscribe((totalGames) => {
      if (this.currentPage2 < Math.ceil(totalGames / this.gamesPerPage)) {
        this.currentPage2++;
      }
    });
  }

  onGameClicked(uid: string, game: any): void {
    if (this.isMasterApplying) {
      this.selectedGames = [uid];
      // this.updateUser();
      this.selectedGameData = game;
      this.gamesTeached = [game.name];
      return;
    }
    const index = this.selectedGames.indexOf(uid);
    console.log(index)
    if (index !== -1) {
        this.selectedGames.splice(index, 1); // remove the game from selectedGames
    } else {
        this.selectedGames.push(uid); // add the game to selectedGames
    }
    // this.updateUser(); // save automatically whenever a game is selected or unselected
}
onGameClickedCancel(uid: string): void {
  const index = this.selectedGames.indexOf(uid);
  console.log(index)
  if (index !== -1) {
      this.selectedGames.splice(index, 1); // remove the game from selectedGames
  } 
  // this.updateUser(); // save automatically whenever a game is selected or unselected
}

  async updateUser(): Promise<void> {
    const currentUser = await this.authService.getCurrentUser(); // get the current user
    if (this.isMasterApplying) {

      this.firestore.doc(`users/${currentUser.uid}`).update({
        gameIds: firebase.default.firestore.FieldValue.arrayUnion(...this.selectedGames),
        gamesEnabled: firebase.default.firestore.FieldValue.arrayUnion(...this.selectedGames.map((g) => false)),
        // gamesTeached: firebase.default.firestore.FieldValue.arrayUnion(...this.selectedGames.map((g) => g.name)), // TODO: add the game name to the gamesTeached array
        
      });
      localStorage.setItem('user_games', JSON.stringify({
        gameIds: this.selectedGames,
        gamesTeached: this.gamesTeached,
        game: this.selectedGameData
      }))
      this.selectedGames.forEach((element) => {
        const selectedgamesRef = this.firestore
          .collection("users")
          .doc(`${currentUser.uid}`)
          .collection("selectedGames")
          .add({
            gameId: element,
            uid: null, // set the initial value of uid to null
            creationDate: new Date(),
          })
          .then((docRef) => {
            // add the docRef parameter to the callback function
            // Update the uid field with the newly created document's ID
            docRef.update({ uid: docRef.id }).then(() => {
              this.router.navigate(['/gaming-curriculm']); // navigate to the next page
            });
          });
      });

      return;
    }
    this.firestore.doc(`users/${currentUser.uid}`).update({
      studentGamesSelected: this.selectedGames,
      gameIds: this.selectedGames,
    });

    this.selectedGames.forEach((element) => {
      const selectedgamesRef = this.firestore
        .collection("users")
        .doc(`${currentUser.uid}`)
        .collection("studentGamesSelected")
        .add({
          gameId: element,
          uid: null, // set the initial value of uid to null
          creationDate: new Date(),
        })
        .then((docRef) => {
          // add the docRef parameter to the callback function
          // Update the uid field with the newly created document's ID
          docRef.update({ uid: docRef.id }).then(() => {
            this.router.navigate(["/dashboard"]); // navigate to the next page
          });
        });
    });
  }

  filterGames(): void {
    this.games$ = this.gamesCollection.valueChanges().pipe(
        map(games => games.filter(game => 
            !this.selectedGames.includes(game.uid) &&
            (this.searchTerm === '' || game.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
        ))
    );
}

}
